import React from 'react';
import { Form, Formik, setNestedObjectValues } from 'formik';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { dataFormValidationSchema } from '../../global/validationShemas';
import ContactModal from '../../containers/MainPage/components/ContactUsBlock/components/ContactModal';
import useDesktopSize from '../../global/hooks/useDesktopSize';
import Checkbox from '../CheckBox';
import { DataFormContainer } from './DataForm.styled';
import Input from '../Input';
import Select from '../Select';
import Textarea from '../Textarea';
import { formikInjectedPropsTypes } from '../../global/types';
import { useGetIndustriesQuery } from '../../global/service/industryService';

const DataForm = ({ handleSubmitForm, isContactModalOpen, setContactModalOpen }) => {
  const { t } = useTranslation();
  const { isDesktop } = useDesktopSize();
  const { data } = useGetIndustriesQuery();

  const handleValidate = async (props) => {
    const validationErrors = await props.validateForm();
    if (Object.keys(validationErrors).length > 0) {
      props.setTouched(setNestedObjectValues(validationErrors, true));
      return;
    }
    setContactModalOpen(true);
  };

  const agreement = (props) => (
    <Checkbox
      checkBoxName="agreement"
      title={t('mainDataForm.agreement')}
      error={props.touched.agreement && props.errors.agreement}
    />
  );

  const selectOptions =
    data?.map((el) => ({
      value: el.name.value,
      label: el.name.value,
      id: el.id,
    })) || null;

  return (
    <DataFormContainer>
      <Formik
        initialValues={{
          name: '',
          email: '',
          industry: 0,
          message: '',
          agreement: false,
          link: '',
          file: '',
        }}
        enableReinitialize
        validationSchema={dataFormValidationSchema}
        onSubmit={handleSubmitForm}
      >
        {(props) => (
          <Form className="form-container">
            <div className="form-container__grid">
              <Input
                name="name"
                error={props.touched.name && props.errors.name}
                label={t(`mainDataForm.formFields.name`)}
                placeholder={t(`mainDataForm.placeholderFields.name`)}
                required
              />
              <Input
                name="email"
                error={props.touched.email && props.errors.email}
                label={t(`mainDataForm.formFields.email`)}
                placeholder={t(`mainDataForm.placeholderFields.email`)}
                required
              />
              {selectOptions && (
                <Select
                  name="industry"
                  selectOptions={selectOptions}
                  label={t(`mainDataForm.formFields.subject`)}
                  placeholder={t(`mainDataForm.placeholderFields.subject`)}
                  theme="dark"
                />
              )}
            </div>
            <div className="form-container__row">
              <Textarea
                name="message"
                label={t(`mainDataForm.formFields.message`)}
                placeholder={t(`mainDataForm.placeholderFields.message`)}
              />
            </div>
            {!isDesktop && agreement(props)}
            <button
              className="btn btn_primary_contained form-container__btn-send"
              type="button"
              onClick={() => handleValidate(props)}
            >
              {t('mainDataForm.buttonText')}
            </button>
            {isDesktop && agreement(props)}
            {isContactModalOpen && <ContactModal onClose={() => setContactModalOpen(false)} />}
          </Form>
        )}
      </Formik>
    </DataFormContainer>
  );
};

DataForm.propTypes = {
  ...formikInjectedPropsTypes,
  handleSubmitForm: PropTypes.func.isRequired,
  isContactModalOpen: PropTypes.bool,
  setContactModalOpen: PropTypes.func,
};

DataForm.defaultProps = {
  isContactModalOpen: false,
  setContactModalOpen: () => {},
};

export default DataForm;
