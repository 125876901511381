import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import ReactSelect from 'react-select';
import { BsArrowRight } from 'react-icons/bs';
import { JobsMobileContainer, MobileVacanciesContainer } from './JobsMobile.styled';
import { departmentsIconsData } from '../constants';

const JobsMobile = ({ data }) => {
  const { t } = useTranslation();

  const ALL_VACANCIES = {
    value: t('jobs_page.all_vacancies'),
    label: t('jobs_page.all_vacancies'),
  };

  const [selectedDepartment, setSelectedDepartment] = useState(ALL_VACANCIES);

  const navigate = useNavigate();

  const handleSelectChange = (selectedOption) => {
    setSelectedDepartment(selectedOption);
  };

  const selectedVacanciesArr = useMemo(() => {
    if (selectedDepartment.value === ALL_VACANCIES.value) {
      return data.reduce(
        (arr, department) => [
          ...arr,
          ...department.vacancies.map((vacancy) => ({
            ...vacancy,
            departmentName: department.name,
          })),
        ],
        [],
      );
    }

    const vacanciesByDeparment = data.find(
      (department) => department.name === selectedDepartment.value,
    );
    return vacanciesByDeparment.vacancies;
  }, [selectedDepartment, data]);

  const options = useMemo(
    () => [
      { ...ALL_VACANCIES },
      ...data.map((department) => ({ value: department.name, label: department.name })),
    ],
    [data],
  );

  return (
    <JobsMobileContainer>
      <div>
        <ReactSelect
          defaultValue={ALL_VACANCIES}
          value={selectedDepartment}
          options={options}
          onChange={handleSelectChange}
          placeholder="All Vacancies"
          classNamePrefix="mobile__select"
        />
        <div className="mobile__open-jobs">
          {t('jobs_page.we_found')}{' '}
          <span className="mobile__open-jobs_vacancies">{selectedVacanciesArr.length}</span>{' '}
          {t('jobs_page.job_openings')}
        </div>
        <div className="blur" />
      </div>
      <MobileVacanciesContainer>
        {selectedVacanciesArr.map((vacancy) => (
          <div key={vacancy.name.value} className="vacancy__container_outer">
            <div className="vacancy__container_inner">
              <div className="vacancy__icon">
                <img
                  src={departmentsIconsData[vacancy.departmentName || selectedDepartment.value]}
                  alt={vacancy.departmentName}
                />
                <div className="vacancy__department">
                  {selectedDepartment.value !== 'All Vacancies'
                    ? selectedDepartment.value
                    : vacancy.departmentName}
                </div>
              </div>
              <div className="vacancy__title">{vacancy.name.value}</div>
              <div className="vacancy__type">{vacancy.work_type.value}</div>

              <div className="vacancy__apply">
                <div className="vacancies__apply-text">{t('jobs_page.apply')}</div>
                <button
                  className="vacancy__btn"
                  type="button"
                  onClick={() => navigate(`/career/jobs/${vacancy.slug}`)}
                >
                  <BsArrowRight />
                </button>
              </div>
            </div>
          </div>
        ))}
      </MobileVacanciesContainer>
    </JobsMobileContainer>
  );
};

export default JobsMobile;

JobsMobile.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string.isRequired,
      vacancies: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          slug: PropTypes.string,
          name: PropTypes.shape({
            value: PropTypes.string.isRequired,
          }),
          work_type: PropTypes.shape({
            value: PropTypes.string,
          }).isRequired,
        }),
      ).isRequired,
    }),
  ).isRequired,
};
