import styled from 'styled-components';
import careerBackground from '../../../../global/images/backgrounds/career.jpg';
import theme from '../../../../global/styles/theme';
import { mobileWidth } from '../../../../global/styles/Global.styled';

export const PreviewBlockContainer = styled.div`
  background-image: url(${careerBackground});
  background-size: cover;
  max-width: 1440px;
  overflow: hidden;
  height: 444px;
  margin: 0 auto;

  @media only screen and (min-width: 1980px) {
    max-width: 100%;
  }

  @media only screen and (max-width: ${mobileWidth}) {
    height: 678px;
    background-position: center center;
    background-repeat: no-repeat;
    margin-left: 16px;
    margin-right: 16px;
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 114px;

    @media only screen and (max-width: ${mobileWidth}) {
      margin-top: 140px;
    }

    &__title {
      font-family: Manrope, sans-serif;
      font-size: 64px;
      font-style: normal;
      font-weight: 800;
      line-height: 132%;
      letter-spacing: 1.28px;

      color: ${theme.colors.primary['100']};
      margin-bottom: 32px;

      @media only screen and (max-width: ${mobileWidth}) {
        width: 331px;
        text-align: center;
        font-size: 50px;
      }
    }

    &__btn {
      width: fit-content;
      min-width: 224px;
    }

    &__text {
      font-size: 17px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
      letter-spacing: 0.51px;
      color: ${theme.colors.text['95']};
      margin-top: 16px;
    }
  }
`;
