import review from '../../../../global/images/career/benefits/review.jpg';
import salary from '../../../../global/images/career/benefits/salary.jpg';
import vacation from '../../../../global/images/career/benefits/vacation.jpg';
import remote from '../../../../global/images/career/benefits/remote.jpg';

export const benefitsData = [
  {
    id: 1,
    benefit: 'career.benefits.review',
    image: review,
  },
  {
    id: 2,
    benefit: 'career.benefits.vacation',
    image: vacation,
  },
  {
    id: 3,
    benefit: 'career.benefits.salary',
    image: salary,
  },
  {
    id: 4,
    benefit: 'career.benefits.remote',
    image: remote,
  },
];
