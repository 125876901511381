import React, { useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import PropTypes from 'prop-types';
import { ProcessListContainer } from './ProcessList.styled';
import Process from './components/Process';
import SwiperNavigator from './components/SwiperNavigator';
import { getProcessList } from '../../helpers';
import useDesktopSize from '../../../../global/hooks/useDesktopSize';

const ProcessList = ({ setCurrentStep, currentStep }) => {
  const sliderRef = useRef();
  const { isDesktop1240 } = useDesktopSize();

  const slideTo = (step) => {
    if (sliderRef.current) {
      sliderRef.current.swiper.slideTo(step);
    }
  };

  useEffect(() => {
    slideTo(currentStep);
  }, [currentStep]);

  return (
    <ProcessListContainer>
      <Swiper
        ref={sliderRef}
        direction={!isDesktop1240 ? 'vertical' : 'horizontal'}
        onBeforeTransitionStart={(swiper) => {
          setCurrentStep(swiper.activeIndex);
        }}
        className="processSwiper"
        autoHeight
        speed={700}
      >
        {getProcessList().map((process) => (
          <SwiperSlide key={process.id}>
            <Process title={process.title} subtitle={process.subtitle} />
          </SwiperSlide>
        ))}
      </Swiper>
      <SwiperNavigator
        steps={getProcessList().length}
        currentStep={currentStep}
        slideTo={slideTo}
      />
    </ProcessListContainer>
  );
};

ProcessList.propTypes = {
  setCurrentStep: PropTypes.func.isRequired,
  currentStep: PropTypes.number.isRequired,
};

export default ProcessList;
