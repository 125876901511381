import styled from 'styled-components';
import theme from '../../../../global/styles/theme';

export const OtherCaseStudiesContainer = styled.div`
  padding-bottom: 100px;

  .swiper-wrapper {
    height: 100% !important;
  }

  .content {
    margin: 0 auto;
  }

  .title {
    padding-bottom: 78px;
    padding-top: 109px;
  }

  .swiper-slide,
  .swiper-slide-active {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-slide,
  .swiper-slide-next {
    width: auto;
    height: 100%;
  }

  .sliders-container {
    display: flex;
    justify-content: center;
    gap: 12px;
    padding-top: 57px;

    &__button {
      width: 38px;
      height: 6px;
      background-color: ${theme.colors.stroke['50']};
      cursor: pointer;
      transition: 0.5s;
      border-radius: 1px;

      &_active {
        width: 73px;
        background-color: ${theme.colors.primary['40']};
      }

      &:hover {
        background-color: ${theme.colors.primary['40']};
      }
    }
  }
`;
