import styled from 'styled-components';
import theme from '../../../../../../../../global/styles/theme';
import { mobileWidth } from '../../../../../../../../global/styles/Global.styled';

export const TechnologiesNavigationContainer = styled('div')`
  display: flex;
  justify-content: center;
`;

export const TechnologiesNavigationContent = styled('div')`
  width: 70%;
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: ${mobileWidth}) {
    width: auto;
    grid-auto-flow: column;
    column-gap: 32px;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .sliders-button {
    font-style: normal;
    color: ${theme.colors.text['90']};
    cursor: pointer;
    padding-bottom: 16px;
    border-bottom: 2px solid transparent;
    transition: 0.5s;
    min-width: fit-content;

    font-size: 21px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.42px;

    &_active {
      border-bottom: 2px solid ${theme.colors.primary['30']};
    }
  }
`;
