import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

const HelmetHead = ({ page }) => {
  const { t } = useTranslation();

  return (
    <Helmet>
      <title>{t(`pagesDescription.${page}.metaTitle`)}</title>
      <meta name="description" content={t(`pagesDescription.${page}.metaDescription`)} />
    </Helmet>
  );
};

HelmetHead.propTypes = {
  page: PropTypes.string.isRequired,
};

export default HelmetHead;
