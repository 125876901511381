import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AcademyContainer, AcademyContentContainer } from './Academy.styled';
import BackgroundLinearText from '../../../../components/BackgroundLinearText';
import academyImage from '../../../../global/images/frontendAcademy/frontendAcademy.jpg';
import JoinAcademyModalForm from '../../../AboutPage/components/FrontendAcademy/components/JoinAcademyModalForm';
import GratitudeModal from '../../../../components/GratitudeModal';

const Academy = () => {
  const { t } = useTranslation();

  const [isJoinAcademyModalOpen, setJoinAcademyModalOpen] = useState(false);
  const [isSuccessJoinOpen, setSuccessJoinOpen] = useState(false);

  const academyDescription = [
    t('frontendAcademyBlock.descriptionCareer.confident'),
    t('frontendAcademyBlock.descriptionCareer.program'),
    t('frontendAcademyBlock.descriptionCareer.offer'),
    t('frontendAcademyBlock.descriptionCareer.join'),
  ];

  // const linkButton = (
  //   <button
  //     onClick={() => setJoinAcademyModalOpen(true)}
  //     type="button"
  //     className="btn btn-image btn_text_dark"
  //   >
  //     <div className="btn-image__contained">
  //       <BsArrowRight />
  //     </div>
  //     {t('frontendAcademyBlock.joinTheAcademy')}
  //   </button>
  // );

  return (
    <>
      <AcademyContainer>
        <div className="content light-container-responsive">
          <BackgroundLinearText type="light">
            {t('frontendAcademyBlock.backgroundTitle')}
          </BackgroundLinearText>
          <AcademyContentContainer>
            <div className="header-container">
              <div className="header-container__title">
                <h2 className="title title_dark">{t('frontendAcademyBlock.backgroundTitle')}</h2>
                {/* {linkButton} */}
              </div>
            </div>

            <div className="academy-grid">
              <img src={academyImage} alt="students" className="academy-grid__image" />
              <div className="academy-grid__description">
                {academyDescription.map((e) => (
                  <div key={e} className="academy-grid__item">
                    {e}
                  </div>
                ))}
              </div>
            </div>
          </AcademyContentContainer>
        </div>
      </AcademyContainer>
      {isJoinAcademyModalOpen && (
        <JoinAcademyModalForm
          onClose={() => setJoinAcademyModalOpen(false)}
          handleSuccessForm={() => setSuccessJoinOpen(true)}
        />
      )}
      {isSuccessJoinOpen && (
        <GratitudeModal
          onClose={() => setSuccessJoinOpen(false)}
          title={t('frontendAcademyBlock.successJoinModal.title')}
          description={t('frontendAcademyBlock.successJoinModal.description')}
        />
      )}
    </>
  );
};

export default Academy;
