import styled from 'styled-components';
import theme from '../../../../global/styles/theme';

const mobileWidth = '905px';

export const CaseStudiesContainer = styled.div`
  width: 100%;
  margin-bottom: 100px;
  background-color: ${theme.colors.background['10']};

  .btn {
    position: absolute;
    right: 50px;
    top: 20px;
    margin-top: 20px;

    @media only screen and (max-width: ${mobileWidth}) {
      margin-top: 27px !important;
      position: initial;
    }
  }

  .content {
    margin: 0 auto;
    padding-top: 81px;

    @media only screen and (max-width: ${mobileWidth}) {
      padding-top: 40px;
    }

    &__header {
      position: relative;
      margin-bottom: 74px;
      display: flex;
      flex-direction: column;
      @media only screen and (max-width: ${mobileWidth}) {
        margin-bottom: 40px;
      }

      &-top {
        display: flex;
        justify-content: space-between;
        @media only screen and (max-width: ${mobileWidth}) {
          flex-direction: column;
        }
      }
    }
  }
`;

export const CardsContentContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 28px 34px 0 34px;
  position: relative;

  @media only screen and (max-width: ${mobileWidth}) {
    padding: 16px 16px 0 16px;
  }

  .card {
    &__title {
      max-width: 314px;
      font-weight: 800;
      font-size: 24px;
      margin-bottom: 28px;
      color: ${theme.colors.backgroundDark['20']};
      font-family: Manrope, sans-serif;
      font-style: normal;
      line-height: 132%;
      letter-spacing: 0.96px;

      @media only screen and (max-width: ${mobileWidth}) {
        font-size: 21px;
        line-height: 29px;
        margin-bottom: 16px;
      }
    }

    &__buttons-container {
      display: flex;
      gap: 14px;
      flex-wrap: wrap;
      row-gap: 16px;

      @media only screen and (max-width: 1440px) {
        width: 100%;
      }

      &-button {
        border: 1px solid #5b5e63;
        height: 36px;
        padding: 6px 12px;
        width: auto;
        box-sizing: border-box;
        color: ${theme.colors.backgroundDark['40']};
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: 0.51px;

        @media only screen and (max-width: ${mobileWidth}) {
          height: 33px;
          font-size: 14px;
          line-height: 21px;
        }
      }
    }

    &__arrow-btn {
      border: none;
      width: 52px;
      height: 52px;
      border-radius: 50%;
      color: ${theme.colors.primary['100']};
      background-color: ${theme.colors.primary['30']};
      position: absolute;
      left: 34px;
      bottom: 28px;
      font-size: 19px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      @media only screen and (max-width: ${mobileWidth}) {
        left: 16px;
        bottom: 16px;
      }

      &:hover {
        background-color: ${theme.colors.primary['20']};
      }
    }
  }
`;

export const CardsContainer = styled.div`
  width: 100%;

  @media only screen and (max-width: ${mobileWidth}) {
    display: none;
  }

  @media only screen and (min-width: 1920px) {
    max-width: 1596px;
  }
  @media only screen and (min-width: 1440px) and (max-width: 1919px) {
    max-width: 1332px;
  }
  @media only screen and (min-width: 1240px) and (max-width: 1439px) {
    max-width: 1132px;
  }
  @media only screen and (min-width: 905px) and (max-width: 1239px) {
    max-width: 908px;
  }
  @media only screen and (max-width: ${mobileWidth}) {
    max-width: 375px;
  }

  margin: 64px auto 0;
  display: grid;
  gap: 54px;
  grid-template-columns: 466fr 812fr;
  grid-template-rows: 404px 825px 419px;

  &.single-row {
    grid-template-rows: 404px;
  }
`;

export const SliderCardsContainer = styled.div`
  height: 621px;
  width: 343px;
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;
  margin: 0 auto;

  .mobile-up-content {
    height: 300px;
    width: 100%;
    background: ${theme.colors.gradient['4']};
    border-radius: 12px 12px 0 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    &__slider-image {
      max-width: 100%;
      max-height: 100%;
      padding-top: 30px;
      transition: transform 0.9s ease;

      &:hover {
        transform: scale(1.1);
      }
    }
  }

  .mobile-bottom-content {
    height: 321px;
    width: 100%;
    background-color: ${theme.colors.background['95']};
    border-radius: 0 0 12px 12px;
  }
`;

export const SwiperContainer = styled.div`
  @media only screen and (min-width: ${mobileWidth}) {
    display: none;
  }
`;
