import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import ModalWindow from '../../../../components/ModalWindow';
import theme from '../../../../global/styles/theme';
import { SubmitFormContainer } from './SubmitForm.styled';
import Input from '../../../../components/Input';
import Textarea from '../../../../components/Textarea';
import Checkbox from '../../../../components/CheckBox';

const SubmitForm = ({ onClose }) => {
  const { t } = useTranslation();
  const formik = useFormikContext();

  return (
    <ModalWindow onClose={onClose} background={theme.colors.background['10']}>
      <SubmitFormContainer>
        <div className="submit-form__title">{t('submitBriefForm.title')}</div>
        <div className="submit-form__description">{t('submitBriefForm.description')}</div>
        <div className="submit-form__fields">
          <Input
            name="name"
            error={formik.touched.name && formik.errors.name}
            label={t(`mainDataForm.formFields.name`)}
            placeholder={t(`mainDataForm.placeholderFields.name`)}
            required
          />
          <Input
            name="email"
            error={formik.touched.email && formik.errors.email}
            label={t(`mainDataForm.formFields.email`)}
            placeholder={t(`mainDataForm.placeholderFields.email`)}
            required
          />
        </div>
        <Textarea
          name="message"
          label={t(`mainDataForm.formFields.message`)}
          placeholder={t(`mainDataForm.placeholderFields.message`)}
        />
        <div>
          <Checkbox
            checkBoxName="agreement"
            title={t('mainDataForm.agreement')}
            error={formik.touched.agreement && formik.errors.agreement}
          />
        </div>
        <button className="btn btn_primary_contained submit-form__submit " type="submit">
          {t('mainDataForm.buttonText')}
        </button>
      </SubmitFormContainer>
    </ModalWindow>
  );
};

SubmitForm.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default SubmitForm;
