import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { setupListeners } from '@reduxjs/toolkit/query';
import rootReducer from './rootReducer';
import rootSaga from './rootSaga';
import { caseStudiesService } from './global/service/caseStudiesService';
import { feedbacksService } from './global/service/feedBacksService';
import { testimonialsService } from './global/service/testimonialsService';
import { companiesService } from './global/service/companiesService';
import { factsAndNumberService } from './global/service/factsAndNumbersService';
import { joinAcademyService } from './global/service/joinAcademyService';
import { briefService } from './global/service/briefService';
import { jobsService } from './global/service/jobsService';
import { industryService } from './global/service/industryService';

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore payload field in all actions
        // meta.arg prevents errors in the console
        ignoredActionPaths: ['payload', 'meta.arg'],
      },
    })
      .concat(sagaMiddleware)
      .concat(companiesService.middleware)
      .concat(testimonialsService.middleware)
      .concat(factsAndNumberService.middleware)
      .concat(feedbacksService.middleware)
      .concat(caseStudiesService.middleware)
      .concat(joinAcademyService.middleware)
      .concat(briefService.middleware)
      .concat(jobsService.middleware)
      .concat(industryService.middleware),
});

setupListeners(store.dispatch);

sagaMiddleware.run(rootSaga);

export default store;
