import styled from 'styled-components';
import theme from '../../../../global/styles/theme';
import { mobileWidth } from '../../../../global/styles/Global.styled';

export const SolutionsContainer = styled('div')`
  margin: 0 0 25px 0;
`;

export const SolutionsContentContainer = styled('div')`
  margin: 0 auto;
  padding: 120px 92px;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;

  @media only screen and (max-width: ${mobileWidth}) {
    display: flex;
    flex-direction: column;
    padding: 30px 16px;
  }

  .content {
    &__left-block {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
        max-width: 500px;
      }

      @media only screen and (max-width: ${mobileWidth}) {
        justify-content: flex-start;
        align-items: flex-start;
      }
    }

    &__right-block {
      display: flex;
      flex-direction: column;
      gap: 40px;
      padding-top: 20px;
    }

    &__descriptions {
      display: flex;
      flex-direction: column;
      gap: 20px;

      &-item {
        font-size: 19px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: 0.38px;
        color: ${theme.colors.text['90']};
      }
    }

    &__features {
      display: flex;
      flex-direction: column;
      gap: 13px;

      &-title {
        font-size: 21px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0.42px;
        color: ${theme.colors.text['95']};
        padding-bottom: 10px;
      }

      &-item {
        font-size: 19px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: 0.38px;
        color: ${theme.colors.text['95']};
        display: flex;
        gap: 16px;
        align-items: center;

        &-line {
          width: 19px;
          height: 2px;
          background: linear-gradient(rgba(3, 220, 217, 1), rgba(5, 75, 70, 1));
        }
      }
    }
  }
`;
