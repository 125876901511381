import i18next from '../../global/translations/i18next';

export const getTermsData = () => {
  const data = {
    provision: {
      question: i18next.t('termsPage.data.provision.question'),
      answer: i18next.t('termsPage.data.provision.answer'),
    },
    termsAndDefinitions: {
      question: i18next.t('termsPage.data.termsAndDefinitions.question'),
      answer: i18next.t('termsPage.data.termsAndDefinitions.answer'),
    },
    subject: {
      question: i18next.t('termsPage.data.subject.question'),
      answer: i18next.t('termsPage.data.subject.answer'),
    },
    obligationsPerformer: {
      question: i18next.t('termsPage.data.obligationsPerformer.question'),
      answer: i18next.t('termsPage.data.obligationsPerformer.answer'),
    },
    obligationsCustomer: {
      question: i18next.t('termsPage.data.obligationsCustomer.question'),
      answer: i18next.t('termsPage.data.obligationsCustomer.answer'),
    },
    procedure: {
      question: i18next.t('termsPage.data.procedure.question'),
      answer: i18next.t('termsPage.data.procedure.answer'),
    },
    contract: {
      question: i18next.t('termsPage.data.contract.question'),
      answer: i18next.t('termsPage.data.contract.answer'),
    },
    allProcedure: {
      question: i18next.t('termsPage.data.allProcedure.question'),
      answer: i18next.t('termsPage.data.allProcedure.answer'),
    },
    responsibility: {
      question: i18next.t('termsPage.data.responsibility.question'),
      answer: i18next.t('termsPage.data.responsibility.answer'),
    },
    forceMajuere: {
      question: i18next.t('termsPage.data.forceMajuere.question'),
      answer: i18next.t('termsPage.data.forceMajuere.answer'),
    },
    otherTerms: {
      question: i18next.t('termsPage.data.otherTerms.question'),
      answer: i18next.t('termsPage.data.otherTerms.answer'),
    },
    details: {
      question: i18next.t('termsPage.data.details.question'),
      answer: i18next.t('termsPage.data.details.answer'),
    },
  };

  return Object.entries(data);
};
