import React, { useMemo } from 'react';
import HelmetHead from '../../components/HelmetHead';
import { JobsPageContainer } from './JobsPage.styled';
import ApplyForJob from './components/ApplyForJob';
import OpenJobs from './components/OpenJobs/OpenJobs';
import { useGetOpenJobsQuery } from '../../global/service/jobsService';
import Loader from '../../components/Loader';

const JobsPage = () => {
  const { data, isLoading } = useGetOpenJobsQuery();

  const vacanciesNumber = useMemo(
    () => (data ? data.reduce((sum, el) => sum + el.vacancies.length, 0) : null),
    [data],
  );

  return (
    <>
      <HelmetHead page="jobsPage" />

      <JobsPageContainer>
        {isLoading ? (
          <Loader />
        ) : (
          <div>{vacanciesNumber ? <OpenJobs data={data} /> : <ApplyForJob />}</div>
        )}
      </JobsPageContainer>
    </>
  );
};

export default JobsPage;
