import React from 'react';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { careerValidationSchema } from '../../global/validationShemas';
import Input from '../Input';
import Textarea from '../Textarea';
import FileUpload from '../FileUpload';
import { CareerFormContainer } from './CareerForm.styled';
import { formikInjectedPropsTypes } from '../../global/types';

const CareerForm = ({ handleSubmitForm, theme }) => {
  const { t } = useTranslation();

  return (
    <CareerFormContainer>
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
          linkedin_link: '',
          message: '',
          file: '',
        }}
        enableReinitialize
        validationSchema={careerValidationSchema}
        onSubmit={handleSubmitForm}
      >
        {(props) => (
          <Form>
            <div className="career__form-fields">
              <Input
                name="firstName"
                error={props.touched.firstName && props.errors.firstName}
                label={t(`mainDataForm.formFields.firstName`)}
                placeholder={t(`mainDataForm.placeholderFields.firstName`)}
                required
                type={theme}
              />
              <Input
                name="lastName"
                error={props.touched.lastName && props.errors.lastName}
                label={t(`mainDataForm.formFields.lastName`)}
                placeholder={t(`mainDataForm.placeholderFields.lastName`)}
                required
                type={theme}
              />
              <Input
                name="email"
                error={props.touched.email && props.errors.email}
                label={t(`mainDataForm.formFields.email`)}
                placeholder={t(`mainDataForm.placeholderFields.email`)}
                required
                type={theme}
              />
              <Input
                name="linkedin_link"
                error={props.touched.linkedin_link && props.errors.linkedin_link}
                label={t(`mainDataForm.formFields.linkedin`)}
                placeholder={t(`mainDataForm.placeholderFields.linkedin`)}
                type={theme}
              />
            </div>
            <div className="career__form-message">
              <Textarea
                name="message"
                label={t(`mainDataForm.formFields.message`)}
                placeholder={t(`mainDataForm.placeholderFields.message`)}
                theme={theme}
              />
            </div>
            <div className="career__form-finish">
              <div className="career__form-file">
                <FileUpload
                  name="file"
                  type="text"
                  label="import"
                  title="Resume/CV"
                  required
                  error={props.touched.file && props.errors.file}
                  theme={theme}
                />
              </div>
              <button className="btn btn_primary_contained career__form-send" type="submit">
                {t('mainDataForm.buttonText')}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </CareerFormContainer>
  );
};

CareerForm.propTypes = {
  ...formikInjectedPropsTypes,
  handleSubmitForm: PropTypes.func.isRequired,
  theme: PropTypes.oneOf(['light', 'dark']),
};

CareerForm.defaultProps = {
  theme: 'dark',
};
export default CareerForm;
