import styled from 'styled-components';
import { mobileWidth } from '../../../../global/styles/Global.styled';
import theme from '../../../../global/styles/theme';

export const BenefitsContainer = styled.div`
  padding-bottom: 64px;
  padding-top: 64px;
`;

export const BenefitsContentContainer = styled.div`
  margin: 0 auto;

  .header-container {
    padding: 81px 0 0 78px;

    @media only screen and (max-width: ${mobileWidth}) {
      padding: 40px 16px;
    }

    .title {
      text-align: center;
    }

    &__subtitle {
      max-width: none;
      text-align: center;
    }
  }

  .benefits {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    padding: 74px 0 86px 78px;
    row-gap: 88px;

    @media only screen and (max-width: ${mobileWidth}) {
      display: none;
    }

    &__image {
      width: 259px;
      height: 173px;
      border-radius: 13px;
    }

    &__text {
      font-size: 21px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0.42px;
      color: ${theme.colors.text['95']};
      width: 179px;
    }

    &__container {
      display: flex;
      gap: 24px;

      @media only screen and (max-width: 1250px) {
        flex-direction: column;
      }
    }
  }
`;
