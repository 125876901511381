import styled from 'styled-components';
import theme from '../../global/styles/theme';
import { mobileWidth } from '../../global/styles/Global.styled';

export const FileUploadContainer = styled('div')`
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: ${mobileWidth}) {
    width: 100%;
  }

  input[type='file'] {
    opacity: 0;
    z-index: -1;
    position: absolute;
  }

  input[type='file'] + label {
    cursor: pointer;
    outline: none !important;
    border-radius: 4px;
    color: ${theme.colors.text['90']};
    padding: 14px;
    background-color: transparent;
    height: 52px;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.02em;
    border: 0.5px dashed ${theme.colors.primary['30']};
    color: ${theme.colors.text['60']};
    text-align: center;
    overflow: hidden;
  }

  .input__file-name {
    padding-top: 8px;
    font-weight: 300;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.02em;
    color: ${theme.colors.text['70']};
  }
`;

export const FileUploadTextContainer = styled('div')`
  input[type='file'] {
    opacity: 0;
    z-index: -1;
    position: absolute;
  }

  .input-header {
    display: flex;
    gap: 12px;

    &__title {
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      letter-spacing: 0.36px;
      color: ${theme.colors.text['90']};

      span {
        color: ${theme.colors.support.error['70']};
      }

      &_light {
        color: ${theme.colors.text['30']};
      }
    }
  }

  .input-label {
    padding: 0;
    font-style: normal;
    line-height: 150%;
    text-transform: capitalize;
    cursor: pointer;
    color: ${theme.colors.primary['40']};
    font-size: 17px;
    font-weight: 300;
    letter-spacing: 0.51px;
  }

  .input-error-content {
    padding: 0;
  }

  .input__file-name {
    font-weight: 300;
    color: ${theme.colors.text['70']};
    max-width: 300px;
    font-size: 13px;
    font-style: normal;
    line-height: 150%;
    letter-spacing: 0.26px;
  }
`;
