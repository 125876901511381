import styled from 'styled-components';
import theme from '../../global/styles/theme';
import { mobileWidth } from '../../global/styles/Global.styled';

export const BriefPageContainer = styled('div')`
  background-color: ${theme.colors.background['10']};
  padding-bottom: 108px;

  @media only screen and (min-width: ${mobileWidth}) {
    min-height: calc(100vh - 410px - 71px);
  }

  @media only screen and (max-width: ${mobileWidth}) {
    padding-bottom: 30px;
  }
`;
