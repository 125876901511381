import styled from 'styled-components';
import theme from '../../global/styles/theme';

export const LoaderContainer = styled('div')`
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${theme.colors.background['10']};
  opacity: 0.75;
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    position: absolute;
    height: auto;
  }

  .MuiCircularProgress-colorPrimary {
    color: ${theme.colors.primary['30']} !important;
  }
`;
