import React from 'react';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import { Navigation } from 'swiper';
import { SliderCardsContainer, SwiperContainer } from '../../CaseStudiesMainPage.styled';
import CaseStudiesContent from '../CaseStudiesContent';
import { formattedCaseStudyInfo } from '../../../../../../global/helpers';

const Slider = ({ data }) => (
  <SwiperContainer>
    <Swiper modules={[Navigation]} loop>
      {data.map((datum) => (
        <SwiperSlide className="slide" key={datum.id}>
          <SliderCardsContainer>
            <div className="mobile-up-content">
              <img
                className="mobile-up-content__slider-image"
                src={datum.images[0].url}
                alt="screens"
              />
            </div>
            <div className="mobile-bottom-content">
              <CaseStudiesContent
                cartTitle={datum.name}
                technologiesArr={formattedCaseStudyInfo(datum)}
                projectSlug={datum.slug}
              />
            </div>
          </SliderCardsContainer>
        </SwiperSlide>
      ))}
    </Swiper>
  </SwiperContainer>
);
export default Slider;

Slider.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      images: PropTypes.arrayOf(
        PropTypes.shape({
          url: PropTypes.string.isRequired,
        }),
      ).isRequired,
      name: PropTypes.string.isRequired,
      technologies: PropTypes.arrayOf(
        PropTypes.shape({ id: PropTypes.number, name: PropTypes.string }),
      ).isRequired,
    }),
  ).isRequired,
};
