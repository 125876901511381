import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { GratitudeContainer } from './GratitudeModal.styled';
import ModalWindow from '../ModalWindow';

const GratitudeModal = ({ onClose, title, description }) => {
  useEffect(() => {
    const timeout = setTimeout(() => {
      onClose();
    }, 5000);
    return () => clearTimeout(timeout);
  });

  return (
    <ModalWindow onClose={onClose} close={false} height="max-content" width="295px">
      <GratitudeContainer>
        {title && <div className="modal__title">{title}</div>}
        {description && <div className="modal__description">{description}</div>}
      </GratitudeContainer>
    </ModalWindow>
  );
};

GratitudeModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
};

GratitudeModal.defaultProps = {
  title: '',
  description: '',
};

export default GratitudeModal;
