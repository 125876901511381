import React from 'react';
import { BsArrowRight } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-scroll';
import { LinearGradientBlock, TestimonialsMainContainer } from './Testimonials.styled';
import BackgroundLinearText from '../../../../components/BackgroundLinearText';
import TestimonialsList from './TestimonialsList';
import 'swiper/swiper.min.css';
import { useGetTestimonialsQuery } from '../../../../global/service/testimonialsService';
import ErrorState from '../../../../components/ErrorState';

const Testimonials = () => {
  const { t } = useTranslation();
  const { data, isError } = useGetTestimonialsQuery();

  return (
    <TestimonialsMainContainer>
      <div className="card-container light-container-responsive ">
        <div className="card-container__content">
          <div className="background-title">
            <BackgroundLinearText type="light">
              {t('testimonials.backgroundText')}
            </BackgroundLinearText>
          </div>
          <div className="card-container__content-block">
            <div className="card-container__title">{t('testimonials.cardTitle')}</div>
            {data && !isError && <TestimonialsList />}
            {isError && <ErrorState type="light" />}
          </div>
        </div>
        <LinearGradientBlock>
          <div className="text"> {t('testimonials.readyToWork')}</div>
          <Link to="contact-us" offset={-50} spy smooth>
            <button type="button" className="btn btn-image btn_text_light">
              <div className="btn-image__outlined">
                <BsArrowRight />
              </div>
              {t('common.book_consultation')}
            </button>
          </Link>
        </LinearGradientBlock>
      </div>
    </TestimonialsMainContainer>
  );
};

export default Testimonials;
