import styled from 'styled-components';
import theme from '../../../../global/styles/theme';

const singleRowWidth = '1430px';
const mobileWidth = '905px';

export const ClientsMainContainer = styled.div`
  background-color: ${theme.colors.background['20']};
  width: 100%;
  margin: 140px auto;

  .clients-container {
    height: 100%;
    border-radius: 8px;
    background-color: ${theme.colors.background['95']};
    overflow: hidden;
    position: relative;
    padding: 100px 107px;

    @media only screen and (max-width: ${singleRowWidth}) {
      min-height: 339px;
      @media only screen and (max-width: ${mobileWidth}) {
        padding: 40px 16px 42px;
      }
    }

    &__content {
      position: relative;
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
    }

    &__logos-container {
      padding-top: 67px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      row-gap: 90px;
      column-gap: 160px;
      align-items: center;

      @media only screen and (max-width: ${singleRowWidth}) {
        grid-auto-flow: column;
        column-gap: 48px;
        overflow: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      &-logo {
        filter: grayscale(100%);

        @media only screen and (min-width: ${singleRowWidth}) {
          width: 100%;
          height: auto;
        }

        @media only screen and (max-width: ${singleRowWidth}) {
          width: auto;
          max-height: 30px;
          height: 100%;
        }

        &:hover {
          filter: none;
          transition: 1s;
        }
      }
    }

    &__card-title {
      font-weight: 700;
      font-size: 48px;
      line-height: 63.36px;
      letter-spacing: 0.02em;
      color: ${theme.colors.text['20']};
      padding-bottom: 12px;
    }
  }
  .background-title {
    padding-top: 19px;
    position: absolute;
    top: 0;
    left: 0;
  }
`;
