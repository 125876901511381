import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-scroll';
import { StartCooperationContainer } from './StartCooperation.styled';
import Layout from '../../../../components/Layout';

const StartCooperation = () => {
  const { t } = useTranslation();

  return (
    <StartCooperationContainer>
      <Layout>
        <div className="container">
          <div className="container__title">{t('startCooperationBlock.title')}</div>
          <Link to="cooperation" offset={-50} spy smooth>
            <button type="button" className="btn btn_primary_contained">
              {t('startCooperationBlock.button')}
            </button>
          </Link>
        </div>
      </Layout>
    </StartCooperationContainer>
  );
};

export default StartCooperation;
