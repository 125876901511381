import * as Yup from 'yup';

const SUPPORTED_FORMATS = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'application/pdf',
  'application/msword',
];

export const dataFormValidationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  email: Yup.string()
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      'Invalid Email!',
    )
    .required('Required'),
  select: Yup.string(),
  message: Yup.string(),
  link: Yup.string().matches(
    /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    'Enter correct url!',
  ),
  agreement: Yup.bool().oneOf([true]).required('Must be true'),
  file: Yup.mixed()
    .test('size', 'Size too large', (value) => !value || (value && value.size <= 1024 * 1024))
    .test(
      'format',
      'Format does not support',
      (value) => !value || (value && SUPPORTED_FORMATS.includes(value.type)),
    ),
});

export const careerValidationSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  linkedin_link: Yup.string().matches(
    /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    'Enter correct url!',
  ),
  message: Yup.string(),
  file: Yup.mixed()
    .required('Required')
    .test('size', 'Size too large', (value) => !value || (value && value.size <= 1024 * 1024))
    .test(
      'format',
      'Format does not support',
      (value) => !value || (value && SUPPORTED_FORMATS.includes(value.type)),
    ),
});
