import React from 'react';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import { TextareaContainer } from './Textarea.styled';

const Textarea = ({ name, error, label, placeholder, theme }) => (
  <TextareaContainer>
    {label && (
      <label htmlFor={name} className={`input-label ${theme === 'light' && 'input-label_light'}`}>
        {label}
      </label>
    )}
    <Field
      placeholder={placeholder && placeholder}
      name={name}
      type="text"
      className={`input textarea ${error && 'input-error'}`}
      component="textarea"
    />
    {error && <div className="input-error-content">{error}</div>}
  </TextareaContainer>
);

Textarea.propTypes = {
  name: PropTypes.string.isRequired,
  error: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.string]),
  label: PropTypes.string,
  placeholder: PropTypes.string,
  theme: PropTypes.oneOf(['light', 'dark']),
};

Textarea.defaultProps = {
  error: null,
  label: '',
  placeholder: '',
  theme: 'dark',
};

export default Textarea;
