import styled from 'styled-components';
import theme from '../../../../../../../../global/styles/theme';
import { mobileWidth } from '../../../../../../../../global/styles/Global.styled';

export const TechnologyItemContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 64px;
  padding: 0 10px;
  font-family: Manrope, sans-serif;
  font-style: normal;

  @media only screen and (max-width: ${mobileWidth}) {
    gap: 24px;
  }

  .area {
    opacity: 0.3;
    color: ${theme.colors.text['90']};

    font-family: Manrope, sans-serif;
    font-size: 34px;
    font-style: normal;
    font-weight: 800;
    line-height: 140%;
    letter-spacing: 0.68px;
  }

  .content-block {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 100px;

    @media only screen and (max-width: ${mobileWidth}) {
      display: flex;
      flex-direction: column;
      gap: 64px;
    }

    &__item {
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid ${theme.colors.text['70']};
      cursor: pointer;

      &:hover {
        .content-block__item-header-logo {
          filter: none;
        }
      }

      &-header {
        display: flex;
        justify-content: space-between;

        &-name {
          color: ${theme.colors.text['90']};

          font-size: 19px;
          font-style: normal;
          font-weight: 600;
          line-height: 150%;
          letter-spacing: 0.38px;
        }

        &-logo {
          width: 23px;
          height: 23px;
          -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
        }
      }

      &-main {
        display: grid;
        grid-template-columns: 1fr 0.1fr;
        gap: 20px;
        align-items: end;
        padding: 24px 0;

        &-addition {
          color: ${theme.colors.text['80']};
          font-size: 17px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
          letter-spacing: 0.51px;
        }
      }
    }
  }
`;
