import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FirstBlockContainer } from './FirstBlock.styled';

const FirstBlock = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <FirstBlockContainer>
      <div className="container responsive-width">
        <h1 className="container__title"> {t('caseStudiesPage.title')}</h1>
        <div className="container__content"> {t('caseStudiesPage.content')}</div>
        <button
          className="container__btn btn btn_primary_contained"
          type="button"
          onClick={() => navigate('/contact-us')}
        >
          {t('caseStudiesPage.button')}
        </button>
      </div>
    </FirstBlockContainer>
  );
};

export default FirstBlock;
