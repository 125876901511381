import styled from 'styled-components';
import theme from '../../global/styles/theme';
import { mobileWidth } from '../../global/styles/Global.styled';

export const LegalInformationContainer = styled('div')`
  grid-column: 8/19;
  padding: 32px;
  border-radius: 12px;
  background-color: ${theme.colors.primary['100']};
  display: flex;
  flex-direction: column;
  gap: 32px;
  position: relative;
  z-index: 20;

  @media only screen and (max-width: ${mobileWidth}) {
    grid-column: 1/5;
    padding: 0;
    gap: 0;
  }

  .main {
    display: flex;
    flex-direction: column;
    gap: 34px;

    @media only screen and (max-width: ${mobileWidth}) {
      padding: 0 16px 32px 16px;
    }
  }

  .main-block {
    display: flex;
    flex-direction: column;
    gap: 24px;

    &__question {
      font-size: 21px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      letter-spacing: 0.42px;
      color: ${theme.colors.text['20']};
    }

    &__answer {
      font-size: 17px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      letter-spacing: 0.51px;
      color: ${theme.colors.text['20']};
    }

    &__select {
      position: sticky;
      top: 50px;
      padding: 32px 16px;
      background-color: ${theme.colors.primary['100']};
      border-radius: 12px;
    }
  }

  .legal-select {
    > input {
      pointer-events: none;
    }

    &__control {
      width: 100%;
      padding: 10px 16px;
      border-radius: 8px;
      border: none;
      box-shadow: 4px 4px 27px 0 rgba(0, 0, 0, 0.08);
    }

    &__value-container {
      padding: 0;
      margin: 0;
      > div {
        padding: 0;
        margin: 0;
      }
    }

    &__indicator-separator {
      display: none;
    }

    &__menu {
      background-color: ${theme.colors.primary['100']};
      border-radius: 8px;
      border: none;
      box-shadow: 4px 4px 27px 0 rgba(0, 0, 0, 0.08);
    }

    &__single-value {
      color: ${theme.colors.text['20']};
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      letter-spacing: 0.32px;
    }

    &__option {
      color: ${theme.colors.text['20']};
      padding: 16px;

      &--is-focused,
      &--is-selected {
        background-color: transparent;
      }

      &--is-selected {
        color: ${theme.colors.primary['40']};
      }
    }
  }
`;
