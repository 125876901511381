import styled from 'styled-components';
import theme from '../../../../global/styles/theme';
import { mobileWidth } from '../../../../global/styles/Global.styled';

export const FaqBlockContainer = styled('div')`
  margin: 0 auto 100px;
  background-color: ${theme.colors.background['95']};
  border-radius: 8px;
  position: relative;
  overflow-x: hidden;
`;

export const FaqBlockContentContainer = styled('div')`
  padding: 100px 108px;
  position: relative;
  z-index: 1;

  @media only screen and (max-width: ${mobileWidth}) {
    padding: 40px 16px 64px;
  }

  .header-container {
    margin-bottom: 64px;

    @media only screen and (max-width: ${mobileWidth}) {
      margin-bottom: 40px;
    }
  }

  .faq-block {
    &__navigation {
      cursor: pointer;
      color: ${theme.colors.primary['40']};
      padding: 0 2px;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;
