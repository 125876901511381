import styled from 'styled-components';
import theme from '../../../../global/styles/theme';

const mobileWidth = '904px';

export const FactsAndNumbersContainer = styled.div`
  background-color: ${theme.colors.background['10']};
  margin-bottom: 100px;

  .content {
    margin: 0 auto;
    padding-top: 166px;

    &__header {
      position: relative;
      margin-bottom: 74px;
      display: flex;
      justify-content: space-between;

      @media only screen and (max-width: ${mobileWidth}) {
        margin-bottom: 70px;
      }
    }
  }

  .btn {
    @media only screen and (max-width: ${mobileWidth}) {
      display: none;
    }
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  max-width: 1230px;

  @media only screen and (max-width: 1440px) {
    padding: 15px;
    margin: -15px;
    overflow: scroll;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  @media only screen and (min-width: 1440px) {
    flex-wrap: wrap;
  }

  .card {
    width: 245px;
    height: 153px;
    border: 1px solid ${theme.colors.border['30']};
    border-radius: 8px;
    transition: all 400ms ease-out;
    font-family: 'Lato', sans-serif;
    letter-spacing: 0.02em;
    color: ${theme.colors.text['90']};
    padding: 34px 24px;
    flex-shrink: 0;

    @media only screen and (max-width: ${mobileWidth}) {
      height: 149px;
    }

    @media only screen and (min-width: 1440px) {
      &:first-child {
        margin-bottom: 98.5px;
      }
    }

    &:not(:last-child) {
      margin-right: 126px;

      @media only screen and (max-width: ${mobileWidth}) {
        margin-right: 42px;
      }
    }

    > span {
      display: block;
      font-weight: 700;
      text-transform: uppercase;
      font-size: 15px;
      font-style: normal;
      line-height: 150%;
      letter-spacing: 0.45px;
    }

    > span:first-child {
      margin-bottom: 16px;
      font-weight: 600;
      font-size: 34px;
      line-height: 47.6px;
    }

    &:hover {
      transform: scale(1.1);
      background: ${theme.colors.gradient['1']};
      border: none;
      box-shadow: 10px, 10px ${theme.colors.background['0']};
    }
  }

  .dummy {
    width: 182px;
    display: none;

    @media only screen and (min-width: 1440px) {
      display: block;
    }
  }
`;
