import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { SliderContainer } from './BenefitsSlider.styled';
import SwiperNavigator from '../../../../../../components/SwiperNavigator';

const BenefitsSlider = ({ benefitsData }) => {
  const { t } = useTranslation();
  const [currentSlide, setCurrentSlide] = useState(0);

  return (
    <SliderContainer>
      <Swiper
        onBeforeTransitionStart={(swiper) => {
          setCurrentSlide(swiper.activeIndex);
        }}
        autoHeight
        speed={800}
      >
        <span slot="container-start">
          <SwiperNavigator steps={benefitsData.length} currentStep={currentSlide} />
        </span>

        {benefitsData.map((benefit) => (
          <SwiperSlide key={benefit.id}>
            <div className="slide__content">
              <img className="slide__content-image" src={benefit.image} alt="benefit" />
              <div className="slide__content-text">{t(`${benefit.benefit}`)}</div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </SliderContainer>
  );
};

export default BenefitsSlider;

BenefitsSlider.propTypes = {
  benefitsData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      image: PropTypes.string.isRequired,
      benefit: PropTypes.string.isRequired,
    }),
  ).isRequired,
};
