import { useEffect, useState } from 'react';

const useDesktopSize = () => {
  const [isDesktop, setIsDesktop] = useState(null);
  const [isDesktop1920, setIsDesktop1920] = useState(null);
  const [isDesktop1240, setIsDesktop1240] = useState(null);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleResize = () => {
        setIsDesktop(window.innerWidth >= 905);
        setIsDesktop1920(window.innerWidth >= 1920);
        setIsDesktop1240(window.innerWidth < 1240);
      };
      window.addEventListener('resize', handleResize);

      handleResize();
      return () => window.removeEventListener('resize', handleResize);
    }
    return undefined;
  }, []);

  return {
    isDesktop,
    isDesktop1920,
    isDesktop1240,
  };
};

export default useDesktopSize;
