import React from 'react';
import { useTranslation } from 'react-i18next';
import { BsArrowRight } from 'react-icons/bs';
import { useParams } from 'react-router-dom';
import { TestimonialsMainContent } from '../../../MainPage/components/Testimonials/Testimonials.styled';
import { useGetOneCaseStudyQuery } from '../../../../global/service/caseStudiesService';
import {
  ClientLinearGradientBlock,
  ClientTestimonialsMainContainer,
} from './ClientTestimonial.styled';

const ClientTestimonial = () => {
  const { t } = useTranslation();
  const { caseName } = useParams();
  const { data } = useGetOneCaseStudyQuery(caseName);

  return (
    <ClientTestimonialsMainContainer>
      <div className="card-container light-container-responsive ">
        <div className="card-container__content">
          <div className="card-container__content-block">
            <TestimonialsMainContent>
              <div className="client-info-container">
                <div className="client-info-container__content">
                  <img
                    alt="client avatar"
                    src={data.testimonial.customer.photo}
                    className="client-info-container__content-photo"
                  />
                  <div>
                    <div className="client-info-container__content-name">
                      {data.testimonial.customer.name}
                    </div>
                    <div className="client-info-container__content-position">
                      {data.testimonial.customer.position}
                    </div>
                  </div>
                </div>
                <img
                  alt="company logo"
                  src={data.testimonial.customer.company.logo}
                  className="client-info-container__logo"
                />
              </div>
              <div className="client-testimonial">{data.testimonial.text.value}</div>
            </TestimonialsMainContent>
          </div>
        </div>
        <ClientLinearGradientBlock>
          <div className="text"> {t('testimonials.readyToWork')}</div>
          <button type="button" className="btn btn-image btn_text_light">
            <div className="btn-image__outlined">
              <BsArrowRight />
            </div>
            {t('common.talk_to_expert')}
          </button>
        </ClientLinearGradientBlock>
      </div>
    </ClientTestimonialsMainContainer>
  );
};

export default ClientTestimonial;
