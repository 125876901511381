const theme = {
  colors: {
    primary: {
      20: '#0A5756',
      30: '#0B7978',
      40: '#0D9E9C',
      60: '#35DCDA',
      100: '#FFFFFF',
    },
    background: {
      99: '#F5F9FA',
      95: '#F8F8F8',
      0: '#000000',
      10: '#000E11',
      20: '#001318',
      30: '#001920',
      80: '#C0C3CA',
      select: '#040A0F',
    },
    backgroundDark: {
      95: '#EBEDEF',
      90: '#DDDFE2',
      80: '#C0C3CA',
      70: '#9B9DA3',
      60: '#7D8188',
      20: '#131314',
      30: '#1C1C1E',
      40: '#303235',
    },
    stroke: {
      95: '#EFEFEF',
      90: '#D9D9D9',
      70: '#909193',
      50: '#5B5E63',
      80: '#B7B7B7',
    },
    text: {
      95: '#EBEDEF',
      90: '#DDDFE2',
      80: '#C0C3CA',
      70: '#9B9DA3',
      60: '#7D8188',
      20: '#131314',
      30: '#1C1C1E',
      40: '#303235',
      50: '#17171B',
    },
    border: {
      0: '#0D929000',
      30: '#0D92904C',
    },
    gradient: {
      1: 'linear-gradient(180deg, #115554 0%, #0C1115 100%)',
      2: 'linear-gradient(180.98deg, rgba(231, 234, 234, 0.2) 0.84%, rgba(246, 248, 248, 0) 84.61%)',
      3: 'linear-gradient(179.93deg, rgba(231, 234, 234, 0.9) 0.06%, rgba(246, 248, 248, 0.9) 103.68%)',
      4: 'linear-gradient(180deg, #115554 0%, #00070C 100%)',
      5: 'linear-gradient(180deg, rgba(13, 158, 156, 0.7) 0%, rgba(0, 51, 54, 0.7) 100%)',
    },
    support: {
      error: {
        70: '#F97D7D',
        40: '#B02222',
      },
      warning: {
        1: '#D19A00',
      },
      success: {
        30: '#0B7978',
      },
      stroke: {
        90: '#D9D9D9',
        70: '#909193',
        50: '#5B5E63',
      },
    },
  },
};

export default theme;
