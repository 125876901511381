import styled from 'styled-components';
import theme from '../../../../global/styles/theme';
import { mobileWidth } from '../../../../global/styles/Global.styled';

export const OurTeamContainer = styled('div')`
  @keyframes fadeInUp {
    0% {
      transform: translateY(15%);
      opacity: 0;
      background-color: rgba(0, 14, 17, 0.3);
    }
    100% {
      transform: translateY(0%);
      opacity: 1;
      background-color: rgba(0, 14, 17, 1);
    }
  }

  .content {
    grid-column: 1/19;

    @media only screen and (max-width: ${mobileWidth}) {
      grid-column: 1/5;
      margin: 40px 0 100px;
    }

    &__header-container {
      padding: 80px 0 40px;
    }

    &__team-container {
      padding: 85px 0 170px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 85px;

      @media only screen and (max-width: 1240px) {
        gap: 40px;
      }

      @media only screen and (max-width: ${mobileWidth}) {
        padding: 64px 0 122px;
        display: flex;
        flex-direction: column;
      }
    }

    &__person {
      position: relative;
      padding: 32px 24px;
      border: 1px solid ${theme.colors.primary['40']};
      border-radius: 8px;

      display: flex;
      gap: 32px;

      @media only screen and (max-width: ${mobileWidth}) {
        display: flex;
        flex-direction: column;
      }

      img {
        width: 100%;
        height: auto;
        object-fit: contain;
        border-radius: 12px;
      }

      &-info {
        display: flex;
        flex-direction: column;
        gap: 12px;

        &-header {
          display: flex;
          flex-direction: column;
          gap: 8px;
        }

        &-name {
          font-size: 21px;
          font-style: normal;
          font-weight: 900;
          line-height: 140%;
          letter-spacing: 0.84px;
          color: ${theme.colors.primary['40']};
        }

        &-position {
          font-size: 19px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
          letter-spacing: 0.38px;
          color: ${theme.colors.text['95']};
        }

        &-line {
          border-radius: 1px;
          height: 1px;
          background-color: ${theme.colors.primary['40']};
        }

        &-links {
          display: flex;
          gap: 16px;
          flex-direction: column;

          svg {
            width: 24px;
            height: 24px;
          }
        }

        &-email,
        &-linkedin {
          display: flex;
          gap: 16px;
          word-break: break-all;

          a {
            font-size: 17px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            letter-spacing: 0.51px;
            color: ${theme.colors.text['95']};
            word-break: break-all;

            &:hover {
              text-decoration: underline;
            }
          }
        }

        &-linkedin {
          a {
            @media only screen and (max-width: ${mobileWidth}) {
              overflow: hidden;
              position: relative;
              display: inline-block;
              text-overflow: ellipsis;
              white-space: nowrap;
              width: 95%;
              max-width: 250px;
            }
          }
        }
      }
    }

    .overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: max-content;
      width: 100%;
      opacity: 0;
      transition: 0.5s ease;
      background-color: rgba(0, 14, 17, 1);
      border-radius: 8px;
      border: 1px solid ${theme.colors.primary['40']};
      padding: 40px 24px;

      display: flex;
      gap: 24px;
      flex-direction: column;

      &__help,
      &__contact-connect {
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: 0.36px;
        color: ${theme.colors.text['95']};
      }

      &__line {
      }

      &__contact {
      }
    }
  }
`;
