import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { CounterContainer } from './Counter.styled';

const Counter = ({ onIncrease, onDecrease, id, fieldName }) => (
  <Field name={fieldName}>
    {({ field }) => {
      const counterValue = field.value[id] || 0;

      return (
        <CounterContainer>
          <button
            className={`counter__btn ${counterValue === 0 && 'counter__btn_disabled'}`}
            type="button"
            onClick={onDecrease}
            disabled={counterValue === 0}
          >
            -
          </button>
          <div className="counter__output">{counterValue}</div>
          <button className="counter__btn" type="button" onClick={onIncrease}>
            +
          </button>
        </CounterContainer>
      );
    }}
  </Field>
);

export default Counter;

Counter.propTypes = {
  id: PropTypes.number.isRequired,
  onIncrease: PropTypes.func.isRequired,
  onDecrease: PropTypes.func.isRequired,
  fieldName: PropTypes.string.isRequired,
};
