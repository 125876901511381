import styled from 'styled-components';
import theme from '../../../../global/styles/theme';
import { mobileWidth } from '../../../../global/styles/Global.styled';

export const ServiceWrapper = styled('div')`
  position: relative;
  margin: 0 auto;

  @media only screen and (min-width: 1920px) {
    max-width: 2000px;
  }

  @media only screen and (min-width: 1440px) and (max-width: 1919px) {
    max-width: 1500px;
  }

  overflow-x: hidden;
`;

export const ServicesBlockContainer = styled('div')`
  margin: 0 auto 100px;
  background-color: ${theme.colors.background['95']};
  border-radius: 8px;
  position: relative;
`;

export const ServiceImageContainer = styled('div')`
  margin: 0 auto;
  position: absolute;
  top: 282.5px;
  right: 0;

  img {
    max-height: 611px;
    width: auto;
    border-radius: 8px 0 0 0;
  }

  @media only screen and (max-width: 1439px) {
    display: none;
  }
`;

export const ServicesBlockContentContainer = styled('div')`
  padding: 100px 108px;
  position: relative;
  z-index: 1;

  @media only screen and (max-width: ${mobileWidth}) {
    padding: 40px 16px 64px;
  }

  .header-container {
    margin-bottom: 64px;

    @media only screen and (max-width: ${mobileWidth}) {
      margin-bottom: 40px;
    }

    &-title {
      display: flex;
      justify-content: space-between;
    }

    .btn-mobile {
      margin: 15px 0 0 -15px;
    }
  }

  .content-container {
    @media only screen and (min-width: 1440px) {
      max-width: 593px;
    }
  }
`;
