import styled from 'styled-components';
import theme from '../../global/styles/theme';

export const LegalTopicsContainer = styled('div')`
  grid-column: 1/7;
  padding: 32px;
  border-radius: 12px;
  border: 1px solid ${theme.colors.stroke['50']};
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: sticky;
  top: 80px;

  .content {
    &__item {
      font-size: 19px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
      letter-spacing: 0.38px;
      color: ${theme.colors.text['95']};
      cursor: pointer;

      &:hover {
        color: ${theme.colors.primary['40']};
      }
    }
  }
`;
