import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { Link } from 'react-scroll';
import { SummaryItemContainer } from './SummaryItem.styled';
import { ReactComponent as EditIcon } from '../../../../../../global/images/common/edit-pencil.svg';
import { getValuesForSummaryItem } from '../../../../helpers';

const SummaryItem = ({ title, valueKey, isShowed, summaryItemData }) => {
  const formik = useFormikContext();

  const bodyValue = () =>
    getValuesForSummaryItem(summaryItemData[valueKey], formik.values[valueKey], valueKey);

  const header = (name) => (
    <div className="header-item">
      <div className="header-item__name">{name}</div>
      <Link
        to={valueKey}
        offset={-150}
        spy
        smooth
        duration={500}
        className="header-item__edit-icon"
      >
        <EditIcon />
      </Link>
    </div>
  );

  return (
    !!isShowed && (
      <SummaryItemContainer>
        {header(title)}
        <div className="content-item">{bodyValue()}</div>
      </SummaryItemContainer>
    )
  );
};

SummaryItem.propTypes = {
  title: PropTypes.string.isRequired,
  valueKey: PropTypes.string.isRequired,
  isShowed: PropTypes.bool.isRequired,
  summaryItemData: PropTypes.shape({}).isRequired,
};

export default SummaryItem;
