import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-scroll';
import { MainContainer } from './PlanetAnimation.styled';
import { scrollHandlerCreator, startPlanetPosition } from './helpers';
import planetsTogether from '../../../../global/images/planets/planetsTogether.png';

const PlanetAnimation = () => {
  const { t } = useTranslation();

  const planets = useRef(null);

  useEffect(() => {
    const scrollHandler = scrollHandlerCreator(planets);

    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);

  return (
    <MainContainer>
      <div className="content responsive-width">
        <img
          ref={planets}
          style={{ top: `${startPlanetPosition}px` }}
          src={planetsTogether}
          alt="planets"
          className="content__planets"
        />
        <div className="content__first">
          <h1 className="content__first-text"> {t('firstBlock.title')}</h1>
          <div className="content__first-slogan">{t('firstBlock.slogan')}</div>
          <Link to="contact-us" offset={-50} spy smooth>
            <button className="content__first-btn btn btn_primary_contained" type="button">
              {t('common.consultation')}
            </button>
          </Link>
        </div>
      </div>
    </MainContainer>
  );
};

export default PlanetAnimation;
