import React from 'react';
import Flag from 'react-world-flags';
import PropTypes from 'prop-types';
import { TestimonialsMainContent } from './Testimonials.styled';

const TestimonialsTemplate = ({ photo, name, position, country, flag, logo, testimonial }) => (
  <TestimonialsMainContent>
    <div className="client-info-container">
      <div className="client-info-container__content">
        <img alt={name} src={photo} className="client-info-container__content-photo" />
        <div>
          <div className="client-info-container__content-name">{name}</div>
          <div className="client-info-container__content-position">{position}</div>
          <div className="client-info-container__content-country">
            <Flag code={flag} className="client-info-container__content-country-flag" />
            <span>{country}</span>
          </div>
        </div>
      </div>
      <img alt="company logo" src={logo} className="client-info-container__logo" />
    </div>
    <div className="client-testimonial">{testimonial}</div>
  </TestimonialsMainContent>
);

export default TestimonialsTemplate;

TestimonialsTemplate.propTypes = {
  photo: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  flag: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  testimonial: PropTypes.string.isRequired,
};
