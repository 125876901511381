import React from 'react';
import PropTypes from 'prop-types';
import { BsArrowRight } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { SuccessSubmitContainer } from './SuccessSubmit.styled';

const SuccessSubmit = ({ onClick }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <SuccessSubmitContainer>
      <div className="success__title">{t('brief.success.title')}</div>
      <div className="success__description">
        {t('brief.success.description')}
        <span onClick={() => navigate('/about')} className="success__description-company">
          {t('brief.success.companyProfile')}
        </span>
      </div>
      <div className="success__submit-again">
        <button onClick={onClick} type="button" className="btn btn-image btn_text_light">
          <div className="btn-image__outlined">
            <BsArrowRight />
          </div>
          {t('brief.success.again')}
        </button>
      </div>
    </SuccessSubmitContainer>
  );
};

SuccessSubmit.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default SuccessSubmit;
