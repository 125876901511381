import styled from 'styled-components';
import theme from '../../../../global/styles/theme';
import { mobileWidth } from '../../../../global/styles/Global.styled';

export const TechnologiesAndTeamContainer = styled('div')`
  .content {
    padding: 112px 0;
    grid-column: 1/19;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 16px;
    row-gap: 64px;

    @media only screen and (max-width: ${mobileWidth}) {
      grid-column: 1/5;
      display: flex;
      flex-direction: column-reverse;
      padding: 150px 0;
    }

    &__block {
      display: flex;
      flex-direction: column;
      max-width: 480px;
      gap: 16px;

      &-title {
        font-family: Manrope, sans-serif;
        font-size: 34px;
        font-style: normal;
        font-weight: 800;
        line-height: 140%;
        letter-spacing: 0.68px;

        color: ${theme.colors.text['90']};
      }

      &-description {
        font-size: 19px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: 0.38px;
        color: ${theme.colors.text['90']};
      }

      &-items {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: baseline;
        row-gap: 45px;
        column-gap: 60px;
        max-width: 380px;

        @media only screen and (max-width: ${mobileWidth}) {
          padding-top: 40px;
        }

        &-team {
          grid-template-columns: 1fr 1fr 1fr;
          max-width: 453px;

          @media only screen and (max-width: ${mobileWidth}) {
            padding-bottom: 10px;
            column-gap: 48px;
          }
        }
      }
    }

    &__item {
      display: flex;
      align-items: center;
      gap: 16px;
      font-size: 19px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
      letter-spacing: 0.38px;
      color: ${theme.colors.text['90']};
      max-width: 160px;

      &-logo {
        img {
          width: 46px;
        }
      }

      &-team {
        flex-direction: column;
        align-items: flex-start;
        max-width: 100px;
      }
    }
  }
`;
