import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { CardContainer } from './CaseStudiesCard.styled';

const CaseStudiesCard = ({ cardTitle, imgUrl, technologies, projectSlug, projectName }) => (
  <CardContainer>
    <Link to={`/case-studies/${projectSlug}`}>
      <div className="content">
        <div className="content__company">{cardTitle}</div>
        <div className="content__project">{projectName}</div>
        <img className="content__img" src={imgUrl} alt={cardTitle} />
        <div className="content__technologies-container">
          {technologies.map((technology) => (
            <div key={technology.id} className="content__technologies-container-button">
              {technology.name}
            </div>
          ))}
        </div>
      </div>
    </Link>
  </CardContainer>
);

export default CaseStudiesCard;

CaseStudiesCard.propTypes = {
  projectName: PropTypes.string.isRequired,
  projectSlug: PropTypes.string.isRequired,
  cardTitle: PropTypes.string.isRequired,
  imgUrl: PropTypes.string.isRequired,
  technologies: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
};
