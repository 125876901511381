import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { CheckBoxContainer } from './CheckBox.styled';
import { ReactComponent as CheckMark } from '../../global/images/common/checkmark.svg';

const Checkbox = ({ title, error, disable, checkBoxName, onChange, value, titleTheme }) => (
  <Field name={checkBoxName}>
    {({ field }) => (
      <CheckBoxContainer className={`filterOption  ${disable && 'disable'}`}>
        <input
          type="checkbox"
          id={checkBoxName}
          className={`filterCheckbox ${error && 'filterCheckboxError'}`}
          checked={!!field.value || !!value}
          onClick={onChange}
          readOnly
          {...(!onChange && field)}
        />
        <label htmlFor={checkBoxName} className={`label ${titleTheme === 'dark' && 'label_dark'}`}>
          {title}
        </label>
        {(field.value || !!value) && (
          <div className="checkmark">
            <CheckMark />
          </div>
        )}
      </CheckBoxContainer>
    )}
  </Field>
);

Checkbox.propTypes = {
  title: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.string]),
  disable: PropTypes.bool,
  onChange: PropTypes.func,
  checkBoxName: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.oneOf([null])]),
  titleTheme: PropTypes.oneOf(['dark', 'light']),
};

Checkbox.defaultProps = {
  title: '',
  error: null,
  onChange: null,
  disable: false,
  value: null,
  titleTheme: 'light',
};

export default Checkbox;
