import styled from 'styled-components';
import theme from '../../../../../../global/styles/theme';
import { mobileWidth } from '../../../../../../global/styles/Global.styled';

export const BriefMainContainer = styled.div`
  padding-top: 140px;
  padding-bottom: 49px;

  .header-container {
    padding-top: 100px;
    position: relative;
    margin: 0 auto;
    @media only screen and (max-width: ${mobileWidth}) {
      padding: 40px 0;

      &__title {
        display: flex;
        width: 610px;

        @media only screen and (max-width: ${mobileWidth}) {
          max-width: 343px;
          width: 100%;
        }
      }
    }
  }

  .title__block {
    @media only screen and (max-width: ${mobileWidth}) {
      font-weight: 800;
      font-size: 64px;
      line-height: 84.48px;
      color: ${theme.colors.primary['100']};
    }
  }

  .halo {
    position: absolute;
    top: -70px;
    right: 40px;
    width: 300px;
    height: 300px;
    background: ${theme.colors.gradient['5']};
    filter: blur(90px);
    border-radius: 50%;
  }
`;
