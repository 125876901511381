import styled from 'styled-components';

export const TechnologiesListContainer = styled('div')`
  padding-top: 72px;

  .swiper {
    display: flex;
    flex-direction: column-reverse;
  }

  .swiper-wrapper {
    padding-top: 64px;
  }
`;
