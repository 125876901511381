import styled from 'styled-components';
import theme from '../../global/styles/theme';
import { mobileWidth } from '../../global/styles/Global.styled';

export const ContactUsContainer = styled('div')`
  background-color: ${theme.colors.background['10']};
  padding-top: 60px;
  margin: 0 auto;

  .content {
    padding-top: 125px;
    position: relative;
    margin: 0 auto;

    @media only screen and (max-width: ${mobileWidth}) {
      padding-top: 40px;
    }

    @media only screen and (max-width: 900px) and (min-width: 400px) {
      margin: 0 auto;
    }

    @media only screen and (min-width: 1980px) {
      margin: 0 auto;
      padding-left: 0;
    }

    &__title {
      color: ${theme.colors.text['90']};
      font-family: Manrope, sans-serif;
      font-weight: 800;
      font-size: 64px;
      line-height: 132%;
      letter-spacing: 0.02em;

      @media only screen and (max-width: ${mobileWidth}) {
        letter-spacing: 1.28px;
        font-size: 48px;
      }
    }

    &__subtitle {
      font-size: 18px;
      line-height: 150%;
      color: ${theme.colors.backgroundDark['90']};

      @media only screen and (max-width: ${mobileWidth}) {
        padding-bottom: 0;
      }
    }

    &__blur {
      position: absolute;
      top: -70px;
      right: 0;
      width: 300px;
      height: 300px;
      background: ${theme.colors.gradient['5']};
      border-radius: 50%;
      filter: blur(90px);

      @media only screen and (max-width: ${mobileWidth}) {
        width: 200px;
        height: 200px;
      }
    }
  }
`;

export const ContentContainer = styled('div')`
  padding-bottom: 100px;
  display: flex;
  gap: 120px;
  margin: 0 auto;
  align-items: flex-start;

  @media only screen and (max-width: 900px) and (min-width: 400px) {
    justify-content: center;
    align-items: center;
  }

  @media only screen and (max-width: ${mobileWidth}) {
    padding-left: 0;
    gap: 0;
  }

  @media only screen and (max-width: ${mobileWidth}) {
    flex-direction: column;
  }
`;

export const ContactsContainer = styled('div')`
  .contact {
    margin-top: 54px;
    display: flex;
    flex-direction: column;
    gap: 40px;

    &__address-container {
      display: flex;
      gap: 16px;
      align-items: center;
      margin-bottom: 20px;
    }

    &__title {
      font-family: Manrope, sans-serif;
      font-size: 34px;
      font-style: normal;
      font-weight: 800;
      line-height: 140%;
      letter-spacing: 0.68px;

      color: ${theme.colors.background['95']};
      margin-bottom: 20px;
    }

    &__text {
      font-size: 19px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      letter-spacing: 0.38px;
      color: ${theme.colors.backgroundDark['90']};
    }
  }
`;
