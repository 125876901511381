import styled from 'styled-components';
import theme from '../../global/styles/theme';
import { mobileWidth } from '../../global/styles/Global.styled';

export const ModalWindowContainer = styled('div')`
  @keyframes modalAnimation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  animation: modalAnimation 0.3s linear;

  background-color: ${(props) =>
    props.background ? props.background : theme.colors.background['30']};
  overflow: hidden;
  position: fixed;
  width: max-content;
  z-index: 501;
  top: 0;
  left: 0;

  @media only screen and (max-width: ${mobileWidth}) {
    width: ${(props) => (props.width ? props.width : '100%')};
    top: ${(props) => (props.width ? '10px' : '0')};
    left: ${(props) => (props.width ? '50%' : '0')};
    transform: ${(props) => (props.width ? ' translate(-50%, 0)' : 'none')};
    max-height: ${(props) => (props.height ? props.height : '100vh')};
    height: ${(props) => (props.height ? props.height : '100vh')};
    border-radius: ${(props) => (props.width ? '12px' : 'none')};
    padding: 24px 16px;
    overflow-y: auto;
    z-index: 1999;
  }

  @media only screen and (min-width: 905px) {
    border-radius: 12px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 4px 4px 27px rgba(0, 0, 0, 0.08);
    padding: 40px;
  }

  @media only screen and (max-width: 905px) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .modal-header {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    position: sticky;
    top: 0;

    &__icon-container {
      cursor: pointer;
      background-color: ${(props) =>
        props.background ? props.background : theme.colors.background['30']};
    }

    &__close {
      width: 16px;
      height: 16px;
      fill: ${theme.colors.text['90']};
      path {
        fill: ${theme.colors.text['90']};
      }
    }
  }
`;

export const ModalWindowBackdrop = styled('div')`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 290;
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  background-color: #000000;
  opacity: 0.8;
`;
