import { apiService } from './api';
import { isMockJoinAcademyService } from './isMockEnabled';
import sendStatusMock from '../../__mocks__/contact/sendFeedBack.mock.json';
import { CACHE_TAGS } from './constants';

export const joinAcademyService = apiService.injectEndpoints({
  endpoints: (builder) => ({
    joinAcademy: builder.mutation({
      query: ({ data, onSuccess, onError }) => ({
        url: `join`,
        method: 'POST',
        data,
        onSuccess,
        onError,
        isMockEnabled: isMockJoinAcademyService,
        mockData: sendStatusMock,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: CACHE_TAGS.joinAcademy, id }],
    }),
  }),
});

export const { useJoinAcademyMutation } = joinAcademyService;
