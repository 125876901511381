export const historyData = [
  {
    id: 0,
    year: 2019,
    text: 'history.2019',
  },
  {
    id: 1,
    year: 2020,
    text: 'history.2020',
  },
  {
    id: 2,
    year: 2021,
    text: 'history.2021',
  },
  {
    id: 3,
    year: 2022,
    text: 'history.2022',
  },
  {
    id: 4,
    year: 2023,
    text: 'history.2023',
  },
];
