import React from 'react';
import PropTypes from 'prop-types';
import { LayoutContainer } from './Layout.styled';

const Layout = ({ children }) => (
  <LayoutContainer>
    <div className="main-content">{children}</div>
  </LayoutContainer>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
