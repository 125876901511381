import styled from 'styled-components';
import theme from '../../../../global/styles/theme';
import { mobileWidth } from '../../../../global/styles/Global.styled';

export const NotificationContainer = styled('div')`
  position: relative;
  min-width: 472px;
  color: ${theme.colors.primary['100']};
  border-radius: 12px;
  display: flex;
  cursor: default;
  align-items: flex-start;
  padding: 16px;
  gap: 12px;

  @media only screen and (max-width: ${mobileWidth}) {
    min-width: 90vw;
  }

  &.error {
    background-color: ${theme.colors.support.error['40']};
  }

  &.success {
    background-color: ${theme.colors.support.success['30']};
  }

  &.warning {
    background-color: ${theme.colors.support.warning['1']};
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 4px;

    &__title {
      font-size: 18px;
      font-weight: 500;
      line-height: 150%;
      letter-spacing: 0.36px;
    }

    &__subtitle {
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.32px;
    }
  }

  .close {
    width: 24px;
    height: 24px;
    position: absolute;
    right: 16px;
    cursor: pointer;
  }
`;
