import { apiService } from './api';
import { isMockVacancyService } from './isMockEnabled';
import sendStatusMock from '../../__mocks__/contact/sendFeedBack.mock.json';
import { CACHE_TAGS } from './constants';

export const vacancyService = apiService.injectEndpoints({
  endpoints: (builder) => ({
    sendVacancyFeedback: builder.mutation({
      query: ({ data, onSuccess, onError }) => ({
        url: `vacancy-feedbacks`,
        method: 'POST',
        data,
        onSuccess,
        onError,
        isMockEnabled: isMockVacancyService,
        mockData: sendStatusMock,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: CACHE_TAGS.vacancy, id }],
    }),
  }),
});

export const { useSendVacancyFeedbackMutation } = vacancyService;
