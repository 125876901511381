export const INITIAL_VALUES = {
  name: '',
  email: '',
  message: '',
  agreement: false,
  industry: null,
  development_stage: null,
  consultation: [],
  software_solutions: [],
  technologies: [],
  specialists: {},
  duration: 0,
};

export const CURRENT_FORM = 'current';
export const SUCCESS_FORM = 'success';
