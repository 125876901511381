import React from 'react';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import { BiErrorCircle } from 'react-icons/bi';
import theme from '../../global/styles/theme';
import { InputContainer } from './Input.styled';

const Input = ({ name, error, label, placeholder, required, type }) => (
  <InputContainer>
    {label && (
      <label htmlFor={name} className={`input-label ${type === 'light' && 'input-label_light'}`}>
        {label}
        {required && <span className="input-label-required">&#42;</span>}
      </label>
    )}
    <Field
      placeholder={placeholder && placeholder}
      name={name}
      className={`input ${error && 'input-error'} ${type === 'light' && 'input_light'}`}
    />
    {error && (
      <div className="input-error-content">
        <BiErrorCircle style={{ color: `${theme.colors.support.error['70']}` }} />
        <div>{error}</div>
      </div>
    )}
  </InputContainer>
);

Input.propTypes = {
  name: PropTypes.string.isRequired,
  error: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.string]),
  label: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.oneOf(['light', 'dark']),
};

Input.defaultProps = {
  error: null,
  label: '',
  placeholder: '',
  required: false,
  type: 'dark',
};

export default Input;
