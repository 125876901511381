import styled from 'styled-components';
import theme from '../../../../../../global/styles/theme';
import { mobileWidth } from '../../../../../../global/styles/Global.styled';

export const ProcessContainer = styled('div')`
  max-width: 323px;

  @media only screen and (max-width: ${mobileWidth}) {
    max-width: max-content;
  }

  .process {
    &__title {
      font-size: 34px;
      line-height: 140%;
      text-transform: uppercase;
      color: ${theme.colors.text['90']};
      opacity: 0.3;
      padding-bottom: 12px;
      font-family: Manrope, sans-serif;
      font-style: normal;
      font-weight: 800;
      letter-spacing: 0.68px;
    }

    &__subtitle {
      font-size: 18px;
      line-height: 150%;
      letter-spacing: 0.02em;
      color: ${theme.colors.text['90']};
    }
  }
`;
