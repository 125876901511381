import { createReducer } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import * as actions from './actions';

const initialState = {
  notifications: [],
};

const reducer = createReducer(initialState, {
  [actions.addApplicationNotification.type]: (state, action) => {
    state.notifications = [{ ...action.payload, id: uuidv4() }];
  },
  [actions.removeApplicationNotification.type]: (state, action) => {
    state.notifications = state.notifications.filter((n) => n.id !== action.payload);
  },
  [actions.clearApplicationNotification.type]: (state) => {
    state.notifications = [];
  },
});

const notifications = (state) => state.initComponentReducer.notifications;

const selectors = {
  notifications,
};

export { selectors };
export default reducer;
