import React from 'react';
import { Link } from 'react-scroll';
import PropTypes from 'prop-types';
import { LegalTopicsContainer } from './LegalTopics.styled';

const LegalTopics = ({ topics }) => (
  <LegalTopicsContainer>
    {topics.map(([key, value]) => (
      <Link to={key} offset={-150} spy smooth duration={500} className="content__item" key={key}>
        {value.question}
      </Link>
    ))}
  </LegalTopicsContainer>
);

LegalTopics.propTypes = {
  topics: PropTypes.arrayOf(
    PropTypes.shape({
      [PropTypes.string]: {
        question: PropTypes.string,
        answer: PropTypes.string,
      },
    }),
  ).isRequired,
};

export default LegalTopics;
