import consultingImg from '../../../../global/images/howCanWeHelp/consulting.png';
import productImg from '../../../../global/images/howCanWeHelp/product-design.png';
import softwareImg from '../../../../global/images/howCanWeHelp/software-development.png';
import staffImg from '../../../../global/images/howCanWeHelp/staff.png';
import optimizationImg from '../../../../global/images/howCanWeHelp/optimization.png';
import consultingMobile from '../../../../global/images/howCanWeHelp/consulting-mobile.png';
import productMobile from '../../../../global/images/howCanWeHelp/product-design-mobile.png';
import softwareMobile from '../../../../global/images/howCanWeHelp/software-mobile.png';
import staffMobile from '../../../../global/images/howCanWeHelp/staff-mobile.png';
import optimizationMobile from '../../../../global/images/howCanWeHelp/optimization-mobile.png';

export const howCanWeHelpData = [
  {
    id: 0,
    menu: 'how_can_we_help.content.it_consultation_title',
    title: 'how_can_we_help.content.it_consultation_title',
    text: 'how_can_we_help.content.it_consultation_text',
    imgDesktop: consultingImg,
    imgMobile: consultingMobile,
  },
  {
    id: 1,
    menu: 'how_can_we_help.content.design_title',
    title: 'how_can_we_help.content.design_title',
    text: 'how_can_we_help.content.design_text',
    imgDesktop: productImg,
    imgMobile: productMobile,
  },
  {
    id: 2,
    menu: 'how_can_we_help.content.software_development_title',
    title: 'how_can_we_help.content.software_development_title',
    text: 'how_can_we_help.content.software_development_text',
    imgDesktop: softwareImg,
    imgMobile: softwareMobile,
  },
  {
    id: 3,
    menu: 'how_can_we_help.content.staff_title',
    title: 'how_can_we_help.content.staff_title',
    text: 'how_can_we_help.content.staff_text',
    imgDesktop: staffImg,
    imgMobile: staffMobile,
  },
  {
    id: 4,
    menu: 'how_can_we_help.content.optimization_title',
    title: 'how_can_we_help.content.optimization_title',
    text: 'how_can_we_help.content.optimization_text',
    imgDesktop: optimizationImg,
    imgMobile: optimizationMobile,
  },
];
