import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { BsArrowRight } from 'react-icons/bs';
import BackgroundLinearText from '../../../../components/BackgroundLinearText';
import Accordion from '../../../../components/Accordion';
import {
  ServiceWrapper,
  ServicesBlockContainer,
  ServicesBlockContentContainer,
  ServiceImageContainer,
} from './ServicesBlock.styled';
import useAccordion from '../../../../global/hooks/useAccordion';
import ServiceImage1440 from '../../../../global/images/service/system1440.jpg';
import ServiceImage1920 from '../../../../global/images/service/system1920.jpg';
import useDesktopSize from '../../../../global/hooks/useDesktopSize';
import { getServices } from './helpers';

const ServicesBlock = () => {
  const { t } = useTranslation();
  const { isDesktop1920, isDesktop } = useDesktopSize();
  const navigate = useNavigate();
  const [accordionState, handleChangeAccordionState] = useAccordion(getServices());

  const handleButtonClick = () => navigate('/services');

  const linkButton = (
    <button
      onClick={handleButtonClick}
      type="button"
      className={`btn btn-image btn_text_dark ${!isDesktop && 'btn-mobile'}`}
    >
      <div className="btn-image__contained">
        <BsArrowRight />
      </div>
      {t('common.know_more')}
    </button>
  );

  return (
    <ServiceWrapper>
      <ServicesBlockContainer className="light-container-responsive">
        <BackgroundLinearText type="light">
          {t('servicesBlock.backgroundText')}
        </BackgroundLinearText>
        <ServicesBlockContentContainer>
          <div className="header-container">
            <div className="header-container-title">
              <h2 className="title title_dark">{t('servicesBlock.title')}</h2>
              {isDesktop && linkButton}
            </div>
            <div className="subtitle subtitle_dark">{t('servicesBlock.subtitle')}</div>
            {!isDesktop && linkButton}
          </div>
          <div className="content-container">
            {getServices().map((el) => (
              <Accordion
                key={el.id}
                title={el.title}
                content={el.content}
                id={el.id}
                handleChangeAccordion={handleChangeAccordionState}
                state={accordionState}
              />
            ))}
          </div>
        </ServicesBlockContentContainer>
      </ServicesBlockContainer>
      {isDesktop && (
        <ServiceImageContainer>
          <img src={isDesktop1920 ? ServiceImage1920 : ServiceImage1440} alt="service" />
        </ServiceImageContainer>
      )}
    </ServiceWrapper>
  );
};

export default ServicesBlock;
