import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BackgroundLinearText from '../../../../components/BackgroundLinearText';
import {
  OurValuesContainer,
  OurValuesContentContainer,
  OurValuesContent,
} from './OurValues.styled';
import { scrollListenerCreator } from '../../../../global/helpers';
import { ourValuesData, heightStrategy } from './constants';

const OurValues = () => {
  const { t } = useTranslation();

  const [activeValue, setActiveValue] = useState(-1);
  const valuesRef = useRef(null);

  const images = useMemo(
    () => ourValuesData.map((el) => ({ src: el.image, key: el.key })),
    [ourValuesData],
  );

  useEffect(() => {
    const scrollListener = scrollListenerCreator(valuesRef, setActiveValue, 6);
    window.addEventListener('scroll', scrollListener);
    scrollListener();

    return () => window.removeEventListener('scroll', scrollListener);
  }, []);

  return (
    <OurValuesContainer>
      <div className="background-dark-title-outlined">
        <BackgroundLinearText type="dark">
          {t('ourValuesBlock.backgroundText')}
        </BackgroundLinearText>
      </div>
      <OurValuesContentContainer className="light-container-responsive transparent-outlined-block">
        <div className="header-container">
          <h2 className="title title_light">{t('ourValuesBlock.title')}</h2>
        </div>
        <OurValuesContent>
          <div className="images-container">
            {images.map((item, index) => (
              <img
                key={item.key}
                className={`images-container__single ${activeValue === index && 'active'}`}
                src={item.src}
                alt={item.key}
                style={{ top: heightStrategy[index] }}
              />
            ))}
          </div>
          <div className="values-container" ref={valuesRef}>
            {ourValuesData.map((value, index) => (
              <div className="values-container__item" key={value.key}>
                <div className="values-container__item-icon">
                  {' '}
                  <div
                    className={`values-container__icon ${
                      activeValue === index && 'values-container__icon_active'
                    }`}
                  >
                    <img src={value.icon} alt={value.title} />
                  </div>
                </div>
                <div
                  className={`values-container__item-content ${
                    activeValue === index && 'values-container__item-content_active'
                  }`}
                >
                  <div className="values-container__title">{t(`${value.title}`)}</div>
                  <div className="values-container__description">{t(`${value.description}`)}</div>
                </div>
              </div>
            ))}
          </div>
        </OurValuesContent>
      </OurValuesContentContainer>
    </OurValuesContainer>
  );
};

export default OurValues;
