import styled from 'styled-components';
import theme from '../../global/styles/theme';
import { mobileWidth } from '../../global/styles/Global.styled';

export const PrivacyPolicyContainer = styled('div')`
  background-color: ${theme.colors.background['10']};
  padding: 90px 0 199px 0;

  .policy-header {
    margin: 0 auto;
    padding: 125px 0 74px 0;

    @media only screпen and (max-width: ${mobileWidth}) {
      padding: 40px 0 74px 0;
    }

    .title {
      font-size: 64px;
      font-style: normal;
      font-weight: 800;
      line-height: 132%;
      letter-spacing: 1.28px;
    }

    .subtitle {
      font-weight: 400;
      color: ${theme.colors.text['90']};
      max-width: none;
    }
  }

  .blur {
    position: absolute;
    top: 135px;
    right: 140px;
    width: 300px;
    height: 300px;
    background: ${theme.colors.gradient['5']};
    filter: blur(90px);
    border-radius: 50%;

    @media only screen and (max-width: ${mobileWidth}) {
      top: 60px;
    }

    &_second {
      top: 90vh;
      opacity: 0.6;
    }
  }
`;
