import styled from 'styled-components';
import theme from '../../global/styles/theme';

const btn = `
  width: 28px;
  height: 28px;
  font-size: 23px;
  font-weight: 400;
  border: none;
`;

export const CounterContainer = styled.div`
  display: flex;
  border: 1px solid ${theme.colors.stroke['80']};
  width: 101px;
  margin-bottom: 23px;
  border-radius: 2px;

  .counter {
    &__btn {
      ${btn};
      cursor: pointer;
      background: none;
      color: ${theme.colors.stroke['80']};

      &_disabled {
        ${btn};
        color: ${theme.colors.stroke['80']};
        background-color: #efecec;
      }
    }

    &__btn:hover {
      color: ${theme.colors.primary['40']};
    }

    &__output {
      width: 45px;
      text-align: center;
      vertical-align: middle;
      line-height: 28px;
      border-left: 0.75px solid ${theme.colors.stroke['80']};
      border-right: 0.75px solid ${theme.colors.stroke['80']};
      color: ${theme.colors.text['30']};
      font-size: 14px;
      font-weight: 400;
    }
  }
`;
