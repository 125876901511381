import styled from 'styled-components';
import theme from '../../../../global/styles/theme';

const tabletWidth = '1439px';

export const AcademyContainer = styled.div`
  position: relative;
  margin: 0 auto;

  @media only screen and (min-width: 1920px) {
    max-width: 2000px;
  }

  @media only screen and (min-width: 1440px) and (max-width: 1919px) {
    max-width: 1500px;
  }

  overflow-x: hidden;

  @media only screen and (max-width: ${tabletWidth}) {
    margin: 0 auto;
  }

  .content {
    margin: 0 auto 100px;
    background-color: ${theme.colors.background['95']};
    border-radius: 8px;
    position: relative;
    padding-top: 19px;
  }
`;

export const AcademyContentContainer = styled.div`
  padding: 81px 108px;
  position: relative;
  z-index: 1;
  overflow: hidden;

  @media only screen and (max-width: 905px) {
    padding: 40px 16px 64px;
  }

  .title {
    width: 456px;

    @media only screen and (max-width: ${tabletWidth}) {
      width: 100%;
    }
  }
  .header-container {
    &__title {
      display: flex;
      justify-content: space-between;

      @media only screen and (max-width: ${tabletWidth}) {
        flex-direction: column;
      }
    }
    margin-bottom: 64px;

    @media only screen and (max-width: ${tabletWidth}) {
      margin-bottom: 40px;
    }

    &-title {
      display: flex;
      justify-content: space-between;
    }

    .btn {
      @media only screen and (max-width: ${tabletWidth}) {
        padding: 12px 0;
      }
    }
  }

  .content-container {
    width: 593px;
  }

  .academy-grid {
    @media only screen and (min-width: 1440px) {
      display: grid;
      grid-template-columns: 507px 1fr;
    }

    &__description {
      max-width: 507px;
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    &__item {
      font-size: 19px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      letter-spacing: 0.38px;
      color: ${theme.colors.text['30']};
      padding-top: 20px;
    }

    &__image {
      width: 676px;
      position: absolute;
      right: -55px;
      bottom: 0;
      object-fit: contain;

      @media only screen and (max-width: ${tabletWidth}) {
        width: 100%;
        height: auto;
        position: static;
        border-radius: 12px;
      }
    }
  }
`;
