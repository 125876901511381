import React from 'react';
import { useTranslation } from 'react-i18next';
import { FactsAndNumbersContainer, FlexContainer } from './FactsAndNumbers.styled';
import BackgroundLinearText from '../../../../components/BackgroundLinearText';
import { useGetFactsAndNumbersQuery } from '../../../../global/service/factsAndNumbersService';
import ErrorState from '../../../../components/ErrorState';

const FactsAndNumbers = () => {
  const { t } = useTranslation();

  const { data, isError } = useGetFactsAndNumbersQuery();

  return (
    <FactsAndNumbersContainer>
      <div className="background-dark-title">
        <BackgroundLinearText type="dark">{t('factsAndNumber.title')}</BackgroundLinearText>
      </div>
      <div className="content responsive-width">
        <div className="content__header">
          <div className="title title_light">{t('factsAndNumber.title')}</div>
          {/* <button type="button" className="btn btn-image btn_text_light"> */}
          {/*  <div className="btn-image__outlined"> */}
          {/*    <BsArrowRight /> */}
          {/*  </div> */}
          {/*  {t('factsAndNumber.button')} */}
          {/* </button> */}
        </div>
        {data && !isError && (
          <FlexContainer>
            <div className="card">
              <span> {data.projects}+</span>
              <span>{t('factsAndNumber.projects')}</span>
            </div>
            <div className="card">
              <span> {data.years_of_experience}</span>
              <span>{t('factsAndNumber.years_of_experience')}</span>
            </div>
            <div className="card">
              <span> {data.people_amount}+</span>
              <span>{t('factsAndNumber.people_amount')}</span>
            </div>
            <div className="dummy" />
            <div className="card">
              <span> {data.countries}</span>
              <span>{t('factsAndNumber.countries')}</span>
            </div>
            <div className="card">
              <span> {data.clutch_rate}</span>
              <span>{t('factsAndNumber.clutch_rate')}</span>
            </div>
            <div className="card">
              <span> {data.clients}+</span>
              <span>{t('factsAndNumber.clients')}</span>
            </div>
          </FlexContainer>
        )}
        {isError && <ErrorState type="dark" />}
      </div>
    </FactsAndNumbersContainer>
  );
};

export default FactsAndNumbers;
