import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-scroll';
import { AboutContainer } from './About.styled';

const About = () => {
  const { t } = useTranslation();
  return (
    <AboutContainer>
      <div className="about-container responsive-width">
        <h1 className="about-container__title"> {t('about.title')}</h1>
        <div className="about-container__content"> {t('about.content')}</div>
        <Link to="cooperation" offset={-50} spy smooth>
          <button
            className="about-container__first-block-btn btn btn_primary_contained"
            type="button"
          >
            {t('about.btn')}
          </button>
        </Link>
      </div>
      <div className="video__container">
        <div className="video__overlay" />
        <video autoPlay loop muted playsInline>
          <source
            src="https://roxers-public.s3.eu-central-1.amazonaws.com/web_media/about.mp4"
            type="video/mp4"
          />
        </video>
      </div>
    </AboutContainer>
  );
};

export default About;
