import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import ModalWindow from '../../../../../../components/ModalWindow';
import { ContentContainer } from './ContactModal.styled';
import FileUpload from '../../../../../../components/FileUpload';
import Input from '../../../../../../components/Input';

const ContactModal = ({ onClose }) => {
  const formik = useFormikContext();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleButtonClick = () => navigate('/brief');

  return (
    <ModalWindow onClose={onClose}>
      <ContentContainer>
        <div className="modal__title">{t('contactUsBlock.modal.title')}</div>
        <div className="modal__description">{t('contactUsBlock.modal.description')}</div>

        <div className="fields-block">
          <FileUpload
            name="file"
            placeholder={t('contactUsBlock.modal.file')}
            error={formik.touched.file && formik.errors.file}
          />
          <Input
            name="link"
            placeholder={t('contactUsBlock.modal.link')}
            error={formik.touched.link && formik.errors.link}
          />
          <button
            className="btn btn_primary_outlined fields-block__fill-from-btn"
            type="button"
            onClick={handleButtonClick}
          >
            {t('contactUsBlock.modal.fill_form')}
          </button>
        </div>
        <div className="btn-container">
          <button type="submit" className="btn btn_primary_contained btn-container__send">
            {' '}
            {t('contactUsBlock.modal.send')}
          </button>
        </div>
      </ContentContainer>
    </ModalWindow>
  );
};

ContactModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default ContactModal;
