import i18next from 'i18next';

export const getServices = () => [
  {
    title: i18next.t('servicesBlock.services.IT_Consulting_Advisory'),
    content: i18next.t('servicesBlock.descriptions.IT_Consulting_Advisory'),
    id: i18next.t('servicesBlock.services.IT_Consulting_Advisory'),
  },
  {
    title: i18next.t('servicesBlock.services.Product_Design'),
    content: i18next.t('servicesBlock.descriptions.Product_Design'),
    id: i18next.t('servicesBlock.services.Product_Design'),
  },
  {
    title: i18next.t('servicesBlock.services.Custom_Software_Development'),
    content: i18next.t('servicesBlock.descriptions.Custom_Software_Development'),
    id: i18next.t('servicesBlock.services.Custom_Software_Development'),
  },
  {
    title: i18next.t('servicesBlock.services.IT_Staff_Augmentation'),
    content: i18next.t('servicesBlock.descriptions.IT_Staff_Augmentation'),
    id: i18next.t('servicesBlock.services.IT_Staff_Augmentation'),
  },
  {
    title: i18next.t('servicesBlock.services.Optimisation'),
    content: i18next.t('servicesBlock.descriptions.Optimisation'),
    id: i18next.t('servicesBlock.services.Optimisation'),
  },
];
