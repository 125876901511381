import styled from 'styled-components';

export const ContentContainer = styled('div')`
  max-width: 760px;

  .fields-block {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 40px;

    @media only screen and (max-width: 1904px) {
      display: flex;
      flex-direction: column;
      grid-gap: 24px;
    }

    &__fill-from-btn {
      height: 52px;
    }
  }

  .btn-container {
    margin-top: 40px;

    display: flex;
    justify-content: flex-end;

    &__send {
      width: 148px;
    }
  }
`;
