import styled from 'styled-components';
import theme from '../../global/styles/theme';
import { mobileWidth } from '../../global/styles/Global.styled';

export const ArrowChartContainer = styled('div')`
  width: 600px;
  height: 600px;
  position: relative;
  text-align: center;
  border-radius: 100%;
  overflow: hidden;
  clip-path: circle(50% at 50% 50%);

  @media only screen and (max-width: ${mobileWidth}) {
    width: 340px;
    height: 340px;
  }

  &:after {
    content: '';
    display: block;
    background: ${theme.colors.background['20']};
    width: 43%;
    height: 43%;
    z-index: 10;
    position: absolute;
    border-radius: 100%;
    top: 29%;
    left: 29%;
  }

  section {
    height: 48%;
    width: 55%;
    position: absolute;
    text-align: center;
    color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%);
    clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%);
    background: linear-gradient(180deg, rgba(17, 85, 84, 0.35) 0%, rgba(12, 17, 21, 0.35) 100%);
    cursor: pointer;

    &:hover {
      background: linear-gradient(180deg, #115554 0%, #0c1115 100%);
      box-shadow: 4px 4px 29px rgba(0, 0, 0, 0.5), -4px -4px 38px rgba(21, 21, 21, 0.4);
    }
  }

  .active {
    color: ${theme.colors.text['95']};
    background: linear-gradient(180deg, #115554 0%, #0c1115 100%);
    box-shadow: 4px 4px 29px rgba(0, 0, 0, 0.5), -4px -4px 38px rgba(21, 21, 21, 0.4);

    label {
      color: ${theme.colors.text['95']};
    }
  }

  section label {
    position: relative;
    width: 50%;
    padding: 0 5%;
    margin: 30% 0 0 0;
    display: block;
    color: ${theme.colors.text['60']};
    font-weight: 700;
    font-size: 19px;
    line-height: 140%;
    cursor: pointer;

    @media only screen and (max-width: ${mobileWidth}) {
      font-size: 10px;
    }
  }

  section:nth-child(1) {
    transform: rotate(0);
    margin-left: 44%;
    margin-top: -12%;

    &:hover {
      label {
        transform: rotate(0) scale(1.1);
      }
    }
  }

  section:nth-child(1) label {
    transform: rotate(0);
  }

  section:nth-child(2) {
    transform: rotate(60deg);
    margin-left: 66%;
    margin-top: 26%;

    &:hover {
      label {
        transform: rotate(-60deg) scale(1.1);
      }
    }
  }

  section:nth-child(2) label {
    transform: rotate(-60deg);
  }

  section:nth-child(3) {
    transform: rotate(120deg);
    margin-left: 44%;
    margin-top: 64%;

    &:hover {
      label {
        transform: rotate(-120deg) scale(1.1);
      }
    }
  }

  section:nth-child(3) label {
    transform: rotate(-120deg);
  }

  section:nth-child(4) {
    transform: rotate(180deg);
    margin-left: 0.25%;
    margin-top: 64%;

    &:hover {
      label {
        transform: rotate(-180deg) scale(1.1);
      }
    }
  }

  section:nth-child(4) label {
    transform: rotate(-180deg);
  }

  section:nth-child(5) {
    transform: rotate(240deg);
    margin-left: -21.5%;
    margin-top: 26%;

    &:hover {
      label {
        transform: rotate(-240deg) scale(1.1);
      }
    }
  }

  section:nth-child(5) label {
    transform: rotate(-240deg);
  }

  section:nth-child(6) {
    transform: rotate(300deg);
    margin-left: 0;
    margin-top: -12%;

    &:hover {
      label {
        transform: rotate(-300deg) scale(1.1);
      }
    }
  }

  section:nth-child(6) label {
    transform: rotate(-300deg);
  }
`;
