import { apiService } from './api';
import { isMockFeedBackService } from './isMockEnabled';
import sendStatusMock from '../../__mocks__/contact/sendFeedBack.mock.json';
import { CACHE_TAGS } from './constants';

export const feedbacksService = apiService.injectEndpoints({
  endpoints: (builder) => ({
    sendFeedback: builder.mutation({
      query: ({ data, onSuccess, onError }) => ({
        url: `proposal-requests`,
        method: 'POST',
        data,
        onSuccess,
        onError,
        isMockEnabled: isMockFeedBackService,
        mockData: sendStatusMock,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: CACHE_TAGS.feedbacks, id }],
    }),
  }),
});

export const { useSendFeedbackMutation } = feedbacksService;
