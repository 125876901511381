import styled from 'styled-components';
import theme from '../../../../global/styles/theme';
import { mobileWidth } from '../../../../global/styles/Global.styled';

export const ThankYouPageContainer = styled.div`
  background-color: ${theme.colors.background['20']};
  margin: 0 auto;
  padding-top: 60px;
  padding-bottom: 200px;
  height: 80vh;

  @media only screen and (max-width: ${mobileWidth}) {
    padding-bottom: 80px;
  }

  .content {
    padding-top: 127px;
    padding-left: 108px;
    position: relative;

    @media only screen and (max-width: ${mobileWidth}) {
      padding-left: 16px;
      padding-top: 75px;
    }

    @media only screen and (min-width: 1440px) {
      margin: 0 auto;
      padding-left: 0;
    }

    @media only screen and (max-width: 900px) and (min-width: 400px) {
      margin: 0 auto;
    }

    &__title {
      color: ${theme.colors.text['90']};
      font-family: Manrope, sans-serif;
      font-weight: 800;
      font-size: 64px;
      line-height: 132%;
      letter-spacing: 1.28px;

      width: 834px;

      @media only screen and (max-width: ${mobileWidth}) {
        letter-spacing: 1.28px;
        font-size: 38px;
        width: 300px;
      }
    }

    &__subtitle {
      font-size: 18px;
      line-height: 150%;
      color: ${theme.colors.backgroundDark['90']};
      margin-top: 16px;
      width: 490px;
      margin-bottom: 35px;

      @media only screen and (max-width: ${mobileWidth}) {
        width: 300px;
      }

      &_link {
        color: ${theme.colors.primary['60']};
        padding-left: 5px;
        text-decoration: underline;
      }
    }

    &__blur {
      position: absolute;
      top: -70px;
      right: 0;
      width: 300px;
      height: 300px;
      background: ${theme.colors.gradient['5']};
      border-radius: 50%;
      filter: blur(90px);

      @media only screen and (max-width: ${mobileWidth}) {
        width: 200px;
        height: 200px;
      }
    }

    .button {
      padding-bottom: 0;
    }
  }
`;
