import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ErrorPageContainer, ErrorPageContentContainer } from './ErrorPage.styled';
import BackgroundLinearText from '../../components/BackgroundLinearText';
import { routes } from '../../components/Header/routes';
import plugIcon from '../../global/images/icons/error_page.svg';

const ErrorPage = () => {
  const { t } = useTranslation();

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <ErrorPageContainer>
      <div className="background-dark-title">
        <BackgroundLinearText type="dark">Oops!</BackgroundLinearText>
      </div>
      <ErrorPageContentContainer>
        <div className="content responsive-width">
          <div>
            <h2 className="content__title">Oops!</h2>
            <div className="content__subtitle">{t('errorPage.subtitle')}</div>
            <div className="content__error-code">{t('errorPage.errorCode')}</div>
            <div className="content__links-title">{t('errorPage.helpfulLinks')}</div>
            <ul className="content__links-container">
              {routes.map((route) => (
                <li key={route.path}>
                  <Link to={route.path} onClick={scrollToTop}>
                    {t(route.text)}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <img src={plugIcon} alt="plugs" className="content__img" />
        </div>
      </ErrorPageContentContainer>
    </ErrorPageContainer>
  );
};

export default ErrorPage;
