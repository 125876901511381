import styled from 'styled-components';
import theme from '../../../../../../global/styles/theme';

const rightContent = `{
    height: 100%;
    background-color: ${theme.colors.background['95']};
    border-radius: 0 12px 12px 0;
    position: relative;
}`;
const leftContent = `{
    height: 100%;
    border-radius: 12px 0 0 12px;
    background: ${theme.colors.gradient['4']};
    
    &:hover {
      & > img {
        transform: scale(1.1);
      }
    }

    & > img {
        transition: transform 0.9s ease;
    }
}`;

export const CaseStudiesCardContainer = styled.div`
  display: grid;

  & > div {
    overflow: hidden;
  }

  &.case-study-view {
    &--horizontal {
      grid-column: span 2;
      grid-template-columns: 750fr 582fr;

      & > div:first-child {
        ${leftContent}
      }

      & > div:last-child {
        ${rightContent}
      }
    }

    &--vertical-sm {
      grid-template-rows: 466fr 466fr;

      & > div:first-child {
        min-height: 324px;
        background: ${theme.colors.gradient['4']};
        border-radius: 12px 12px 0 0;
        overflow: hidden;

        & > img {
          transition: transform 0.9s ease;
        }

        & > img:hover {
          transform: scale(1.1);
        }
      }

      & > div:last-child {
        min-height: 376px;
        background-color: ${theme.colors.background['95']};
        border-radius: 0 0 12px 12px;
        position: relative;
      }
    }

    &--horizontal-sm {
      grid-template-columns: 383fr 429fr;

      & > div:first-child {
        ${leftContent}
      }

      & > div:last-child {
        ${rightContent}
      }
    }
  }

  .case-image-container {
    display: flex;
    align-items: center;
    justify-content: center;

    &__img {
      max-width: 100%;
      max-height: 100%;
      padding-top: 50px;
    }
  }
`;
