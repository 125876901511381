import styled from 'styled-components';
import theme from '../../global/styles/theme';
import { mobileWidth } from '../../global/styles/Global.styled';

export const CheckBoxContainer = styled('div')`
  &.disable {
    pointer-events: none;
  }

  &.filterOption {
    display: flex;
    align-items: center;
    position: relative;

    .filterCheckbox {
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      outline: none;
      margin-right: 14px;
      cursor: pointer;
      min-width: 16px;
      width: 16px;
      height: 16px;
      border-radius: 2px;
      z-index: 1;

      &:not(:checked) {
        border: 1px solid ${theme.colors.stroke['90']};
      }

      &:hover {
        border-color: ${theme.colors.primary['40']};
      }
    }

    .filterCheckboxError {
      &:not(:checked) {
        border: 1px solid ${theme.colors.support.error['70']};
        transition: border-color 0.3s linear;
      }
    }
  }

  .checkmark {
    position: absolute;
    left: 2px;
    z-index: 0;
  }

  div,
  label {
    letter-spacing: 0.02em;
    color: ${theme.colors.text['90']};
    line-height: 150%;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;

    @media only screen and (max-width: ${mobileWidth}) {
      max-width: 310px;
    }
  }

  .label_dark {
    color: ${theme.colors.text['20']};
  }
`;
