import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import BackgroundLinearText from '../../../../../../components/BackgroundLinearText';
import { BriefMainContainer } from './BriefHeader.styled';
import { CURRENT_FORM } from '../../../../constants';

const BriefHeader = ({ status }) => {
  const { t } = useTranslation();

  const backgroundText = {
    current: t('brief.title'),
    success: t('brief.success.backgroundTitle'),
    error: t('brief.titleError'),
  };

  return (
    <BriefMainContainer>
      <div className="background-dark-title">
        <BackgroundLinearText type="dark">{backgroundText[status]}</BackgroundLinearText>
      </div>
      {status === CURRENT_FORM && (
        <div className="header-container responsive-width">
          <div className="header-container__title">
            <h2 className="title title_light ">{t('brief.title')}</h2>
          </div>
          <div className="subtitle subtitle_light">{t('brief.subtitle')}</div>
        </div>
      )}
      <div className="halo" />
    </BriefMainContainer>
  );
};

BriefHeader.propTypes = {
  status: PropTypes.oneOf(['current', 'success', 'error']).isRequired,
};

export default BriefHeader;
