import styled from 'styled-components';
import theme from '../../../../global/styles/theme';
import { mobileWidth } from '../../../../global/styles/Global.styled';

export const CaseStudiesContainer = styled.div`
  width: 100%;
  background-color: ${theme.colors.background['10']};
  margin-top: 19px;

  @media only screen and (max-width: ${mobileWidth}) {
    margin-top: 112px;
  }

  .header {
    &__container {
      @media only screen and (min-width: 400px) {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 0 auto;
        padding-top: 101px;
      }

      @media only screen and (max-width: 400px) {
        padding-left: 16px;
      }

      &-title {
        font-family: Manrope, sans-serif;
        font-size: 34px;
        font-style: normal;
        font-weight: 800;
        line-height: 140%;
        letter-spacing: 0.68px;
        color: ${theme.colors.text['95']};
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        @media only screen and (max-width: ${mobileWidth}) {
          padding-top: 40px;
        }
      }

      &-subtitle {
        font-size: 19px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: 0.38px;
        color: ${theme.colors.text['80']};
        max-width: 801px;
        padding-top: 12px;
        margin-bottom: 74px;
        text-align: center;

        @media only screen and (max-width: ${mobileWidth}) {
          max-width: 343px;
        }
      }
    }
  }

  .cards {
    display: flex;
    justify-content: center;
    align-items: center;

    &__dummy {
      width: 580px;
      height: 49px;
      @media only screen and (max-width: ${mobileWidth}) {
        display: none;
      }
    }
    &__container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 58px;
      margin-bottom: 117px;

      @media only screen and (max-width: 1230px) {
        grid-template-columns: 1fr;
        margin: 0 auto;
      }

      & > div {
        display: flex;
        flex-direction: column;
        gap: 64px;

        @media only screen and (max-width: ${mobileWidth}) {
          align-items: center;
          padding-bottom: 50px;
        }
      }
    }
  }
`;
