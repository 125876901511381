import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { PreviewBlockContainer } from './PreviewBlock.styled';

const PreviewBlock = () => {
  const { t } = useTranslation();
  return (
    <PreviewBlockContainer>
      <div className="content">
        <div className="content__title">{t('career.preview.title')}</div>
        <Link to="/career/jobs">
          <button className="content__btn btn btn_primary_contained" type="button">
            {t('career.preview.button')}
          </button>
        </Link>
        <div className="content__text">{t('career.preview.submit')}</div>
      </div>
    </PreviewBlockContainer>
  );
};

export default PreviewBlock;
