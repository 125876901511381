import React from 'react';
import PropTypes from 'prop-types';
import { ProcessContainer } from './Process.styled';

const Process = ({ title, subtitle }) => (
  <ProcessContainer>
    <div className="process__title">{title}</div>
    <div className="process__subtitle">{subtitle}</div>
  </ProcessContainer>
);

Process.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default Process;
