import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Element } from 'react-scroll';
import { GetInTouchContainer } from './GetInTouch.styled';
import BackgroundLinearText from '../BackgroundLinearText';
import Layout from '../Layout';
import DataForm from '../DataForm';
import { useSendFeedbackMutation } from '../../global/service/feedBacksService';
import useNotifications from '../../global/hooks/useNotifications';
import { formattedContactData } from '../../containers/MainPage/helpers';
import GratitudeModal from '../GratitudeModal';
import Loader from '../Loader';

const GetInTouch = () => {
  const { t } = useTranslation();
  const [isContactModalOpen, setContactModalOpen] = useState(false);
  const [isGratitudeModalOpen, setGratitudeModalOpen] = useState(false);
  const { addNotification } = useNotifications();
  const [sendFeedback, state] = useSendFeedbackMutation();

  const handleSubmitForm = async (values, formikHelpers) => {
    if (values.file || values.link) {
      const onError = () => {
        addNotification({
          type: 'error',
          title: t('common.notifications.error.title'),
          subtitle: t('common.notifications.error.subtitle'),
        });
      };
      const onSuccess = () => {
        setContactModalOpen(false);
        setGratitudeModalOpen(true);
        formikHelpers.resetForm();
      };

      sendFeedback({ data: await formattedContactData(values), onSuccess, onError });
    } else {
      addNotification({
        type: 'error',
        title: t('common.notifications.one_is_required.title'),
        subtitle: t('common.notifications.one_is_required.subtitle'),
        noClose: true,
      });
    }
  };

  return (
    <>
      <Element name="cooperation">
        <GetInTouchContainer>
          <div className="background-dark-title">
            <BackgroundLinearText type="dark">{t('getInTouchBlock.title')}</BackgroundLinearText>
          </div>
          <Layout>
            <div className="content">
              <div className="content__header-container">
                <h2 className="title title_light">{t('getInTouchBlock.title')}</h2>
                <div className="subtitle subtitle_light">{t('getInTouchBlock.subtitle')}</div>
              </div>
              <DataForm
                handleSubmitForm={handleSubmitForm}
                isContactModalOpen={isContactModalOpen}
                setContactModalOpen={setContactModalOpen}
              />
            </div>
          </Layout>
          {isGratitudeModalOpen && (
            <GratitudeModal
              onClose={() => setGratitudeModalOpen(false)}
              title={t('contactUsBlock.gratitudeModal.title')}
              description={t('contactUsBlock.gratitudeModal.description')}
            />
          )}
        </GetInTouchContainer>
      </Element>
      {state.isLoading && <Loader />}
    </>
  );
};

export default GetInTouch;
