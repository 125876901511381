import i18next from '../../global/translations/i18next';

export const getPrivacyData = () => {
  const data = {
    listPersonalData: {
      question: i18next.t('privacyPage.data.listPersonalData.question'),
      answer: i18next.t('privacyPage.data.listPersonalData.answer'),
    },
    collectionPersonalUserInformation: {
      question: i18next.t('privacyPage.data.collectionPersonalUserInformation.question'),
      answer: i18next.t('privacyPage.data.collectionPersonalUserInformation.answer'),
    },
    processingPersonalInfo: {
      question: i18next.t('privacyPage.data.processingPersonalInfo.question'),
      answer: i18next.t('privacyPage.data.processingPersonalInfo.answer'),
    },
    updateInformation: {
      question: i18next.t('privacyPage.data.updateInformation.question'),
      answer: i18next.t('privacyPage.data.updateInformation.answer'),
    },
    responsibilities: {
      question: i18next.t('privacyPage.data.responsibilities.question'),
      answer: i18next.t('privacyPage.data.responsibilities.answer'),
    },
    disputeResolution: {
      question: i18next.t('privacyPage.data.disputeResolution.question'),
      answer: i18next.t('privacyPage.data.disputeResolution.answer'),
    },
    additional: {
      question: i18next.t('privacyPage.data.additional.question'),
      answer: i18next.t('privacyPage.data.additional.answer'),
    },
  };

  return Object.entries(data);
};
