import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { BsArrowRight } from 'react-icons/bs';
import { CardsContentContainer } from '../../CaseStudiesMainPage.styled';

const CaseStudiesContent = ({ cartTitle, technologiesArr, projectSlug }) => (
  <CardsContentContainer className="card">
    <div className="card__title">{cartTitle}</div>
    <div className="card__buttons-container">
      {technologiesArr.map((el) => (
        <div key={el.id} className="card__buttons-container-button">
          {el.name}
        </div>
      ))}
    </div>
    <Link to={`/case-studies/${projectSlug}`}>
      <button className="card__arrow-btn" type="button">
        <BsArrowRight />
      </button>
    </Link>
  </CardsContentContainer>
);

export default CaseStudiesContent;

CaseStudiesContent.propTypes = {
  cartTitle: PropTypes.string.isRequired,
  projectSlug: PropTypes.string.isRequired,
  technologiesArr: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    }),
  ).isRequired,
};
