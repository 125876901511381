import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import Checkbox from '../../../../../../components/CheckBox';
import QuestionTemplate from './QuestionTemplate';
import Loader from '../../../../../../components/Loader';

const MAX_OPTIONS_FOR_ONE_COLUMN = 4;

const QuestionCheckBoxAdvanced = ({ question, isOptional, options, formikFieldName }) => {
  const { values, setFieldValue } = useFormikContext();

  const checkboxHandleChange = (id) => {
    const currentValues = values[formikFieldName];

    const index = currentValues.indexOf(id);
    if (index === -1) {
      currentValues.push(id);
    } else {
      currentValues.splice(index, 1);
    }
    setFieldValue(formikFieldName, currentValues);
  };

  const optionsArePresent = options.length > 0;

  return (
    <QuestionTemplate question={question} isOptional={isOptional} name={formikFieldName}>
      <div className="checkbox-container two-columns-grid">
        {optionsArePresent ? (
          options.map((checkList) => (
            <div
              className={`checkbox-container__columns-width-${
                checkList.technologies.length > MAX_OPTIONS_FOR_ONE_COLUMN ? 'two' : 'one'
              }`}
              key={checkList.name}
            >
              <div className="checkbox-container__technology-title">{checkList.name}</div>
              <div
                className={
                  checkList.technologies.length > MAX_OPTIONS_FOR_ONE_COLUMN
                    ? 'two-columns-grid'
                    : ''
                }
              >
                {checkList.technologies &&
                  checkList.technologies.map((option) => (
                    <div className="checkbox-container__option" key={option.name}>
                      <Checkbox
                        checkBoxName={option.name}
                        onChange={() => {
                          checkboxHandleChange(option.id);
                        }}
                        value={
                          Array.isArray(values[formikFieldName]) &&
                          values[formikFieldName]?.find((id) => id === option.id)
                        }
                        title={option.name}
                        titleTheme="dark"
                      />
                    </div>
                  ))}
              </div>
            </div>
          ))
        ) : (
          <Loader />
        )}
      </div>
    </QuestionTemplate>
  );
};

export default QuestionCheckBoxAdvanced;

QuestionCheckBoxAdvanced.propTypes = {
  question: PropTypes.string.isRequired,
  isOptional: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string.isRequired,
      technologies: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
        }),
      ),
    }),
  ),
  formikFieldName: PropTypes.string.isRequired,
};

QuestionCheckBoxAdvanced.defaultProps = {
  options: [],
};
