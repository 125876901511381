import styled from 'styled-components';
import theme from '../../../../global/styles/theme';

export const SubmitFormContainer = styled('div')`
  max-width: 760px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 40px;

  .submit-form {
    &__title {
      width: 100%;
      text-align: center;
      font-size: 21px;
      font-weight: 700;
      line-height: 140%;
      letter-spacing: 0.42px;
      color: ${theme.colors.text['90']};
      margin-bottom: -20px;
    }

    &__description {
      width: 100%;
      text-align: center;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.32px;
      color: ${theme.colors.text['80']};
    }

    &__fields {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 40px;

      @media only screen and (max-width: 1024px) {
        display: flex;
        flex-direction: column;
        grid-gap: 24px;
      }
    }

    &__submit {
      width: 253px;

      @media only screen and (max-width: 1024px) {
        width: 100%;
      }
    }
  }
`;
