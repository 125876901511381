import i18next from '../../global/translations/i18next';

export const getProcessList = () => [
  {
    id: '0',
    title: i18next.t('processBlock.items.introduction'),
    subtitle: i18next.t('processBlock.descriptions.introduction'),
  },
  {
    id: '1',
    title: i18next.t('processBlock.items.discovery'),
    subtitle: i18next.t('processBlock.descriptions.discovery'),
  },
  {
    id: '2',
    title: i18next.t('processBlock.items.designDevelopment'),
    subtitle: i18next.t('processBlock.descriptions.designDevelopment'),
  },
  {
    id: '3',
    title: i18next.t('processBlock.items.testing'),
    subtitle: i18next.t('processBlock.descriptions.testing'),
  },
  {
    id: '4',
    title: i18next.t('processBlock.items.prototypingMVPDevelopment'),
    subtitle: i18next.t('processBlock.descriptions.prototypingMVPDevelopment'),
  },
  {
    id: '5',
    title: i18next.t('processBlock.items.maintenanceUpdates'),
    subtitle: i18next.t('processBlock.descriptions.maintenanceUpdates'),
  },
];
