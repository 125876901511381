import styled from 'styled-components';
import theme from '../../global/styles/theme';
import { mobileWidth } from '../../global/styles/Global.styled';

export const control = `
      outline: none !important;
      border: 0.5px solid ${theme.colors.stroke['70']};
      border-radius: 4px;
      background-color: transparent;
      height: 52px;
      width: 100%;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.02em;
      box-shadow: none;
`;

export const option = `
  padding: 12px 14px;
  line-height: 23px;
  letter-spacing: 0.02em;
  cursor: pointer;
`;

export const SelectContainer = styled('div')`
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: ${mobileWidth}) {
    width: 99%;
    margin: 0 auto;
  }

  .custom-select_dark {
    &__control {
      ${control};

      &--is-focused {
        border-color: ${theme.colors.stroke['95']} !important;
      }
    }

    &__single-value,
    &__input {
      color: ${theme.colors.text['90']} !important;
    }

    &__indicator-separator {
      display: none;
    }

    &__indicator {
      color: ${theme.colors.stroke['70']};
    }

    &__menu {
      margin-top: 6px;
      background-color: ${theme.colors.background.select};
      width: 100%;
      z-index: 20;

      &-list {
        padding: 0;
        border-radius: 4px;
      }

      &-notice {
        color: ${theme.colors.text['80']};
      }
    }

    &__option {
      ${option};
      color: ${theme.colors.text['80']};

      &:active {
        background-color: ${theme.colors.primary['20']};
      }

      &--is-focused {
        background-color: ${theme.colors.primary['20']};
      }

      &--is-selected {
        background-color: transparent;
      }
    }
  }

  .custom-select_light {
    &__control {
      ${control};

      &--is-focused {
        border-color: ${theme.colors.primary['40']} !important;
      }
    }

    &__single-value,
    &__input {
      color: ${theme.colors.backgroundDark['20']};
    }

    &__indicator-separator {
      display: none;
    }

    &__indicator {
      color: ${theme.colors.primary['40']};
    }

    &__menu {
      position: relative;
      margin-top: 6px;
      width: 100%;
      height: auto;
      &-list {
        padding: 0;
        border-radius: 4px;
        max-height: none;
        overflow-y: hidden;
      }

      &-notice {
        color: ${theme.colors.text['80']};
      }
    }

    &__option {
      ${option};
      color: ${theme.colors.backgroundDark['20']};

      &--is-focused {
        background-color: rgba(13, 158, 156, 0.1);
      }

      &--is-selected {
        background-color: transparent;
      }
    }
  }
`;
