import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProcessBlockContainer } from './ProcessBlock.styled';
import Layout from '../Layout';
import BackgroundLinearText from '../BackgroundLinearText';
import ProcessList from './components/ProcessList';
import ArrowChart from '../ArrowChart';
import { getProcessList } from './helpers';

const ProcessBlock = () => {
  const { t } = useTranslation();

  const [currentStep, setCurrentStep] = useState(0);

  // const handleButtonClick = () => navigate('/services');

  // const linkButton = (
  //   <button
  //     onClick={handleButtonClick}
  //     type="button"
  //     className={`btn btn-image btn_text_light ${!isDesktop && 'btnMobile'}`}
  //   >
  //     <div className="btn-image__outlined">
  //       <BsArrowRight />
  //     </div>
  //     {t('common.know_more')}
  //   </button>
  // );

  return (
    <ProcessBlockContainer>
      <div className="background-dark-title">
        {' '}
        <BackgroundLinearText type="dark">{t('processBlock.title')}</BackgroundLinearText>
      </div>
      <Layout>
        <div className="content">
          <div className="content__header-container">
            <div className="content__header-container-title">
              <h2 className="title title_light">{t('processBlock.title')}</h2>
              {/* {isDesktop && linkButton} */}
            </div>
            <div className="subtitle subtitle_light">{t('processBlock.subtitle')}</div>
            {/* {!isDesktop && linkButton} */}
          </div>
          <div className="content__main-container">
            <ArrowChart
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              data={getProcessList()}
            />
            <ProcessList currentStep={currentStep} setCurrentStep={setCurrentStep} />
          </div>
        </div>
      </Layout>
    </ProcessBlockContainer>
  );
};

export default ProcessBlock;
