import React from 'react';
import { CookiesProvider } from 'react-cookie';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router } from 'react-router-dom';
import InitComponent from './containers/InitComponent';
import theme from './global/styles/theme';
import './global/translations/i18next';
import './global/styles/index.scss';

const App = () => (
  <CookiesProvider>
    <ThemeProvider theme={theme}>
      <Router>
        <InitComponent />
      </Router>
    </ThemeProvider>
  </CookiesProvider>
);

export default App;
