import React, { useEffect, useState } from 'react';
import { Field, useFormikContext } from 'formik';
import { BiErrorCircle } from 'react-icons/bi';
import PropTypes from 'prop-types';
import { FileUploadContainer, FileUploadTextContainer } from './FileUpload.styled';
import * as colorsTheme from '../../global/styles/theme';

const FileUpload = ({ name, error, label, placeholder, type, title, required, theme }) => {
  const formik = useFormikContext();
  const [fileName, setFileName] = useState('');
  const handleSetFile = (file) => {
    formik.setFieldValue(name, file);
    setFileName(file.name);
  };

  useEffect(() => {
    if (formik?.isSubmitting && formik?.isValid) setFileName('');
  }, [formik?.isSubmitting]);

  const labelContent = (
    <div className="input-header">
      {title && (
        <div className={`input-header__title ${theme === 'light' && 'input-header__title_light'}`}>
          {title} {required && <span>&#42;</span>}
        </div>
      )}
      <label htmlFor={name} className={`input-label  ${theme === 'light' && 'input-label_light'}`}>
        {label}
      </label>
    </div>
  );

  const inputContent = (
    <Field name={name} className={`input file ${error && 'input-error'}`}>
      {() => (
        <>
          <input
            type="file"
            id={name}
            onChange={(e) => {
              handleSetFile(e.target.files[0]);
            }}
          />
          <label htmlFor={name}>{placeholder}</label>
        </>
      )}
    </Field>
  );

  return type === 'text' ? (
    <FileUploadTextContainer>
      {label && labelContent}
      {inputContent}
      {fileName && <div className="input__file-name">{fileName}</div>}
      {error && (
        <div className="input-error-content">
          <BiErrorCircle style={{ color: `${colorsTheme.default.colors.support.error['70']}` }} />
          <div>{error}</div>
        </div>
      )}
    </FileUploadTextContainer>
  ) : (
    <FileUploadContainer>
      {label && labelContent}
      {inputContent}
      {fileName && <div className="input__file-name">{fileName}</div>}
      {error && (
        <div className="input-error-content">
          <BiErrorCircle style={{ color: `${colorsTheme.default.colors.support.error['70']}` }} />
          <div>{error}</div>
        </div>
      )}
    </FileUploadContainer>
  );
};

FileUpload.propTypes = {
  name: PropTypes.string.isRequired,
  error: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.string]),
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.oneOf(['filed', 'text']),
  title: PropTypes.string,
  required: PropTypes.bool,
  theme: PropTypes.oneOf(['light', 'dark']),
};

FileUpload.defaultProps = {
  error: null,
  label: '',
  placeholder: '',
  type: 'field',
  title: '',
  required: false,
  theme: 'dark',
};

export default FileUpload;
