export const briefFormQuestionsData = {
  businessIndustry: {
    alias: 'industry',
    type: 'select',
    isOptional: false,
  },
  stage: {
    alias: 'development_stage',
    type: 'select',
    isOptional: false,
  },
  consultation: {
    alias: 'consultation',
    type: 'checkBox',
    isOptional: true,
  },
  softwareSolution: {
    alias: 'software_solutions',
    type: 'checkBox',
    isOptional: false,
  },
  techStack: {
    alias: 'technologies',
    type: 'checkBoxAdvanced',
    isOptional: true,
  },
  specialists: {
    alias: 'specialists',
    type: 'counter',
    isOptional: false,
  },
  duration: {
    alias: 'duration',
    type: 'duration',
    isOptional: true,
  },
};
