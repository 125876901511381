export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '/api-v1/';

export const CACHE_TAGS = {
  companies: 'COMPANIES',
  testimonials: 'TESTIMONIALS',
  feedbacks: 'FEEDBACKS',
  caseStudies: 'CASE_STUDIES',
  caseStudy: 'CASE_STUDY',
  joinAcademy: 'JOIN_ACADEMY',
  industries: 'INDUSTRIES',
  brief: 'BRIEF',
  caseStudyOne: 'CASE_STUDY_ONE',
  job: 'JOB',
  vacancy: 'VACANCY',
};
