import React from 'react';
import PropTypes from 'prop-types';
import { AccordionContainer } from './Accordion.styled';
import { ReactComponent as Arrow } from '../../global/images/common/arrow-bottom.svg';

const Accordion = ({ title, content, id, handleChangeAccordion, state }) => (
  <AccordionContainer>
    <div
      onClick={() => handleChangeAccordion(id)}
      className={`accordion-header ${state[id] && 'accordion-header_collapsed'}`}
    >
      <div className="accordion-header__title">{title}</div>
      <div className={`accordion-header__icon ${state[id] && 'accordion-header__icon_collapsed'}`}>
        <Arrow />
      </div>
    </div>
    <div className="accordion-body-container">
      <div
        className={`accordion-body-container__body ${
          state[id] && 'accordion-body-container__body_collapsed'
        }`}
      >
        {content}
      </div>
    </div>
  </AccordionContainer>
);

Accordion.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  id: PropTypes.string.isRequired,
  handleChangeAccordion: PropTypes.func.isRequired,
  state: PropTypes.shape({
    [PropTypes.number]: PropTypes.bool,
  }).isRequired,
};

export default Accordion;
