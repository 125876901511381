import { apiService } from './api';
import { isMockTestimonialsServices } from './isMockEnabled';
import testimonialsList from '../../__mocks__/testimonials/getTestimonialsList.mock.json';
import { CACHE_TAGS } from './constants';

export const testimonialsService = apiService.injectEndpoints({
  endpoints: (builder) => ({
    getTestimonials: builder.query({
      query: () => ({
        url: 'testimonials',
        method: 'GET',
        isMockEnabled: isMockTestimonialsServices,
        mockData: testimonialsList,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: CACHE_TAGS.testimonials, id })),
              { type: CACHE_TAGS.testimonials, id: 'LIST' },
            ]
          : [{ type: CACHE_TAGS.testimonials, id: 'LIST' }],
    }),
  }),
});

export const { useGetTestimonialsQuery } = testimonialsService;
