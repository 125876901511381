import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ApplyForJobContainer, CareerFormContainer } from './ApplyForJob.styled';
import BackgroundLinearText from '../../../../components/BackgroundLinearText';
import useNotifications from '../../../../global/hooks/useNotifications';
import CareerForm from '../../../../components/CareerForm';
import { formattedVacancyForm } from '../../../../global/helpers';
import ThankYouForApplying from '../ThankYouForApplying';
import { useSendVacancyFeedbackMutation } from '../../../../global/service/vacancyService';
import Loader from '../../../../components/Loader';

const ApplyForJob = () => {
  const { t } = useTranslation();

  const [sendVacancyFeedback, state] = useSendVacancyFeedbackMutation();
  const [isThanksPageShowed, setIsThanksPageShowed] = useState(false);
  const { addNotification } = useNotifications();

  const handleSubmitForm = async (values, formikHelpers) => {
    const onError = () =>
      addNotification({
        type: 'error',
        title: t('common.notifications.error.title'),
        subtitle: t('common.notifications.error.subtitle'),
      });
    const onSuccess = () => {
      window.scrollTo(0, 0);
      setIsThanksPageShowed(true);
      formikHelpers.resetForm();
    };
    sendVacancyFeedback({ data: await formattedVacancyForm(values), onSuccess, onError });
  };

  if (isThanksPageShowed) {
    return <ThankYouForApplying />;
  }

  return (
    <>
      <ApplyForJobContainer>
        <div className="background-dark-title">
          <BackgroundLinearText type="dark">{t('jobs_page.title')}</BackgroundLinearText>
        </div>

        <div className="content responsive-width">
          <h2 className="title content__title">{t('jobs_page.title')}</h2>
          <div className="content__subtitle">{t('jobs_page.subtitle')}</div>
          <div className="content__description">{t('jobs_page.description')}</div>
          <div className="content__blur" />
          <div className="content__blur-right" />
        </div>
        <CareerFormContainer className="content">
          <CareerForm handleSubmitForm={handleSubmitForm} theme="dark" />
        </CareerFormContainer>
      </ApplyForJobContainer>
      {state.isLoading && <Loader />}
    </>
  );
};

export default ApplyForJob;
