import React from 'react';
import { MainPageContainer } from './MainPage.styled';
import FaqBlock from './components/FAQBlock';
import ServicesBlock from './components/ServicesBlock';
import FactsAndNumbers from './components/FactsAndNumbers';
import Clients from './components/Clients';
import CaseStudiesMainPage from './components/CaseStudiesMainPage';
import PlanetAnimation from './components/PlanetAnimation';
import ContactUsBlock from './components/ContactUsBlock';
import Testimonials from './components/Testimonials/Testimonials';
import ProcessBlock from '../../components/ProcessBlock';
import HelmetHead from '../../components/HelmetHead';

const MainPage = () => (
  <>
    <HelmetHead page="mainPage" />
    <MainPageContainer>
      <PlanetAnimation />
      <ServicesBlock />
      <CaseStudiesMainPage />
      <ProcessBlock />
      <FactsAndNumbers />
      <Testimonials />
      <Clients />
      <FaqBlock />
      <ContactUsBlock />
    </MainPageContainer>
  </>
);

export default MainPage;
