import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';
import { Slider } from '@mui/material';
import { RangeSliderContainer } from './RangeSlider.styled';

const RangeSlider = ({ onChange, fieldName }) => {
  const { t } = useTranslation();

  const labels = {
    1: `1 ${t('brief.rangeSliderLabels.month')}`,
    6: `6 ${t('brief.rangeSliderLabels.months')}`,
    12: `1 ${t('brief.rangeSliderLabels.year')}`,
    18: `1.5 ${t('brief.rangeSliderLabels.years')}`,
    24: `2 ${t('brief.rangeSliderLabels.years')}`,
  };

  const getMarks = () =>
    new Array(25).fill({}).map((_, index) => ({ value: index, label: labels[index] }));

  return (
    <Field name={fieldName}>
      {({ field }) => (
        <RangeSliderContainer>
          <Slider
            aria-valuetext="Always visible"
            min={0}
            max={24}
            value={field.value}
            marks={getMarks()}
            valueLabelDisplay="on"
            valueLabelFormat={(step) => `${step} ${t('brief.rangeSliderLabels.months')}`}
            onChange={(e) => onChange(e.target.value)}
          />
        </RangeSliderContainer>
      )}
    </Field>
  );
};

export default RangeSlider;

RangeSlider.propTypes = {
  onChange: PropTypes.func.isRequired,
  fieldName: PropTypes.string.isRequired,
};
