import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  ContentContainer,
  ObjectivesAndChallengesContainer,
} from './ObjectivesAndChallenges.styled';
import BackgroundLinearText from '../../../../components/BackgroundLinearText';
import { useGetOneCaseStudyQuery } from '../../../../global/service/caseStudiesService';
import { getImagesByType } from '../../helpers';
import useDesktopSize from '../../../../global/hooks/useDesktopSize';
import MarkdownParser from '../../../../components/MarkdownParser';

const ObjectivesAndChallenges = () => {
  const { t } = useTranslation();
  const { caseName } = useParams();
  const { data } = useGetOneCaseStudyQuery(caseName);
  const { isDesktop } = useDesktopSize();
  const objectivesAndChallengesImage = getImagesByType(data, 'objectives&challenges')[0];

  return (
    <ObjectivesAndChallengesContainer>
      <div className="background-dark-title">
        <BackgroundLinearText type="dark">
          {t('objectivesAndChallenges.backgroundText')}
        </BackgroundLinearText>
      </div>
      <ContentContainer className="light-container-responsive">
        <div className="title title_light">{t('objectivesAndChallenges.title')}</div>
        <div className="content">
          {isDesktop && (
            <div className="content__left">
              <img src={objectivesAndChallengesImage.url} alt="objectives and challenges" />
            </div>
          )}
          <div className="content__right">
            <div className="content__objectives">
              <div className="content__objectives-title">
                {t('objectivesAndChallenges.objectives')}
              </div>
              <div className="content__objectives-description">
                <MarkdownParser>{data.objectives_description.value}</MarkdownParser>
              </div>
            </div>
            {!isDesktop && (
              <img src={objectivesAndChallengesImage.url} alt="objectives and challenges" />
            )}
            <div className="content__challenges">
              <div className="content__challenges-title">
                {t('objectivesAndChallenges.challenges')}
              </div>
              <div className="content__challenges-description">
                <MarkdownParser>{data.challenges_description.value}</MarkdownParser>
              </div>
            </div>
          </div>
        </div>
      </ContentContainer>
    </ObjectivesAndChallengesContainer>
  );
};

export default ObjectivesAndChallenges;
