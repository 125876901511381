import styled from 'styled-components';
import theme from '../../global/styles/theme';
import { mobileWidth } from '../../global/styles/Global.styled';

export const text = `
     font-size: 19px;
     font-style: normal;
     font-weight: 600;
     line-height: 150%; 
     letter-spacing: 0.38px;
     color: ${theme.colors.text['90']};
`;

export const ErrorPageContainer = styled.div`
  height: 758px;
  background-color: ${theme.colors.background['10']};
  padding-top: 150px;

  @media only screen and (max-width: ${mobileWidth}) {
    padding-top: 50px;
  }

  .content {
    padding-top: 91px;
    display: flex;
    margin: 0 auto;

    @media only screen and (max-width: ${mobileWidth}) {
      padding-left: 30px;
    }

    &__img {
      width: 583px;
      height: 440px;
      position: absolute;
      right: 0;
      @media only screen and (max-width: 1200px) {
        width: 348px;
        height: 240px;
      }

      @media only screen and (max-width: ${mobileWidth}) {
        display: none;
      }
    }

    &__title {
      font-family: Manrope, sans-serif;
      font-size: 64px;
      font-style: normal;
      font-weight: 800;
      line-height: 132%;
      letter-spacing: 1.28px;
      color: ${theme.colors.text['90']};
    }

    &__subtitle {
      font-family: Manrope, sans-serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 800;
      line-height: 132%;
      letter-spacing: 0.96px;
      color: ${theme.colors.text['90']};
      margin-top: 51px;
    }

    &__error-code {
      ${text};
      margin-top: 24px;
    }

    &__links-title {
      ${text};

      margin-top: 32px;
      margin-bottom: 8px;
    }

    &__links-container {
      & > li {
        margin-bottom: 8px;
      }

      & > li > a {
        color: ${theme.colors.primary['60']};
        cursor: pointer;
      }
    }
  }
`;

export const ErrorPageContentContainer = styled.div`;`;
