import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { SolutionsContainer, SolutionsContentContainer } from './Solutions.styled';
import BackgroundLinearText from '../../../../components/BackgroundLinearText';
import { useGetOneCaseStudyQuery } from '../../../../global/service/caseStudiesService';
import { getImagesByType } from '../../helpers';
import useDesktopSize from '../../../../global/hooks/useDesktopSize';
import MarkdownParser from '../../../../components/MarkdownParser';

const Solutions = () => {
  const { caseName } = useParams();
  const { data } = useGetOneCaseStudyQuery(caseName);
  const { t } = useTranslation();
  const { isDesktop } = useDesktopSize();

  const solutionsImage = getImagesByType(data, 'solutions')[0];

  return (
    <SolutionsContainer>
      <div className="background-dark-title-outlined">
        <BackgroundLinearText type="dark">{t('solutionsBlock.title')}</BackgroundLinearText>
      </div>
      <SolutionsContentContainer className="light-container-responsive transparent-outlined-block">
        {isDesktop && (
          <div className="content__left-block">
            <img src={solutionsImage.url} alt="solutions" />
          </div>
        )}
        <div className="content__right-block">
          <div className="content__title">
            <h2 className="title title_light">{t('solutionsBlock.title')}</h2>
          </div>
          <div className="content__descriptions">
            <div className="content__descriptions-item">{data.solutions_description.value}</div>
          </div>
          {!isDesktop && (
            <div className="content__left-block">
              <img src={solutionsImage.url} alt="solutions" />
            </div>
          )}
          <div className="content__features">
            <div className="content__features-title">{t('solutionsBlock.featuresTitle')}</div>
            <div className="content__features-item">
              <MarkdownParser>{data.solutions_features.value}</MarkdownParser>
            </div>
          </div>
        </div>
      </SolutionsContentContainer>
    </SolutionsContainer>
  );
};

export default Solutions;
