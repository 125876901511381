import styled from 'styled-components';
import theme from './theme';

export const mobileWidth = '904px';

export const AppContainer = styled('div')`
  white-space: pre-line;

  .btn {
    border: none;
    margin: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    font: inherit;
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    -webkit-appearance: none;
    cursor: pointer;

    &::-moz-focus-inner {
      border: 0;
      padding: 0;
    }
    line-height: 150%;
    border-radius: 4px;
    padding: 13px 16px;

    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.51px;

    &_primary_contained {
      background: ${theme.colors.primary['30']};
      color: ${theme.colors.primary['100']};

      &:active, &:hover {
        background: ${theme.colors.primary['20']};
      }
    }

    &_primary_outlined {
      border: 1px solid ${theme.colors.stroke['70']};
      color: ${theme.colors.text['90']};

      &:active, &:hover {
        border: 1px solid ${theme.colors.stroke['95']};
        color: ${theme.colors.stroke['95']};
      }
    }

    &_text_light {
      font-size: 18px;
      color: ${theme.colors.text['95']};
    }

    &_text_dark {
      font-size: 18px;
      color: ${theme.colors.text['20']};
    }

    &-image {
      display: flex;
      align-items: center;

      font-size: 19px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      letter-spacing: 0.38px;

      div {
        margin-right: 20px;
      }

      svg {
        fill: ${theme.colors.stroke['95']};
      }
    }

    &-image__contained {
      background-color: ${theme.colors.primary['30']};
      border-radius: 50%;
      min-width: 34px;
      min-height: 34px;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        transform: scale(1.1);
      }
    }

    &-image__outlined {
      border: 1px solid ${theme.colors.stroke['95']};
      border-radius: 50%;
      min-width: 34px;
      min-height: 34px;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        border: none;
        background-color: ${theme.colors.primary['30']};
        transform: scale(1.1);
      }

      &:active {
        border: none;
        background-color: ${theme.colors.primary['30']};
      }
    }
  }

  .light-container-responsive {
    @media only screen and (min-width: 1920px) {
      max-width: 1700px;
    }
    @media only screen and (min-width: 1440px) and (max-width: 1919px) {
      max-width: 1332px;
    }
    @media only screen and (min-width: 1240px) and (max-width: 1439px) {
      max-width: 1132px;
    }
    @media only screen and (min-width: 905px) and (max-width: 1239px) {
      max-width: 908px;
    }
    @media only screen and (max-width: ${mobileWidth}) {
      max-width: 375px;
    }
  }

  .background-dark-title {
    position: absolute;
    width: 100%;
    min-height: 250px;
    overflow: hidden;
    white-space: nowrap;
    @media only screen and (min-width: 1920px) {
      padding-left: calc((100vw - 1320px) / 2 - 207px);
    }
    @media only screen and (min-width: 1440px) and (max-width: 1919px) {
      padding-left: calc((100vw - 1230px) / 2 - 107px);
    }
    @media only screen and (min-width: 1240px) and (max-width: 1439px) {
      padding-left: calc((100vw - 1024px) / 2 - 107px);
    }
    @media only screen and (min-width: 905px) and (max-width: 1239px) {
      padding-left: calc((100vw - 800px) / 2 - 107px);
    }
    @media only screen and (max-width: ${mobileWidth}) {
      padding-left: calc((100vw - 343px) / 2 - 107px);
    }

    &-outlined {
      position: absolute;
      width: 100%;
      min-height: 250px;
      overflow: hidden;
      white-space: nowrap;

      @media only screen and (min-width: 1920px) {
        padding-left: calc((100vw - 1700px) / 2);
      }
      @media only screen and (min-width: 1440px) and (max-width: 1919px) {
        padding-left: calc((100vw - 1332px) / 2);
      }
      @media only screen and (min-width: 1240px) and (max-width: 1439px) {
        padding-left: calc((100vw - 1132px) / 2);
      }
      @media only screen and (min-width: 905px) and (max-width: 1239px) {
        padding-left: calc((100vw - 907px) / 2);
      }
      @media only screen and (max-width: ${mobileWidth}) {
        padding-left: calc((100vw - 375px) / 2);
      }
    }
  }

  .responsive-width {
    @media only screen and (min-width: 1920px) {
      max-width: 1320px;
    }
    @media only screen and (min-width: 1440px) and (max-width: 1919px) {
      max-width: 1230px;
    }
    @media only screen and (min-width: 1240px) and (max-width: 1439px) {
      max-width: 1024px;
    }
    @media only screen and (min-width: 905px) and (max-width: 1239px) {
      max-width: 800px;
    }
    @media only screen and (max-width: 905px) {
      max-width: min(343px, 90%);
    }
  }

}

.title {
  font-family: Manrope, sans-serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 800;
  line-height: 132%;
  letter-spacing: 1px;
  word-break: break-word;
  z-index: 20;

  &_light {
    color: ${theme.colors.text['90']};
  }

  &_dark {
    color: ${theme.colors.text['20']};
  }
}

.subtitle {
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.38px;
  padding-top: 12px;
  max-width: 593px;
  z-index: 20;

  &_light {
    color: ${theme.colors.text['90']};
  }

  &_dark {
    color: ${theme.colors.text['40']};
  }
}

.input {
  outline: none !important;
  border: 0.5px solid ${theme.colors.stroke['70']};
  border-radius: 4px;
  color: ${theme.colors.text['90']};
  padding: 14px;
  background-color: transparent;
  height: 52px;
  width: 100%;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.02em;

  &:active,
  &:focus {
    border-color: ${theme.colors.stroke['95']};
    color: ${theme.colors.text['90']};
  }

  &::placeholder {
    line-height: 150%;
    color: ${theme.colors.text['60']};

    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.51px;
  }

  &_light {
    color: ${theme.colors.text['30']};

    &:active,
    &:focus {
      border-color: ${theme.colors.primary['40']};
      color: ${theme.colors.text['30']};
    }
  }

  &-label {
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.38px;
    color: ${theme.colors.text['90']};
    padding: 0 0 16px 0;

    &-required {
      color: ${theme.colors.support.error['70']};
    }

    &_light {
      color: ${theme.colors.text['20']};
    }
  }

  &-error {
    border: 0.5px solid ${theme.colors.support.error['70']};
    color: ${theme.colors.support.error['70']};
    transition: border-color 0.3s linear;

    &-content {
      display: flex;
      gap: 8px;
      align-items: center;
      font-weight: 400;
      font-size: 15px;
      line-height: 23px;
      padding: 4px 0 0 0;
      letter-spacing: 0.02em;
      color: ${theme.colors.text['70']};
    }
  }
}

.transparent-outlined-block {
  border: 1px solid ${theme.colors.stroke['50']};
  border-radius: 12px;
}

.modal {
  &__title {
    text-align: center;
    font-weight: 700;
    font-size: 21px;
    line-height: 140%;
    color: ${theme.colors.text['90']};
    font-style: normal;
    letter-spacing: 0.42px;
  }

  &__description {
    text-align: center;
    margin: 20px 0 40px;
    line-height: 150%;
    color: ${theme.colors.text['80']};
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.51px;
  }
}

.video {
  &__container {
    width: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    video {
      object-fit: cover;
      height: 100%;
      width: 100%;

      @media only screen and (max-width: ${mobileWidth}) {
        height: 707px;
      }
    }
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #001813 0%, rgba(0, 19, 24, 0.00) 100%),
    linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%);
  }

}
`;
