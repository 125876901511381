import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import Counter from '../../../../../../components/Counter';
import QuestionTemplate from './QuestionTemplate';
import Loader from '../../../../../../components/Loader';

const QuestionCounter = ({ question, isOptional, options, formikFieldName }) => {
  const { values, setFieldValue } = useFormikContext();

  const countHandleIncrease = (option) => {
    const prevValue = values[formikFieldName];
    prevValue[option] = prevValue[option] !== undefined ? prevValue[option] + 1 : 1;
    setFieldValue(formikFieldName, prevValue);
  };

  const countHandleDecrease = (option) => {
    const prevValue = values[formikFieldName];
    prevValue[option] =
      prevValue[option] !== undefined && prevValue[option] > 0 ? prevValue[option] - 1 : 0;
    setFieldValue(formikFieldName, prevValue);
  };

  const optionsArePresent = options.length > 0;

  return (
    <QuestionTemplate question={question} isOptional={isOptional} name={formikFieldName}>
      {optionsArePresent ? (
        <div className="checkbox-container">
          {options.map((option) => (
            <div className="checkbox-container__option" key={option.name}>
              <div className="checkbox-container__option-name">{option.name}</div>
              <Counter
                onIncrease={() => {
                  countHandleIncrease(option.id);
                }}
                onDecrease={() => {
                  countHandleDecrease(option.id);
                }}
                id={option.id}
                fieldName="specialists"
              />
            </div>
          ))}
        </div>
      ) : (
        <Loader />
      )}
    </QuestionTemplate>
  );
};

export default QuestionCounter;

QuestionCounter.propTypes = {
  question: PropTypes.string.isRequired,
  isOptional: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string.isRequired,
    }),
  ),
  formikFieldName: PropTypes.string.isRequired,
};

QuestionCounter.defaultProps = {
  options: [],
};
