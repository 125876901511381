import styled from 'styled-components';
import theme from '../../../../global/styles/theme';
import { mobileWidth } from '../../../../global/styles/Global.styled';

export const ResultsContainer = styled('div')`
  padding-bottom: 95px;

  .content {
    grid-column: 1/19;

    @media only screen and (max-width: ${mobileWidth}) {
      grid-column: 1/5;
    }

    &__header-container {
      padding: 110px 0 57px;
      display: flex;
      flex-direction: column;
      gap: 16px;

      .title {
      }

      .subtitle {
        max-width: 960px;
      }
    }

    .swiper-wrapper {
      height: max-content !important;
    }

    &__result-item {
      width: 100%;
      height: auto;
      max-height: 500px;
    }

    .swiper-slide,
    .swiper-slide-active {
      display: flex;
      justify-content: center;
    }

    .sliders-container {
      display: flex;
      justify-content: center;
      gap: 12px;
      padding-top: 25px;

      &__button {
        width: 38px;
        height: 6px;
        background-color: ${theme.colors.stroke['50']};
        cursor: pointer;
        transition: 0.5s;
        border-radius: 1px;

        &_active {
          width: 73px;
          background-color: ${theme.colors.primary['40']};
        }

        &:hover {
          background-color: ${theme.colors.primary['40']};
        }
      }
    }
  }
`;
