import styled from 'styled-components';
import { mobileWidth } from '../../../../global/styles/Global.styled';
import theme from '../../../../global/styles/theme';

export const OfferContainer = styled('div')`
  padding-top: 128px;
  position: relative;
  z-index: 20;

  @media only screen and (max-width: ${mobileWidth}) {
    padding-top: 67px;
  }

  ul {
    list-style-type: inherit;
  }

  .responsive-width {
    margin: 0 auto;
    padding-bottom: 77px;
  }

  .offer {
    grid-column: 1/19;
    border-radius: 12px;
    padding: 56px;

    background-color: ${theme.colors.background['99']};

    @media only screen and (max-width: ${mobileWidth}) {
      grid-column: 1/5;
      padding: 24px 16px;
    }

    &__header {
      display: flex;
      flex-direction: column;
      gap: 44px;

      @media only screen and (max-width: ${mobileWidth}) {
        gap: 24px;
      }
    }

    &__name-position {
      font-family: Manrope, sans-serif;
      font-size: 34px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
      letter-spacing: 0.68px;

      color: ${theme.colors.text['20']};

      @media only screen and (max-width: ${mobileWidth}) {
        font-size: 28px;
        font-weight: 700;
        letter-spacing: 0.56px;
      }
    }

    &__main-infos {
      display: flex;
      flex-flow: wrap;
      gap: 40px;
    }

    &__block {
      display: flex;
      flex-direction: column;
      gap: 12px;

      &-title {
        font-size: 21px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
        letter-spacing: 0.42px;
        color: ${theme.colors.text['20']};
      }

      &-description {
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        letter-spacing: 0.36px;
        color: ${theme.colors.text['30']};

        &_accent {
          color: ${theme.colors.primary['30']};
        }
      }
    }

    &__line {
      margin: 40px 0;
      width: 100%;
      height: 1px;
      background-color: ${theme.colors.stroke['90']};

      @media only screen and (max-width: ${mobileWidth}) {
        margin: 24px 0;
      }
    }

    &__infos-block {
      display: flex;
      flex-direction: column;
      gap: 34px;
    }

    &__info-block {
      display: flex;
      flex-direction: column;
      gap: 24px;

      &-title {
        font-size: 21px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
        letter-spacing: 0.42px;
        color: ${theme.colors.text['20']};
      }

      &-list {
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding-left: 20px;
      }

      li {
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        letter-spacing: 0.36px;
        color: ${theme.colors.text['30']};
      }
    }

    &__form {
      margin-top: 56px;
      padding: 32px;
      display: flex;
      flex-direction: column;
      gap: 32px;
      border-radius: 12px;
      border: 1px solid ${theme.colors.stroke['90']};

      &-title {
        font-size: 21px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
        letter-spacing: 0.42px;
        color: ${theme.colors.text['20']};
      }
    }
  }
`;
