export const isMockCompaniesServices = false;
export const isMockTestimonialsServices = false;
export const isMockFactsAndNumbersServices = true;
export const isMockFeedBackService = false;
export const isMockCaseStudiesService = false;
export const isMockTechnologiesServices = false;
export const isMockJoinAcademyService = true;
export const isMockBriefService = false;
export const isMockJobsService = false;
export const isMockVacancyService = false;
export const isMockIndustryService = false;
