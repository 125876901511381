import i18next from '../../../../global/translations/i18next';

export const getFormattedInfo = (data) => {
  const { responsibilities, requirements } = data;

  return {
    responsibilities: responsibilities.value,
    requirements: requirements.value,
    offer: data.offer_description.value,
  };
};

export const infoOfferTitleStrategy = {
  responsibilities: i18next.t('offer.responsibilities'),
  requirements: i18next.t('offer.requirements'),
  offer: i18next.t('offer.offer'),
  job_level: i18next.t('offer.jobLevel'),
  employment_type: i18next.t('offer.employmentType'),
  work_type: i18next.t('offer.workType'),
  date_posted: i18next.t('offer.postedDate'),
};
