import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ContactContainer, MenuContainer, NavbarContainer, Navbar } from './Header.styled';
import { routes } from './routes';
import MobileMenu from './MobileMenu';
import logo from '../../global/images/roxers-logo.svg';
import SwitchLanguage from './components/SwitchLanguage';

const DISTANCE = 100;
const BLACK = 'black';
const TRANSPARENT = 'transparent';

const Header = () => {
  const { t } = useTranslation();

  const [openMenu, setOpenMenu] = useState(false);
  const [navbarColor, setNavbarColor] = useState(TRANSPARENT);

  useEffect(() => {
    if (openMenu) {
      document.body.style.overflow = 'hidden';
      setNavbarColor(BLACK);
    } else {
      document.body.style.overflow = 'inherit';
      if (window.scrollY < DISTANCE) {
        setNavbarColor(TRANSPARENT);
      }
    }
  }, [openMenu]);

  function handleClick() {
    setOpenMenu((prev) => !prev);
  }

  const listenScrollEvent = () => {
    if (window.scrollY > DISTANCE) {
      setNavbarColor(BLACK);
    }
    if (window.scrollY < DISTANCE) {
      setNavbarColor(TRANSPARENT);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent);

    return () => {
      window.removeEventListener('scroll', listenScrollEvent);
    };
  }, []);

  const scroll = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <>
      <NavbarContainer
        className={openMenu && 'mobile-menu'}
        style={{ backgroundColor: navbarColor }}
      >
        <Navbar className="responsive-width">
          <Link to="/" onClick={scroll}>
            <img alt="logo" src={logo} />
          </Link>
          <MenuContainer>
            {routes.map((route) => (
              <li key={route.path}>
                <NavLink to={route.path} className={({ isActive }) => ` ${isActive && 'active'}`}>
                  {t(route.text)}
                </NavLink>
              </li>
            ))}
          </MenuContainer>
          <ContactContainer>
            <Link to="/contact-us">
              <button type="button" className="header__contact__btn">
                {t('header.button.contact_us')}
              </button>
            </Link>
            <SwitchLanguage />
          </ContactContainer>
          <div className="header__menu_toggle" onClick={handleClick}>
            <input type="checkbox" checked={openMenu} readOnly />
            <span />
            <span />
            <span />
          </div>
        </Navbar>
      </NavbarContainer>
      {openMenu && <MobileMenu closeMenu={() => setOpenMenu(false)} />}
    </>
  );
};

export default Header;
