import React from 'react';
import i18next from 'i18next';
import { useNavigate } from 'react-router-dom';

export const getQuestions = () => {
  const navigate = useNavigate();

  return [
    {
      title: i18next.t('faqBlock.questions.started'),
      content: (
        <span>
          {i18next.t('faqBlock.answers.starting')},{' '}
          <span className="faq-block__navigation" onClick={() => navigate('/brief')}>
            {i18next.t('faqBlock.answers.fill')}
          </span>
          ,{i18next.t('faqBlock.answers.information')}{' '}
        </span>
      ),
      id: i18next.t('faqBlock.questions.started'),
    },
    {
      title: i18next.t('faqBlock.questions.proposal'),
      content: i18next.t('faqBlock.answers.proposal'),
      id: i18next.t('faqBlock.questions.proposal'),
    },
    {
      title: i18next.t('faqBlock.questions.industries'),
      content: i18next.t('faqBlock.answers.industries'),
      id: i18next.t('faqBlock.questions.industries'),
    },
    {
      title: i18next.t('faqBlock.questions.projects'),
      content: i18next.t('faqBlock.answers.projects'),
      id: i18next.t('faqBlock.questions.projects'),
    },
    {
      title: i18next.t('faqBlock.questions.timeline'),
      content: i18next.t('faqBlock.answers.timeline'),
      id: i18next.t('faqBlock.questions.timeline'),
    },
    {
      title: i18next.t('faqBlock.questions.process'),
      content: i18next.t('faqBlock.answers.process'),
      id: i18next.t('faqBlock.questions.process'),
    },
    {
      title: i18next.t('faqBlock.questions.quality'),
      content: i18next.t('faqBlock.answers.quality'),
      id: i18next.t('faqBlock.questions.quality'),
    },
    {
      title: i18next.t('faqBlock.questions.development'),
      content: i18next.t('faqBlock.answers.development'),
      id: i18next.t('faqBlock.questions.development'),
    },
    {
      title: i18next.t('faqBlock.questions.team'),
      content: i18next.t('faqBlock.answers.team'),
      id: i18next.t('faqBlock.questions.team'),
    },
    {
      title: i18next.t('faqBlock.questions.support'),
      content: i18next.t('faqBlock.answers.support'),
      id: i18next.t('faqBlock.questions.support'),
    },
  ];
};
