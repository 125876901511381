export const routes = [
  {
    path: '/services',
    text: 'header.routes.services',
  },
  {
    path: '/case-studies',
    text: 'header.routes.case-studies',
  },
  {
    path: '/about',
    text: 'header.routes.about',
  },
  {
    path: '/brief',
    text: 'header.routes.brief',
  },
  {
    path: '/career',
    text: 'header.routes.career',
  },
];
