import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { LoaderContainer } from './Loader.styled';
import logo from '../../global/images/common/logo-loading.svg';
import useDesktopSize from '../../global/hooks/useDesktopSize';

const Loader = () => {
  const { isDesktop } = useDesktopSize();
  return (
    <LoaderContainer>
      <CircularProgress size={isDesktop ? 180 : 100} thickness={1.2} />
      <img src={logo} alt="loading" width={isDesktop ? 50 : 30} />
    </LoaderContainer>
  );
};

export default Loader;
