import styled from 'styled-components';
import { mobileWidth } from '../../global/styles/Global.styled';

export const ProcessBlockContainer = styled('div')`
  padding-bottom: 150px;

  @media only screen and (max-width: ${mobileWidth}) {
    padding-bottom: 100px;
  }

  .content {
    grid-column: 1/19;
    margin-top: 81px;

    @media only screen and (max-width: ${mobileWidth}) {
      grid-column: 1/5;
      margin: 40px 0 0;
    }

    &__header-container {
      margin-bottom: 120px;

      @media only screen and (max-width: ${mobileWidth}) {
        margin-bottom: 40px;
      }

      &-title {
        display: flex;
        justify-content: space-between;
      }

      .btnMobile {
        margin: 15px 0 0 -15px;
      }
    }

    &__main-container {
      display: grid;
      grid-template-columns: 1.5fr 1fr;
      grid-gap: 40px;

      @media only screen and (max-width: 1240px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &:first-child {
          height: 600px;
        }
      }
    }
  }
`;
