import React from 'react';
import { useTranslation } from 'react-i18next';
import { BenefitsContainer, BenefitsContentContainer } from './RoxersBenefits.styled';
import BackgroundLinearText from '../../../../components/BackgroundLinearText';
import { benefitsData } from './constants';
import BenefitsSlider from './components/BenefitsSlider';

const RoxersBenefits = () => {
  const { t } = useTranslation();
  return (
    <BenefitsContainer>
      <div className="background-dark-title-outlined">
        <BackgroundLinearText type="dark">{t('career.benefits.title')}</BackgroundLinearText>
      </div>
      <BenefitsContentContainer className="light-container-responsive transparent-outlined-block">
        <div className="header-container">
          <h2 className="title title_light">{t('career.benefits.title')}</h2>
          <div className="header-container__subtitle subtitle subtitle_light">
            {t('career.benefits.subtitle')}
          </div>
        </div>
        <div className="benefits">
          {benefitsData.map((benefit) => (
            <div className="benefits__container" key={benefit.id}>
              <img src={benefit.image} alt="benefit" className="benefits__image" />
              <div className="benefits__text">{t(`${benefit.benefit}`)}</div>
            </div>
          ))}
        </div>
        <BenefitsSlider benefitsData={benefitsData} />
      </BenefitsContentContainer>
    </BenefitsContainer>
  );
};

export default RoxersBenefits;
