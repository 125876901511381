import { apiService } from './api';
import { CACHE_TAGS } from './constants';
import clientsData from '../../__mocks__/clients/getClientsData.mock.json';
import { isMockCompaniesServices } from './isMockEnabled';

export const companiesService = apiService.injectEndpoints({
  endpoints: (builder) => ({
    getCompanies: builder.query({
      query: () => ({
        url: `companies`,
        method: 'GET',
        isMockEnabled: isMockCompaniesServices,
        mockData: clientsData,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: CACHE_TAGS.companies, id })),
              { type: CACHE_TAGS.companies, id: 'LIST' },
            ]
          : [{ type: CACHE_TAGS.companies, id: 'LIST' }],
    }),
  }),
});

export const { useGetCompaniesQuery } = companiesService;
