import styled from 'styled-components';
import theme from '../../global/styles/theme';

export const ErrorStateContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 0 auto;
  width: 100%;
  align-items: center;
  padding: 30px 16px;
  justify-content: center;

  height: ${(props) => (props.height ? `calc(${props.height} - 410px)` : '100%')};

  .description {
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.32px;
    text-align: center;

    &_light {
      color: ${theme.colors.text['20']};
    }

    &_dark {
      color: ${theme.colors.text['95']};
    }
  }
`;
