import styled from 'styled-components';
import theme from '../../../../global/styles/theme';

import { mobileWidth } from '../../../../global/styles/Global.styled';

export const OurValuesContainer = styled('div')`
  margin: 100px auto;
`;

export const OurValuesContentContainer = styled('div')`
  margin: 0 auto;

  .header-container {
    padding: 166px 0 0 107px;

    @media only screen and (max-width: ${mobileWidth}) {
      padding: 40px 16px;
    }
  }
`;

export const OurValuesContent = styled('div')`
  padding: 100px 112px 132px 61px;
  display: grid;
  grid-template-columns: 0.55fr 0.45fr;
  grid-gap: 98px;

  @media only screen and (max-width: 1240px) {
    padding: 100px 20px 132px;
  }

  @media only screen and (max-width: ${mobileWidth}) {
    padding: 0 16px 40px;
    display: flex;
    flex-direction: column;
  }

  .images-container {
    position: relative;
    display: none;

    @media (min-width: ${mobileWidth}) {
      display: block;
    }

    &__single {
      position: absolute;
      transition: 0.7s;
      opacity: 0;

      &.active {
        opacity: 1;
      }
    }

    img {
      width: 100%;

      @media (min-width: 1919px) {
        max-height: 500px;
        width: auto;
      }
    }
  }

  .values-container {
    display: flex;
    flex-direction: column;
    gap: 100px;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 23px;
      width: 2px;
      height: 89%;
      z-index: 2;
      background-color: ${theme.colors.stroke['50']};

      @media only screen and (max-width: 1240px) {
        height: 85%;
      }
    }

    &__item {
      display: flex;
      gap: 24px;
      max-width: 368px;

      &-icon {
        min-height: 56px;
        min-width: 56px;
      }

      &-content {
        display: flex;
        flex-direction: column;
        gap: 20px;
        color: ${theme.colors.text['95']};
        opacity: 0.5;

        &_active {
          opacity: 1;
          transition: color 0.5s;
        }
      }
    }

    &__icon {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 48px;
      width: 48px;
      border-radius: 50%;
      background: ${theme.colors.stroke['70']};
      z-index: 5;
      position: absolute;

      &_active {
        min-height: 56px;
        min-width: 56px;
        background: ${theme.colors.text['95']};
        z-index: 10;
        margin-left: -4px;
        transition: all 0.5s;
      }
    }

    &__title {
      font-family: Manrope, sans-serif;
      font-size: 34px;
      font-style: normal;
      font-weight: 800;
      line-height: 140%;
      letter-spacing: 0.68px;
      word-break: break-word;
    }

    &__description {
      font-size: 17px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      letter-spacing: 0.51px;

      word-break: break-word;
    }
  }
`;
