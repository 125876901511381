import IvanovAvatar from '../../../../global/images/team/IvanovAvatar.jpg';
import DushkevychAvatar from '../../../../global/images/team/DushkevychAvatar.jpg';

export const teamData = [
  {
    name: 'Danil Ivanov',
    position: 'Chief Information Officer',
    image: IvanovAvatar,
    linkedin: 'https://www.linkedin.com/in/danil-ivanov-501067223/',
    email: 'divanov@roxers.io',
  },
  {
    name: 'Oleksandr Dushkevych',
    position: 'Chief Operating Officer',
    image: DushkevychAvatar,
    linkedin: 'https://www.linkedin.com/in/oleksandrdushkevych/',
    email: 'roxers@roxers.io',
  },
];
