import React from 'react';
import PropTypes from 'prop-types';
import { useSwiper } from 'swiper/react';
import {
  TechnologiesNavigationContainer,
  TechnologiesNavigationContent,
} from './TechnologiesNavigation.styled';

const TechnologiesNavigation = ({ navigationData, currentStep }) => {
  const swiper = useSwiper();

  return (
    <TechnologiesNavigationContainer>
      <TechnologiesNavigationContent>
        {navigationData.map((slide, index) => (
          <div
            key={`slider-button-${String(index)}`}
            className={`sliders-button ${
              currentStep === navigationData.indexOf(slide) && 'sliders-button_active'
            }`}
            onClick={() => swiper.slideTo(index)}
          >
            {slide}
          </div>
        ))}
      </TechnologiesNavigationContent>
    </TechnologiesNavigationContainer>
  );
};

TechnologiesNavigation.propTypes = {
  navigationData: PropTypes.arrayOf(PropTypes.string).isRequired,
  currentStep: PropTypes.number.isRequired,
};

export default TechnologiesNavigation;
