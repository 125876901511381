import axios from 'axios';
import { serializeAxiosError } from '../helpers';
import i18next from '../translations/i18next';

export const axiosBaseQuery =
  ({ baseUrl }) =>
  async (request, apiService) => {
    const { url, method, data, params, mockData, isMockEnabled, onSuccess, onError } = request;
    const { dispatch } = apiService;

    try {
      if (isMockEnabled) {
        // temporary test onSuccess
        if (onSuccess) {
          onSuccess({ data: mockData.data, dispatch });
        }
        return { data: mockData.data };
      }

      const headers = {
        'Content-Type': 'application/json; charset=UTF-8',
        'Accept-Language': `${i18next.language}`,
      };

      const result = await axios({
        url: baseUrl + url,
        method,
        data,
        params,
        headers,
        withCredentials: true,
      });

      if (onSuccess) {
        onSuccess({ data: result.data, dispatch });
      }

      return { data: result.data };
    } catch (error) {
      if (error.response.status === 432) {
        window.location.replace('/login');
      } else if (error.response.status === 401) {
        try {
          await axios({
            url: `${process.env.REACT_APP_API_BASE_URL}auth/refresh`,
            method: 'POST',
            body: {},
            withCredentials: true,
          });
        } catch (refreshError) {
          if (refreshError.response.status === 403 || refreshError.response.status === 401) {
            window.location.replace('/login');
          }

          return { error: serializeAxiosError(refreshError) };
        }

        // eslint-disable-next-line
        return await axiosBaseQuery({ baseUrl: process.env.REACT_APP_API_BASE_URL })(
          request,
          apiService,
        );
      }

      if (onError) {
        onError({ error, dispatch });
      }

      return { error: serializeAxiosError(error) };
    }
  };
