export const getFormattedData = (data) => ({
  technologies: {
    title: 'technologiesAndTeam.titleTechnologies',
    description: data.technologies_description.value,
    items: data.technologies.map((el) => ({
      name: el.technology.name,
      icon: el.technology.logo,
      id: el.technology.id,
    })),
  },
  team: {
    title: 'technologiesAndTeam.titleTeam',
    description: data.team_description.value,
    items: data.specialists.map((el) => ({
      name: el.specialist.name,
      icon: el.specialist.logo,
      id: el.specialist.id,
    })),
  },
});
