import styled from 'styled-components';
import theme from '../../../../global/styles/theme';

const mobileWidth = '1270px';

const circle = `
          content: "";
          display: block;
          position: absolute;
          top: -6px;
          width: 15px;
          height: 15px;
          border-radius: 50px;
          background-color: ${theme.colors.primary['40']};
          `;

export const HistoryContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 100px;

  .content {
    margin: 0 auto;
    padding-top: 81px;
    
    @media only screen and (min-width: ${mobileWidth}) {
      display: none;
    }
  }

  .year-container {
    width: 256px;
   

    &__range-container {
      display: flex;
      padding-bottom: 50px;

      @media only screen and (max-width: ${mobileWidth}) {
        padding-top: 71px;
        padding-bottom: 55px;
      }
    }

    &__range-line {
      height: 1px;
      width: 250px;
      background: ${theme.colors.stroke['50']};
      margin-top: 7px;

      &::after {
        content: "";
        display: block;
        width: 15px;
        height: 15px;
        border-radius: 50px;
        background-color: ${theme.colors.primary['40']};
        margin-top: -6px;
      }
    }

    &__history-text {
      padding-top: 71px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.02em;
      color: ${theme.colors.background['80']};

      @media only screen and (max-width: 1270px) {
        padding-bottom: 50px;
      }
    }
    
    &__year {
      color: ${theme.colors.primary['40']};
      font-family: "Lato", sans-serif;
      font-size: 64px;
      font-weight: 700;
      line-height: 140%; 
      letter-spacing: 0.64px;
    }
  }
}
`;

export const HistoryContentContainer = styled.div`
  @keyframes sliding {
    0% {
      left: -500px;
      top: 0;
      opacity: 0;
    }
    100% {
      left: 0;
      top: 0;
      opacity: 1;
    }
  }

  .history {
    &__animated {
      animation-name: sliding;
      animation-duration: 3s;
    }

    &__top {
      margin: 0 auto;
      padding-top: 81px;
      padding-left: 106px;
      position: relative;

      @media only screen and (max-width: ${mobileWidth}) {
        display: none;
      }

      &-year {
        font-family: Manrope, sans-serif;
        font-size: 64px;
        font-style: normal;
        font-weight: 800;
        line-height: 140%;
        letter-spacing: 0.64px;
        color: ${theme.colors.text['95']};
      }

      &-text {
        font-size: 19px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: 0.38px;
        color: ${theme.colors.text['90']};
        max-width: 691px;
        margin-top: 24px;
      }
    }

    &__panel {
      padding-top: 100px;
      display: flex;
      margin: 0 auto;

      &-container {
        display: flex;
        flex-direction: column;
        flex-basis: 100%;

        &:first-of-type .history__range-line::before {
          left: -7.5px;
          ${circle};
        }
      }

      &-year {
        color: ${theme.colors.text['95']};
        text-align: center;
        cursor: pointer;

        font-size: 21px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0.42px;

        &:hover {
          cursor: pointer;
          color: ${theme.colors.primary['40']};
        }
      }

      .active {
        &__year {
          color: ${theme.colors.primary['40']};
        }

        &__line {
          background: ${theme.colors.primary['40']};
        }
      }

      @media only screen and (max-width: ${mobileWidth}) {
        display: none;
      }
    }

    &__range {
      display: flex;
      margin-top: 16px;

      @media only screen and (max-width: ${mobileWidth}) {
        padding-top: 71px;
        padding-bottom: 55px;
      }

      &-line {
        position: relative;
        height: 3px;
        width: 100%;
        background: ${theme.colors.stroke['50']};
        margin-top: 7px;

        &::after {
          z-index: 1;
          right: -7.5px;
          ${circle}
        }
      }
    }
  }
`;

export const SwiperContainer = styled.div`
  @media only screen and (min-width: ${mobileWidth}) {
    display: none;
  }

  .slide {
    display: flex;
    justify-content: center;
  }
`;
