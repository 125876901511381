import styled from 'styled-components';
import theme from '../../global/styles/theme';
import { mobileWidth } from '../../global/styles/Global.styled';

export const RangeSliderContainer = styled.div`
  padding-top: 10px;

  @media only screen and (max-width: ${mobileWidth}) {
    padding-top: 5px;
  }

  .MuiSlider-root {
    color: ${theme.colors.primary['30']};
  }

  .MuiSlider-thumb .MuiSlider-valueLabel {
    color: ${theme.colors.primary['100']};
    background-color: ${theme.colors.primary['30']};
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    padding: 8px 10px;
    border-radius: 3px;
    @media only screen and (max-width: ${mobileWidth}) {
      margin-left: 50px;
      padding: 4px 5px;
      font-size: 12px;
      line-height: 14px;
      font-weight: 500;
    }
  }

  .MuiSlider-mark {
    width: 2px;
    height: 5px;
    color: ${theme.colors.stroke['70']};
  }

  .MuiSlider-rail {
    background-color: ${theme.colors.backgroundDark['95']};
  }

  .MuiSlider-thumb:before {
    width: 7px;
    height: 13px;
    border-radius: 2px;
  }

  .MuiSlider-thumb {
    width: 7px;
    height: 13px;
    border-radius: 2px;
  }

  .MuiSlider-mark {
    color: ${theme.colors.stroke['70']};
    width: 1px;
    height: 5px;
    border-radius: 5px;
  }

  ${[1, 6, 12, 18, 24].map(
    (num) => `
    .MuiSlider-mark[data-index="${num}"] {
      width: 3px;
    }
  `,
  )}

  .MuiSlider-markLabel {
    @media only screen and (max-width: ${mobileWidth}) {
      display: none;
    }
  }
`;
