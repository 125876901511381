import styled from 'styled-components';
import theme from '../../../../global/styles/theme';
import { mobileWidth } from '../../../../global/styles/Global.styled';

export const AboutContainer = styled.div`
  overflow: hidden;
  height: 810px;
  position: relative;
  z-index: 1;

  @media only screen and (min-width: 1445px) {
    height: 850px;
  }

  @media only screen and (max-width: ${mobileWidth}) {
    padding-left: 16px;
    height: 707px;
  }

  .about-container {
    padding-top: 192px;
    margin: 0 auto;
    @media only screen and (max-width: ${mobileWidth}) {
      padding-top: 115px;
    }

    &__title {
      color: ${theme.colors.primary['100']};

      font-family: Manrope, sans-serif;
      font-size: 64px;
      font-style: normal;
      font-weight: 800;
      line-height: 132%;
      letter-spacing: 1.28px;
    }

    &__content {
      max-width: 590px;
      color: ${theme.colors.backgroundDark['80']};
      padding-top: 24px;
      padding-bottom: 48px;

      font-size: 19px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      letter-spacing: 0.38px;

      @media only screen and (max-width: ${mobileWidth}) {
        max-width: 343px;
      }
    }

    &__first-block-btn {
      width: 253px;
    }
  }
`;
