import React from 'react';
import { useTranslation } from 'react-i18next';
import BackgroundLinearText from '../../../../components/BackgroundLinearText';
import { ClientsMainContainer } from './Clients.styled';
import { useGetCompaniesQuery } from '../../../../global/service/companiesService';
import ErrorState from '../../../../components/ErrorState';

const Clients = () => {
  const { t } = useTranslation();

  const { data, isError } = useGetCompaniesQuery();

  return (
    <ClientsMainContainer className="light-container-responsive">
      <div className="clients-container">
        <div className="background-title">
          <BackgroundLinearText type="light">{t('clients.backgroundText')}</BackgroundLinearText>
        </div>
        <div className="clients-container__content">
          <div className="title title_dark">{t('clients.cardTitle')}</div>
          <div className="subtitle subtitle_dark">{t('clients.text')}</div>
          <div>
            {data && !isError && (
              <div className="clients-container__logos-container">
                {data.map((logo) => (
                  <img
                    key={logo.id}
                    src={logo.logo}
                    alt="company logo"
                    className="clients-container__logos-container-logo"
                  />
                ))}
              </div>
            )}
            {isError && <ErrorState type="light" />}
          </div>
        </div>
      </div>
    </ClientsMainContainer>
  );
};

export default Clients;
