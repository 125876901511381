import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import Checkbox from '../../../../../../components/CheckBox';
import QuestionTemplate from './QuestionTemplate';
import Loader from '../../../../../../components/Loader';

const QuestionCheckBox = ({ question, isOptional, options, formikFieldName }) => {
  const { values, setFieldValue } = useFormikContext();

  const checkboxHandleChange = (id) => {
    const currentValues = values[formikFieldName];

    const index = currentValues.indexOf(id);
    if (index === -1) {
      currentValues.push(id);
    } else {
      currentValues.splice(index, 1);
    }
    setFieldValue(formikFieldName, currentValues);
  };

  const optionsArePresent = options.length > 0;

  return (
    <QuestionTemplate question={question} isOptional={isOptional} name={formikFieldName}>
      {optionsArePresent ? (
        <div className="checkbox-container">
          {options.map((option) => (
            <div
              className="checkbox-container__option"
              key={`${option.name.value || option.name}-checkbox`}
            >
              <Checkbox
                checkBoxName={`${formikFieldName}-${option.name.value || option.name}`}
                onChange={() => {
                  checkboxHandleChange(option.id);
                }}
                value={
                  Array.isArray(values[formikFieldName]) &&
                  values[formikFieldName]?.find((id) => id === option.id)
                }
                title={option.name.value || option.name}
                titleTheme="dark"
              />
            </div>
          ))}
        </div>
      ) : (
        <Loader />
      )}
    </QuestionTemplate>
  );
};

export default QuestionCheckBox;

QuestionCheckBox.propTypes = {
  question: PropTypes.string.isRequired,
  isOptional: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({ value: PropTypes.string })]),
    }),
  ),
  formikFieldName: PropTypes.string.isRequired,
};

QuestionCheckBox.defaultProps = {
  options: [],
};
