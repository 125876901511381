import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Element } from 'react-scroll';
import BackgroundLinearText from '../../../../components/BackgroundLinearText';
import { ContactUsContainer } from './ContactUsBlock.styled';
import Layout from '../../../../components/Layout/Layout';
import { useSendFeedbackMutation } from '../../../../global/service/feedBacksService';
import { formattedContactData } from '../../helpers';
import GratitudeModal from '../../../../components/GratitudeModal';
import DataForm from '../../../../components/DataForm';
import useNotifications from '../../../../global/hooks/useNotifications';
import Loader from '../../../../components/Loader';

const ContactUsBlock = () => {
  const { t } = useTranslation();
  const [isContactModalOpen, setContactModalOpen] = useState(false);
  const [isGratitudeModalOpen, setGratitudeModalOpen] = useState(false);
  const { addNotification } = useNotifications();
  const [sendFeedback, state] = useSendFeedbackMutation();

  const handleSubmitForm = async (values, formikHelpers) => {
    if (values.file || values.link) {
      const onError = () => {
        addNotification({
          type: 'error',
          title: t('common.notifications.error.title'),
          subtitle: t('common.notifications.error.subtitle'),
        });
      };
      const onSuccess = () => {
        setContactModalOpen(false);
        setGratitudeModalOpen(true);
        formikHelpers.resetForm();
      };
      sendFeedback({ data: await formattedContactData(values), onSuccess, onError });
    } else {
      addNotification({
        type: 'error',
        title: t('common.notifications.one_is_required.title'),
        subtitle: t('common.notifications.one_is_required.subtitle'),
        noClose: true,
      });
    }
  };

  return (
    <>
      <Element name="contact-us">
        <ContactUsContainer>
          <div className="background-dark-title">
            <BackgroundLinearText type="dark">{t('contactUsBlock.title')}</BackgroundLinearText>
          </div>
          <Layout>
            <div className="content">
              <div className="content__header">
                <h2 className="title title_light">{t('contactUsBlock.title')}</h2>
                <div className="subtitle subtitle_light">{t('contactUsBlock.subtitle')}</div>
              </div>
              <DataForm
                handleSubmitForm={handleSubmitForm}
                isContactModalOpen={isContactModalOpen}
                setContactModalOpen={setContactModalOpen}
              />
            </div>
          </Layout>
          {isGratitudeModalOpen && (
            <GratitudeModal
              onClose={() => setGratitudeModalOpen(false)}
              title={t('contactUsBlock.gratitudeModal.title')}
              description={t('contactUsBlock.gratitudeModal.description')}
            />
          )}
        </ContactUsContainer>
      </Element>
      {state.isLoading && <Loader />}
    </>
  );
};

export default ContactUsBlock;
