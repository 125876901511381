import { apiService } from './api';
import { isMockJobsService } from './isMockEnabled';
import jobPositionData from '../../__mocks__/jobs/getJobPositionDataMock.mock.json';
import openJobsData from '../../__mocks__/jobs/getOpenJobsData.mock.json';

export const jobsService = apiService.injectEndpoints({
  endpoints: (builder) => ({
    getOneJobPosition: builder.query({
      query: (slug) => ({
        url: `vacancies/${slug}`,
        method: 'GET',
        isMockEnabled: isMockJobsService,
        mockData: jobPositionData,
      }),
    }),
    getOpenJobs: builder.query({
      query: () => ({
        url: `vacancy-types`,
        method: 'GET',
        isMockEnabled: isMockJobsService,
        mockData: openJobsData,
      }),
    }),
  }),
});

export const { useGetOneJobPositionQuery, useGetOpenJobsQuery } = jobsService;
