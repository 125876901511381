import styled from 'styled-components';
import theme from '../../../../global/styles/theme';
import { mobileWidth } from '../../../../global/styles/Global.styled';
import caseStudies from '../../../../global/images/backgrounds/caseStudies.png';

export const FirstBlockContainer = styled.div`
  background-image: url(${caseStudies});
  background-size: cover;
  background-position: center;
  overflow: hidden;
  height: 820px;
  width: 100%;

  @media only screen and (max-width: ${mobileWidth}) {
    height: 767px;
  }

  .container {
    padding-top: 111px;
    margin: 0 auto;

    @media only screen and (max-width: ${mobileWidth}) {
      padding-top: 115px;
    }

    &__title {
      color: ${theme.colors.primary['100']};

      width: 610px;

      font-family: Manrope, sans-serif;
      font-size: 64px;
      font-style: normal;
      font-weight: 800;
      line-height: 132%;
      letter-spacing: 1.28px;

      @media only screen and (max-width: ${mobileWidth}) {
        width: 343px;
        font-size: 46px;
        line-height: 150%;
      }
    }

    &__content {
      font-size: 19px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      letter-spacing: 0.38px;
      max-width: 590px;
      color: ${theme.colors.backgroundDark['80']};
      padding-top: 24px;
      padding-bottom: 48px;

      @media only screen and (max-width: ${mobileWidth}) {
        width: 343px;
        padding-top: 60px;
      }
    }

    &__btn {
      width: 253px;
    }
  }
`;
