import Empathise from '../../../../global/images/ourValues/icons/empathise.svg';
import WorkHard from '../../../../global/images/ourValues/icons/work-hard.svg';
import Respect from '../../../../global/images/ourValues/icons/respect.svg';
import Collaborate from '../../../../global/images/ourValues/icons/collaborate.svg';
import Control from '../../../../global/images/ourValues/icons/control.svg';
import SetClearGoals from '../../../../global/images/ourValues/icons/set-clear-goals.svg';

import strive from '../../../../global/images/ourValues/images/exellence.png';
import client from '../../../../global/images/ourValues/images/client.png';
import trust from '../../../../global/images/ourValues/images/trust.png';
import adaptability from '../../../../global/images/ourValues/images/adaptability.png';
import innovation from '../../../../global/images/ourValues/images/innovation.png';
import attention from '../../../../global/images/ourValues/images/attention.png';

export const ourValuesData = [
  {
    id: 1,
    title: 'ourValuesBlock.items.strive',
    description: 'ourValuesBlock.descriptions.strive',
    icon: Empathise,
    image: strive,
    key: 'strive',
  },
  {
    id: 2,
    title: 'ourValuesBlock.items.client-oriented',
    description: 'ourValuesBlock.descriptions.client-oriented',
    icon: WorkHard,
    image: client,
    key: 'client-oriented',
  },
  {
    id: 3,
    title: 'ourValuesBlock.items.deepIntegrity',
    description: 'ourValuesBlock.descriptions.deepIntegrity',
    icon: Respect,
    image: trust,
    key: 'deepIntegrity',
  },
  {
    id: 4,
    title: 'ourValuesBlock.items.adaptability',
    description: 'ourValuesBlock.descriptions.adaptability',
    icon: Collaborate,
    image: adaptability,
    key: 'adaptability',
  },
  {
    id: 5,
    title: 'ourValuesBlock.items.expertiseAndInnovation',
    description: 'ourValuesBlock.descriptions.expertiseAndInnovation',
    icon: Control,
    image: innovation,
    key: 'expertiseAndInnovation',
  },
  {
    id: 6,
    title: 'ourValuesBlock.items.greatAttention',
    description: 'ourValuesBlock.descriptions.greatAttention',
    icon: SetClearGoals,
    image: attention,
    key: 'greatAttention',
  },
];

export const heightStrategy = {
  0: '0',
  1: '350px',
  2: '450px',
  3: '800px',
  4: '1100px',
  5: '1000px',
};
