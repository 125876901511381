import styled from 'styled-components';
import theme from '../../../../../../global/styles/theme';

export const SwiperNavigatorContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 44px;
  margin-left: 20px;

  @media only screen and (max-width: 1240px) {
    flex-direction: row;
    margin: 0 0 44px 0;
    max-width: 340px;
    gap: 35px;
  }

  .wrapper {
    display: flex;
    align-items: flex-end;

    &__current-step {
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      letter-spacing: 0.02em;
      color: ${theme.colors.text['90']};
      margin-bottom: -5px;
      margin-right: 13px;
    }

    &__swiper {
      width: 19px;
      height: 2px;
      background-color: ${theme.colors.stroke['90']};
      cursor: pointer;
      opacity: 0.4;
      padding: 1px 0;
      transition: 0.3s;

      &_active {
        width: 36px;
        opacity: 1;
        padding-left: 13px;
      }

      &:hover {
        opacity: 1;
      }
    }
  }
`;
