import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';
import 'swiper/swiper.min.css';

import { SlideContainer, SwiperContainer } from './HowWeCanHelp.styled';

const Slider = ({ howCanWeHelpData }) => {
  const { t } = useTranslation();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [swipe, setSwiper] = useState(null);

  return (
    <SwiperContainer>
      <Swiper
        onBeforeTransitionStart={(swiper) => {
          setCurrentSlide(swiper.activeIndex);
        }}
        onSwiper={setSwiper}
        autoHeight
      >
        {howCanWeHelpData.map((el) => (
          <SwiperSlide className="slide" key={el.id}>
            <SlideContainer>
              <div className="slide__title">{t(`${el.title}`)}</div>
              <img src={el.imgMobile} alt="product" className="slide__img" />
              {currentSlide < 4 && (
                <button
                  className="slide__right-btn"
                  type="button"
                  onClick={() =>
                    currentSlide < howCanWeHelpData.length && swipe.slideTo(currentSlide + 1)
                  }
                >
                  <IoIosArrowForward />
                </button>
              )}
              {currentSlide <= 4 && currentSlide !== 0 && (
                <button
                  className="slide__left-btn"
                  type="button"
                  onClick={() => currentSlide > 0 && swipe.slideTo(currentSlide - 1)}
                >
                  <IoIosArrowBack />
                </button>
              )}
              <div className="slide__text">{t(`${el.text}`)}</div>
            </SlideContainer>
          </SwiperSlide>
        ))}
      </Swiper>
      {/* <button type="button" className="btn-bottom btn-image btn_text_light"> */}
      {/*  <div className="btn-image__outlined"> */}
      {/*    <BsArrowRight /> */}
      {/*  </div> */}
      {/*  {t('how_can_we_help.explore_button')} */}
      {/* </button> */}
    </SwiperContainer>
  );
};

Slider.propTypes = {
  howCanWeHelpData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      imgDesktop: PropTypes.string.isRequired,
      imgMobile: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default Slider;
