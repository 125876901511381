import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { routes } from './routes';
import { MobileMenuContainer } from './Header.styled';
import SwitchLanguage from './components/SwitchLanguage';

const MobileMenu = ({ closeMenu }) => {
  const { t } = useTranslation();
  return (
    <MobileMenuContainer>
      <div className="responsive-width">
        {routes.map((route) => (
          <li key={route.path}>
            <NavLink
              to={route.path}
              onClick={() => closeMenu()}
              className={({ isActive }) => `${isActive && 'active'}`}
            >
              {t(route.text)}
            </NavLink>
          </li>
        ))}
        <Link to="/contact-us">
          <button type="button" className="header-mobile__btn" onClick={() => closeMenu()}>
            {t('header.button.contact_us')}
          </button>
        </Link>
        <SwitchLanguage />
      </div>
    </MobileMenuContainer>
  );
};

export default MobileMenu;

MobileMenu.propTypes = {
  closeMenu: PropTypes.func.isRequired,
};
