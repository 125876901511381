import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PreviewBlock from './components/PreviewBlock';
import AboutClient from './components/AboutClient';
import { useGetOneCaseStudyQuery } from '../../global/service/caseStudiesService';
import StartCooperation from './components/StartCooperation';
import Results from './components/Results';
import Solutions from './components/Solutions';
import TechnologiesAndTeam from './components/TechnologiesAndTeam';
import ObjectivesAndChallenges from './components/ObjectivesAndChallenges';
import Loader from '../../components/Loader';
import { CaseStudyPageContainer } from './CaseStudy.styled';
import ClientTestimonial from './components/ClientTestimonial';
import GetInTouch from '../../components/GetInTouch/GetInTouch';
import OtherCase from './components/OtherCaseStudies';
import ErrorState from '../../components/ErrorState';
import { useIsFirstRender } from '../../global/hooks/useIsFirtsRender';
import i18next from '../../global/translations/i18next';

const CaseStudy = () => {
  const { caseName } = useParams();
  const { data, isLoading, isError, refetch } = useGetOneCaseStudyQuery(caseName);

  const isFirstRender = useIsFirstRender();
  useEffect(() => {
    if (!isFirstRender) refetch();
  }, [i18next.language]);

  return (
    <CaseStudyPageContainer>
      {data && !isError && (
        <>
          <PreviewBlock />
          <AboutClient />
          {data.testimonial && <ClientTestimonial />}
          <ObjectivesAndChallenges />
          <TechnologiesAndTeam />
          <Solutions />
          <Results />
          <StartCooperation />
          <OtherCase />
          <GetInTouch />
        </>
      )}
      {isLoading && <Loader />}
      {isError && <ErrorState type="dark" height="100vh" />}
    </CaseStudyPageContainer>
  );
};

export default CaseStudy;
