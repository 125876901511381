import { apiService } from './api';
import { isMockBriefService } from './isMockEnabled';
import getBriefContent from '../../__mocks__/brief/getBriefContentData.mock.json';
import { CACHE_TAGS } from './constants';
import sendStatusMock from '../../__mocks__/contact/sendFeedBack.mock.json';

export const briefService = apiService.injectEndpoints({
  endpoints: (builder) => ({
    getBriefContent: builder.query({
      query: () => ({
        url: `brief-content`,
        method: 'GET',
        isMockEnabled: isMockBriefService,
        mockData: getBriefContent,
      }),
    }),
    sendBrief: builder.mutation({
      query: ({ data, onSuccess, onError }) => ({
        url: `proposal-requests`,
        method: 'POST',
        data,
        onSuccess,
        onError,
        isMockEnabled: isMockBriefService,
        mockData: sendStatusMock,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: CACHE_TAGS.brief, id }],
    }),
  }),
});

export const { useSendBriefMutation } = briefService;
export const { useGetBriefContentQuery } = briefService;
