import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './locales/en.json';
import uk from './locales/uk.json';

i18next.use(initReactI18next).init({
  resources: {
    en,
    uk,
  },
  lng: 'en',
  fallbackLng: 'en',
  supportedLngs: ['en', 'uk'],
  interpolation: {
    escapeValue: false,
  },
});

export default i18next;
