export const formattedTechnologiesData = (data) =>
  data?.reduce((accum, item) => {
    if (!accum?.find((i) => i.area === item.type?.name)) {
      return [
        ...accum,
        {
          area: item.type.name,
          technologies: data.filter((e) => e.type.name === item.type.name),
        },
      ];
    }

    return accum;
  }, []);
