import React from 'react';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import { Navigation } from 'swiper';
import { SwiperContainer } from '../History.styled';
import HistorySliderContent from './HistoryContent';

const Slider = ({ historyData }) => (
  <SwiperContainer>
    <Swiper modules={[Navigation]} loop>
      {historyData.map((year) => (
        <SwiperSlide key={year.id} className="slide">
          <HistorySliderContent year={year} />
        </SwiperSlide>
      ))}
    </Swiper>
  </SwiperContainer>
);

Slider.propTypes = {
  historyData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      year: PropTypes.number.isRequired,
      text: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default Slider;
