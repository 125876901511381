import styled from 'styled-components';
import theme from '../../global/styles/theme';

const mobileMenu = '1240px';

export const NavbarContainer = styled.div`
  width: 100%;
  z-index: 500;
  position: fixed;
  top: 0;
  left: 0;
  background-color: transparent;
  transition: background-color 0.2s linear;

  .mobile-menu {
    position: fixed;
    z-index: 100;
    background-color: ${theme.colors.background['0']};
  }
`;

export const Navbar = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  color: ${theme.colors.backgroundDark['90']};
  font-weight: 600;
  font-size: 17px;
  line-height: 150%;
  letter-spacing: 0.51px;

  margin: 0 auto;

  .header {
    &__menu {
      &_toggle {
        display: none;
        @media (max-width: ${mobileMenu}) {
          display: block;
        }
        z-index: 1;
        position: relative;
        -webkit-user-select: none;
        user-select: none;

        & > span {
          display: block;
          width: 20px;
          height: 2.2px;
          margin-bottom: 3.4px;
          position: relative;
          background: #cdcdcd;
          border-radius: 3px;
          z-index: 1;
          transform-origin: 1.1px 50%;
          transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
            background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
        }

        & > input {
          display: block;
          width: 40px;
          height: 32px;
          position: absolute;
          top: -7px;
          left: -5px;
          cursor: pointer;
          opacity: 0;
          z-index: 2;
          -webkit-touch-callout: none;
        }

        & > input:checked ~ span {
          opacity: 1;
          transform: rotate(45deg) scale(0.9, 1);
        }

        & > input:checked ~ span:nth-of-type(2) {
          opacity: 0;
          transform: rotate(0deg) scale(0.2, 0.2);
        }

        & > input:checked ~ span:nth-of-type(3) {
          transform: rotate(-45deg) scale(0.9, 1);
        }
      }
    }
  }

  @media only screen and (max-width: ${mobileMenu}) {
    height: 53px;
  }

  > a:first-child {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const MenuContainer = styled.ul`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  color: ${theme.colors.text['90']};
  list-style-type: none;

  > li {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${theme.colors.text['90']};
    padding-left: 8px;
    padding-right: 8px;
    transition: 0.3s;

    > a {
      height: 100%;
      color: inherit;
      display: inherit;
      justify-content: inherit;
      align-items: inherit;
    }

    &::after,
    .active::after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 2px;
      border-radius: 1px;
      background-color: ${theme.colors.primary['40']};
      opacity: 0;
      transition: 0.3s;
    }

    &:hover,
    .active {
      color: ${theme.colors.primary['40']};

      &::after {
        opacity: 1;
      }
    }
  }

  @media (max-width: ${mobileMenu}) {
    display: none;
  }
`;

export const ContactContainer = styled.nav`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 28px;

  @media (max-width: ${mobileMenu}) {
    display: none;
  }

  .header__contact {
    &__btn {
      display: flex;
      gap: 12px;
      align-items: center;
      justify-content: center;
      width: max-content;
      padding: 11px 16px;
      font-size: 17px;
      font-weight: 600;
      line-height: 150%;
      letter-spacing: 0.51px;
      border: 1px solid ${theme.colors.stroke['70']};
      border-radius: 4px;
      background: transparent;
      color: ${theme.colors.text['90']};

      cursor: pointer;

      &:hover {
        cursor: pointer;
        border-color: ${theme.colors.stroke['95']};
      }
    }
  }
`;

export const MobileMenuContainer = styled.div`
  background-color: ${theme.colors.background['10']};
  width: 100%;
  position: fixed;
  bottom: 0;
  top: 53px;
  overflow-y: scroll;
  z-index: 100;
  padding-bottom: 20px;

  &::-webkit-scrollbar {
    display: none;
  }

  & > div {
    display: flex;
    flex-direction: column;
    gap: 51px;
    padding-top: 32px;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.02em;
    color: ${theme.colors.text['90']};
    list-style-type: none;
    cursor: pointer;
    margin: 0 auto;

    & > li {
      color: ${theme.colors.text['90']};

      & > a {
        height: 100%;
        color: inherit;
        display: inherit;
        justify-content: inherit;
        align-items: inherit;
      }
    }

    .header-mobile {
      &__btn {
        padding: 11px 16px;
        font-size: 18px;
        line-height: 27px;
        letter-spacing: 0.02em;
        border: 1px solid ${theme.colors.stroke['70']};
        border-radius: 4px;
        background-color: transparent;
        color: ${theme.colors.text['90']};

        &:hover {
          cursor: pointer;
          border-color: ${theme.colors.stroke['95']};
        }
      }
    }
  }

  .active {
    color: ${theme.colors.primary['40']};
  }
`;
