import React from 'react';
import HelmetHead from '../../components/HelmetHead';
import FirstBlock from './components/FirstBlock';
import { CaseStudiesPageContainer } from './CaseStudiesPage.styled';
import CaseStudies from './components/CaseStudies';

const CaseStudiesPage = () => (
  <>
    <HelmetHead page="caseStudiesPage" />
    <CaseStudiesPageContainer>
      <FirstBlock />
      <CaseStudies />
    </CaseStudiesPageContainer>
  </>
);

export default CaseStudiesPage;
