import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import TechnologyItem from './components/TechnologyItem';
import TechnologiesNavigation from './components/TechnologiesNavigation';
import { TechnologiesListContainer } from './TechnologiesList.styled';

const TechnologiesList = ({ data }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const navigationData = useMemo(() => data?.map((el) => el.area) || [], [data.length]);

  return (
    <TechnologiesListContainer>
      <Swiper
        onBeforeTransitionStart={(swiper) => {
          setCurrentStep(swiper.activeIndex);
        }}
        speed={800}
      >
        <TechnologiesNavigation navigationData={navigationData} currentStep={currentStep} />
        {data.map((technology) => (
          <SwiperSlide className="swiperContainer swiperSlide" key={technology.area}>
            <TechnologyItem area={technology.area} technologies={technology.technologies} />
          </SwiperSlide>
        ))}
      </Swiper>
    </TechnologiesListContainer>
  );
};

TechnologiesList.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      area: PropTypes.string.isRequired,
      technologies: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          logo: PropTypes.string.isRequired,
          addition: PropTypes.arrayOf(PropTypes.string.isRequired),
        }),
      ).isRequired,
    }),
  ).isRequired,
};

export default TechnologiesList;
