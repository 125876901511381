import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const HistorySliderContent = ({ year }) => {
  const { t } = useTranslation();

  return (
    <div className="year-container" key={year.id}>
      <div className="year-container__range-container">
        <div className="year-container__range-line" />
      </div>
      <div className="year-container__year">{year.year}</div>
      <div className="year-container__history-text">{t(`${year.text}`)}</div>
    </div>
  );
};

HistorySliderContent.propTypes = {
  year: PropTypes.shape({
    id: PropTypes.number.isRequired,
    year: PropTypes.number.isRequired,
    text: PropTypes.string.isRequired,
  }).isRequired,
};
export default HistorySliderContent;
