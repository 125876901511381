import React from 'react';
import PropTypes from 'prop-types';
import { ArrowChartContainer } from './ArrowChart.styled';

const ArrowChart = ({ setCurrentStep, currentStep, data }) => (
  <ArrowChartContainer>
    {data.map((label) => (
      <section
        className={` ${currentStep === Number(label.id) && 'active'}`}
        onClick={() => setCurrentStep(Number(label.id))}
        key={label.id}
      >
        <label>{label.title}</label>
      </section>
    ))}
  </ArrowChartContainer>
);

ArrowChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      subtitle: PropTypes.string.isRequired,
    }),
  ).isRequired,
  setCurrentStep: PropTypes.func.isRequired,
  currentStep: PropTypes.number.isRequired,
};

export default ArrowChart;
