import React from 'react';
import { useTranslation } from 'react-i18next';
import { TermsOfUseContainer } from './TermsOfUsePage.styled';
import BackgroundLinearText from '../../components/BackgroundLinearText';
import Layout from '../../components/Layout';
import useDesktopSize from '../../global/hooks/useDesktopSize';
import LegalTopics from '../../components/LegalTopis';
import { getTermsData } from './helpers';
import LegalInformation from '../../components/LegalInformation';

const TermsOfUsePage = () => {
  const { t } = useTranslation();
  const { isDesktop } = useDesktopSize();

  return (
    <TermsOfUseContainer>
      <div className="background-dark-title">
        <BackgroundLinearText type="dark">{t('termsPage.backgroundTitle')}</BackgroundLinearText>
      </div>
      <div className="terms-header responsive-width">
        <div className="header-container__title">
          <h2 className="title title_light ">{t('termsPage.title')}</h2>
        </div>
        <div className="subtitle subtitle_light">{t('termsPage.description')}</div>
      </div>
      <Layout>
        {isDesktop && <LegalTopics topics={getTermsData()} />}
        <LegalInformation information={getTermsData()} />
      </Layout>
      <div className="blur" />
      <div className="blur blur_second" />
    </TermsOfUseContainer>
  );
};

export default TermsOfUsePage;
