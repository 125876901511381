import styled from 'styled-components';
import theme from '../../../../../../global/styles/theme';

export const SummaryItemContainer = styled('div')`
  background-color: ${theme.colors.primary['100']};
  padding: 16px 24px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  .header-item {
    display: flex;
    justify-content: space-between;

    &__name {
      color: ${theme.colors.text['40']};
      font-family: Manrope, sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.02em;
    }

    &__edit-icon {
      cursor: pointer;
    }
  }

  .content-item {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.02em;

    &__simple {
      color: ${theme.colors.text['20']};
    }

    &__simple-ones {
      display: flex;
      gap: 17px;
      flex-wrap: wrap;

      svg {
        height: 14px;
        width: 14px;
        margin-right: 11px;
      }
    }

    &__object-items {
      display: flex;
      flex-flow: wrap;
      gap: 14px;

      &-advanced {
        display: flex;
        gap: 14px;
        flex-direction: column;
      }
    }

    &__container {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }

    &__items-area {
      font-weight: 500;
      color: ${theme.colors.text['60']};
    }

    &__object-item {
      display: flex;
      align-items: center;
      gap: 12px;
      flex-wrap: wrap;

      svg {
        height: 14px;
        width: 14px;
      }
    }

    &__name {
      font-size: 16px;
      font-weight: 500;
      line-height: 150%;
      letter-spacing: 0.32px;
      color: ${theme.colors.text['60']};
      padding-bottom: 12px;
    }

    &__technologies {
      display: flex;
      gap: 14px;
      flex-flow: wrap;
    }

    &__object-count {
      display: flex;
      gap: 10px;
      flex-flow: wrap;

      .count {
        color: ${theme.colors.primary['30']};
        padding-left: 4px;
        padding-right: 8px;
      }
    }
  }
`;
