import React, { useState } from 'react';
import { BsArrowLeft } from 'react-icons/bs';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { OfferContainer } from './Offer.styled';
import Layout from '../../../../components/Layout';
import { useGetOneJobPositionQuery } from '../../../../global/service/jobsService';
import { getFormattedInfo, infoOfferTitleStrategy } from './helpers';
import { useSendFeedbackMutation } from '../../../../global/service/feedBacksService';
import useNotifications from '../../../../global/hooks/useNotifications';
import GratitudeModal from '../../../../components/GratitudeModal';
import CareerForm from '../../../../components/CareerForm';
import MarkdownParser from '../../../../components/MarkdownParser';
import { formattedVacancyForm } from '../../../../global/helpers';

const Offer = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { jobPosition } = useParams();
  const { data } = useGetOneJobPositionQuery(jobPosition);
  const [sendFeedback] = useSendFeedbackMutation();

  const { addNotification } = useNotifications();
  const [isSuccessJoinOpen, setSuccessJoinOpen] = useState(false);

  const handleSubmitForm = async (values, formikHelpers) => {
    const onError = () => addNotification({ type: 'error', title: 'Error', subtitle: 'Try again' });
    const onSuccess = () => {
      setSuccessJoinOpen(true);
      formikHelpers.resetForm();
    };
    sendFeedback({ data: await formattedVacancyForm(values, data.id), onSuccess, onError });
  };

  const linkButton = (
    <button onClick={() => navigate(-1)} type="button" className={`btn btn-image btn_text_light `}>
      <div className="btn-image__outlined">
        <BsArrowLeft />
      </div>
      {t('common.back_to_job_openings')}
    </button>
  );

  const keyBlock = (key, description) => (
    <div className="offer__block">
      <div className="offer__block-title">{infoOfferTitleStrategy[key]}</div>
      <div
        className={`offer__block-description ${
          key === 'job_level' && 'offer__block-description_accent'
        }`}
      >
        {key === 'date_posted' ? moment(description).format('MMMM DD, YYYY') : description}
      </div>
    </div>
  );

  return (
    <>
      <OfferContainer>
        {location.key !== 'default' && <div className="responsive-width">{linkButton}</div>}
        <Layout>
          <div className="offer">
            <div className="offer__header">
              <div className="offer__name-position">{data.name.value}</div>
              <div className="offer__main-infos">
                {data.job_level.value && keyBlock('job_level', data.job_level.value)}
                {data.employment_type.value &&
                  keyBlock('employment_type', data.employment_type.value)}
                {data.work_type.value && keyBlock('work_type', data.work_type.value)}
                {data.date_posted && keyBlock('date_posted', data.date_posted)}
              </div>
            </div>
            <div className="offer__line" />
            <div className="offer__infos-block">
              {Object.entries(getFormattedInfo(data)).map(([key, value]) => (
                <div className="offer__info-block" key={key}>
                  <div className="offer__info-block-title">{infoOfferTitleStrategy[key]}</div>
                  <div className="offer__info-block-list">
                    <MarkdownParser>{value}</MarkdownParser>
                  </div>
                </div>
              ))}
            </div>
            <div className="offer__form">
              <div className="offer__form-title">{t('offer.formTitle')}</div>
              <CareerForm handleSubmitForm={handleSubmitForm} theme="light" />
            </div>
          </div>
        </Layout>
      </OfferContainer>
      {isSuccessJoinOpen && (
        <GratitudeModal
          onClose={() => setSuccessJoinOpen(false)}
          title={t('frontendAcademyBlock.successJoinModal.title')}
          description={t('frontendAcademyBlock.successJoinModal.description')}
        />
      )}
    </>
  );
};

export default Offer;
