import styled from 'styled-components';
import { mobileWidth } from '../../global/styles/Global.styled';

export const NotificationsListContainer = styled('div')`
  position: fixed;
  display: flex;
  flex-flow: column;
  align-items: center;
  top: 82px;
  left: 50%;
  transform: translateX(-50%);
  width: auto;
  min-width: 600px;
  max-width: 900px;
  z-index: 2001;

  @media only screen and (max-width: ${mobileWidth}) {
    top: 55px;
  }
`;
