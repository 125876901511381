import styled from 'styled-components';
import theme from '../../../../global/styles/theme';
import { mobileWidth } from '../../../../global/styles/Global.styled';

export const PreviewBlockContainer = styled('div')`
  display: flex;
  color: ${theme.colors.primary['100']};
  justify-content: space-between;
  grid-column: 1/19;
  gap: 10px;
  margin-top: 70px;

  @media only screen and (max-width: 1250px) {
    flex-direction: column;
  }

  @media only screen and (max-width: ${mobileWidth}) {
    grid-column: 1/5;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__title {
      font-family: Manrope, sans-serif;
      font-size: 54px;
      font-style: normal;
      font-weight: 800;
      line-height: 132%;
      letter-spacing: 1.28px;

      padding-bottom: 32px;

      @media only screen and (max-width: 1250px) {
        margin-top: 6px;
      }

      @media only screen and (max-width: ${mobileWidth}) {
        font-size: 34px;
        font-weight: 600;
        line-height: 140%;
        letter-spacing: 0.68px;
        width: 100%;
        position: static;
      }
    }

    &__subtitle {
      font-size: 19px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
      letter-spacing: 0.38px;
    }

    &__img {
      max-height: 708px;
      max-width: 718px;

      @media only screen and (max-width: ${mobileWidth}) {
        margin-top: 24px;
      }
    }
  }

  .btn {
    padding: 0;
    margin-top: 35.5px;
  }
`;
