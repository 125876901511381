import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BsArrowRight } from 'react-icons/bs';
import { PreviewBlockContainer } from './PreviewBlock.styled';
import { useGetOneCaseStudyQuery } from '../../../../global/service/caseStudiesService';
import Layout from '../../../../components/Layout';
import { getImagesByType } from '../../helpers';
import { useIsFirstRender } from '../../../../global/hooks/useIsFirtsRender';
import i18next from '../../../../global/translations/i18next';

const PreviewBlock = () => {
  const { t } = useTranslation();
  const { caseName } = useParams();
  const { data, refetch } = useGetOneCaseStudyQuery(caseName);

  const isFirstRender = useIsFirstRender();
  useEffect(() => {
    if (!isFirstRender) refetch();
  }, [i18next.language]);

  const previewImage = getImagesByType(data, 'main');

  return (
    <Layout>
      <PreviewBlockContainer>
        <div className="content">
          <div className="content__title">{data.project_name.value}</div>
          <div className="content__subtitle">{data.description.value}</div>
          <a href={data.url} target="_blank" rel="noopener noreferrer">
            <button type="button" className="btn btn-image btn_text_light">
              <div className="btn-image__outlined">
                <BsArrowRight />
              </div>
              {t('common.visit_website')}
            </button>
          </a>
        </div>
        {previewImage.map((image) => (
          <img src={image.url} alt="project" className="content__img" key={image.id} />
        ))}
      </PreviewBlockContainer>
    </Layout>
  );
};

export default PreviewBlock;
