import styled from 'styled-components';
import theme from '../../../../global/styles/theme';

const mobileWidth = '950px';

export const CandidateJourneyContainer = styled.div`
  margin: 0 auto;

  .content {
    margin: 0 auto;
    padding-top: 109px;
  }
`;

export const ContentContainer = styled.div`
  padding: 100px 112px 132px 61px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 120px;

  @media only screen and (max-width: 1240px) {
    padding: 100px 20px 132px;
  }

  @media only screen and (max-width: ${mobileWidth}) {
    padding: 0 16px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  @media only screen and (min-width: 1980px) {
    justify-items: center;
  }

  .images-container {
    position: relative;
    display: none;

    @media (min-width: ${mobileWidth}) {
      display: block;
    }

    &__single {
      position: absolute;
      transition: 0.7s;
      opacity: 0;
      width: 505px;
      height: 386px;

      &_active {
        opacity: 1;
      }
    }
  }

  .journey-container {
    display: flex;
    flex-direction: column;
    gap: 64px;
    position: relative;

    @media only screen and (max-width: ${mobileWidth}) {
      margin-top: 40px;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 23px;
      width: 2px;
      height: 88%;
      z-index: 2;
      background-color: ${theme.colors.stroke['50']};

      @media only screen and (max-width: 1150px) {
        height: 88%;
      }
    }

    &__item {
      display: flex;
      gap: 24px;
      max-width: 368px;

      &-icon {
        min-height: 56px;
        min-width: 56px;
      }

      &-content {
        display: flex;
        flex-direction: column;
        gap: 20px;
        color: ${theme.colors.text['95']};
        opacity: 0.5;

        &_active {
          opacity: 1;
          transition: color 0.5s;
        }
      }
    }

    &__number {
      font-family: 'Lato', sans-serif;
      font-size: 21px;
      font-style: normal;
      font-weight: 800;
      line-height: 140%;
      letter-spacing: 0.84px;
      color: ${theme.colors.text['60']};

      &_active {
        font-family: Manrope, sans-serif;
        font-size: 34px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
        letter-spacing: 0.68px;

        color: ${theme.colors.primary['100']};
      }
    }

    &__icon {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 48px;
      width: 48px;
      border: solid 1px #054b46;
      border-radius: 50%;
      background: ${theme.colors.background['20']};

      z-index: 5;
      position: absolute;

      &_active {
        height: 56px;
        width: 56px;
        z-index: 10;
        margin-left: -4px;
        transition: all 0.5s;

        background: ${theme.colors.background['20']};
      }
    }

    &__title {
      font-family: Manrope, sans-serif;
      font-size: 34px;
      font-style: normal;
      font-weight: 800;
      line-height: 140%;
      letter-spacing: 0.68px;

      max-width: 436px;
      width: 100%;
      word-break: break-word;
    }

    &__description {
      font-size: 19px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      letter-spacing: 0.38px;
      color: ${theme.colors.text['90']};
      opacity: 0.5;
      max-width: 436px;
      width: 100%;
      word-break: break-word;

      &_active {
        font-weight: 400;
        line-height: 150%;
        letter-spacing: 0.36px;
        color: ${theme.colors.text['90']};
      }
    }
  }
`;
