import React, { useState } from 'react';
import ReactSelect from 'react-select';
import { Element, scroller } from 'react-scroll';
import PropTypes from 'prop-types';
import { LegalInformationContainer } from './LegalInformation.styled';
import useDesktopSize from '../../global/hooks/useDesktopSize';

const LegalInformation = ({ information }) => {
  const { isDesktop } = useDesktopSize();
  const options = information.map(([key, value]) => ({ value: key, label: value.question }));
  const [currentQuestion, setCurrentQuestion] = useState(options[0]);

  const select = (
    <div className="main-block__select">
      <ReactSelect
        value={currentQuestion}
        classNamePrefix="legal-select"
        onChange={(e) => {
          setCurrentQuestion(e);
          scroller.scrollTo(e.value, {
            offset: -180,
            spy: true,
            smooth: true,
            duration: 500,
          });
        }}
        options={options}
        placeholder={options[0].label}
        isSearchable={false}
      />
    </div>
  );

  return (
    <LegalInformationContainer>
      {!isDesktop && select}
      <div className="main">
        {information.map(([key, value]) => (
          <Element name={key} className="main-block" key={key}>
            <div className="main-block__question">{value.question}</div>
            <div className="main-block__answer">{value.answer}</div>
          </Element>
        ))}
      </div>
    </LegalInformationContainer>
  );
};

LegalInformation.propTypes = {
  information: PropTypes.arrayOf(
    PropTypes.shape({
      [PropTypes.string]: {
        question: PropTypes.string,
        answer: PropTypes.string,
      },
    }),
  ).isRequired,
};

export default LegalInformation;
