import React from 'react';
import PropTypes from 'prop-types';
import { SwiperNavigatorContainer } from './SwiperNavigator.styled';

const SwiperNavigator = ({ steps, currentStep, slideTo }) => {
  const indexFormatter = (index) => (index > 9 ? index : `0${index}`);

  return (
    <SwiperNavigatorContainer>
      {new Array(steps).fill('').map((_, index) => (
        <div className="wrapper" onClick={() => slideTo(index)} key={`swiper-${index.toString()}`}>
          {index === currentStep && (
            <div className="wrapper__current-step">{indexFormatter(index + 1)}</div>
          )}
          <div className={`wrapper__swiper ${index === currentStep && 'wrapper__swiper_active'}`} />
        </div>
      ))}
    </SwiperNavigatorContainer>
  );
};

SwiperNavigator.propTypes = {
  steps: PropTypes.number.isRequired,
  currentStep: PropTypes.number.isRequired,
  slideTo: PropTypes.func.isRequired,
};

export default SwiperNavigator;
