import styled from 'styled-components';
import theme from '../../global/styles/theme';

export const CaseStudyPageContainer = styled('div')`
  background-color: ${theme.colors.background['10']};

  ul {
    list-style-type: inherit;
    padding-left: 20px;
  }
`;
