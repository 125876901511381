import styled from 'styled-components';
import theme from '../../../../global/styles/theme';

const mobileWidth = '1240px';

export const HowCanWeHelpOuterContainer = styled('div')`
  margin: 100px auto;
`;

export const HowCanWeHelpInnerContainer = styled('div')`
  background-color: ${theme.colors.background['10']};
  padding: 0 108px 109px 108px;
  margin: 0 auto;

  @media only screen and (max-width: ${mobileWidth}) {
    padding: 40px 16px 0 16px;
  }

  .btn,
  a {
    width: max-content;
    position: absolute;
    right: -27px;
    @media only screen and (max-width: ${mobileWidth}) {
      display: none;
    }
  }

  .content {
    padding-top: 109px;

    @media only screen and (max-width: ${mobileWidth}) {
      padding-top: 40px;
    }

    &__header-container {
      position: relative;
      margin-bottom: 74px;
      display: flex;
      flex-direction: column;
      @media only screen and (max-width: ${mobileWidth}) {
        margin-bottom: 40px;
      }
    }

    &__top-content {
      display: flex;
      justify-content: space-between;
      @media only screen and (max-width: ${mobileWidth}) {
        flex-direction: column;
      }
    }
  }

  .subtitle-block {
    letter-spacing: 0.02em;
    max-width: 593px;
    padding-top: 12px;

    @media only screen and (max-width: ${mobileWidth}) {
      width: 100%;
    }
  }
`;

const bottomBar = `
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  border-radius: 1px;
`;

export const NavigationMenuContainer = styled('div')`
  display: flex;
  margin-top: 79px;
  width: 100%;
  justify-content: space-between;

  @media only screen and (max-width: ${mobileWidth}) {
    display: none;
  }

  .content__nav-menu {
    position: relative;
    flex-direction: column;
    font-family: 'lato', sans-serif;
    line-height: 24px;
    color: #fff;
    cursor: pointer;
    vertical-align: top;

    max-width: 171px;
    padding-bottom: 20px;

    font-size: 21px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.42px;

    &::after {
      ${bottomBar};
      width: 100%;
      border-bottom: 2px solid ${theme.colors.stroke['50']};
    }

    &:before {
      ${bottomBar};
      z-index: 1;
      width: 0;
      border-bottom: 2px solid ${theme.colors.primary['40']};
      transition: 0.3s;
    }

    &:hover::before {
      width: 100%;
      transition: 0.5s;
    }
  }
`;

const mainContentText = `  
    color: ${theme.colors.background['80']};
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.38px;
`;

export const MainContentContainer = styled('div')`
  @media only screen and (max-width: ${mobileWidth}) {
    display: none;
  }

  display: flex;
  gap: 176px;
  margin-top: 130px;
  position: relative;

  .main-info {
    &__title {
      padding-bottom: 16px;
      color: ${theme.colors.text['90']};

      font-family: Manrope, sans-serif;
      font-size: 34px;
      font-style: normal;
      font-weight: 800;
      line-height: 140%;
      letter-spacing: 0.68px;
    }

    &__description {
      ${mainContentText};
      width: 445px;
    }

    &__btn-explore {
      bottom: 0;
      left: -20px;
    }
  }

  .img {
    height: 326px;
  }
`;

const prevNextBtn = `
background-color: rgba(255, 255, 255, 0.1);
        border-radius: 50px;
        border: none;
        width: 38px;
        height: 38px;
        position: absolute;
        top: 200px;
        color: ${theme.colors.backgroundDark['70']};
        font-size: 20px;
        padding-top: 3px;
       
  
`;

export const SlideContainer = styled('div')`
  @media only screen and (max-width: ${mobileWidth}) {
    display: flex;
    flex-direction: column;
  }

  .slide {
    &__title {
      position: relative;
      padding-top: 25px;
      font-family: 'Lato', sans-serif;
      font-size: 21px;
      font-weight: 500;
      color: #fff;
      padding-bottom: 16px;
      max-width: 244px;

      &::after {
        ${bottomBar};
        width: 100%;
        border-bottom: 2px solid ${theme.colors.stroke['50']};
      }
    }

    &__img {
      width: 100%;
      padding-top: 40px;
      padding-bottom: 24px;

      @media only screen and (max-width: ${mobileWidth}) {
        width: 90%;
        margin: 0 auto;
      }
    }

    &__text {
      ${mainContentText};
      padding-bottom: 27.5px;
      min-width: 343px;

      @media only screen and (max-width: ${mobileWidth}) {
        min-width: inherit;
      }
    }

    &__right-btn {
      ${prevNextBtn};
      right: 0;
      padding-left: 3px;

      @media only screen and (min-width: 905px) {
        display: none;
      }
    }

    &__left-btn {
      ${prevNextBtn};
      left: 0;
      padding-right: 3px;
      @media only screen and (min-width: 905px) {
        display: none;
      }
    }
  }
`;

export const SwiperContainer = styled.div`
  position: relative;
  @media only screen and (min-width: 1241px) {
    display: none;
  }

  .btn-bottom {
    padding-left: 16px;
    padding-bottom: 44px;
    background: none;
    border: none;
    display: flex;
  }
`;
