import React from 'react';
import PropTypes from 'prop-types';
import Select from '../../../../../../components/Select';
import QuestionTemplate from './QuestionTemplate';

const QuestionSelect = ({ question, isOptional, options, formikFieldName }) => {
  const optionsArePresent = options.length > 0;

  return (
    <QuestionTemplate question={question} isOptional={isOptional} name={formikFieldName}>
      <Select
        name={formikFieldName}
        selectOptions={
          optionsArePresent
            ? options.map((option) => ({
                value: option.name.value,
                label: option.name.value,
                id: option.id,
              }))
            : []
        }
        placeholder={optionsArePresent ? options[0].name.value : ''}
        theme="light"
      />
    </QuestionTemplate>
  );
};

export default QuestionSelect;

QuestionSelect.propTypes = {
  question: PropTypes.string.isRequired,
  isOptional: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.shape({ value: PropTypes.string.isRequired }),
    }),
  ),
  formikFieldName: PropTypes.string.isRequired,
};

QuestionSelect.defaultProps = {
  options: [],
};
