import styled from 'styled-components';
import theme from '../../../../global/styles/theme';
import { mobileWidth } from '../../../../global/styles/Global.styled';

export const OpenJobsContainer = styled.div`
  padding-top: 60px;
  margin: 0 auto;
  padding-bottom: 250px;

  .content {
    padding-top: 125px;
    margin: 0 auto;

    @media only screen and (max-width: 1142px) and (min-width: ${mobileWidth}) {
      padding-left: 108px;
    }

    @media only screen and (max-width: ${mobileWidth}) {
      padding-top: 40px;
    }

    &__title {
      color: ${theme.colors.text['90']};
      font-family: Manrope, sans-serif;
      font-weight: 800;
      font-size: 64px;
      line-height: 132%;
      letter-spacing: 0.02em;
    }
  }
`;

export const VacanciesContainer = styled.div`
  padding-left: 108px;
  padding-top: 74px;
  display: flex;
  margin: 0 auto;
  width: 1440px;

  @media only screen and (max-width: 1145px) {
    display: none;
  }

  .vacancies__list {
    width: 345px;
    margin-right: 38px;

    &-notActive {
      color: ${theme.colors.text['90']};
      opacity: 0.3;
      cursor: auto;
    }

    &_item {
      display: flex;
      gap: 115px;
      color: ${theme.colors.text['90']};
      font-family: 'Lato', sans-serif;
      font-size: 21px;
      font-weight: 700;
      line-height: 140%;
      letter-spacing: 0.42px;
      padding: 20px 16px;

      &-name {
        width: 162px;

        &:hover {
          cursor: pointer;
        }
      }

      &.active {
        background-color: ${theme.colors.background['99']};
        border-radius: 12px;
        height: 72px;
        color: ${theme.colors.text['30']};
        padding-left: 16px;

        &:hover {
          cursor: pointer;
        }
      }
    }

    &_btn {
      border: none;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      color: ${theme.colors.primary['100']};
      background-color: ${theme.colors.primary['30']};
      font-size: 19px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .vacancies {
    position: relative;

    &__container {
      border-left: 1px dashed ${theme.colors.stroke['50']};
      padding-left: 38px;
    }

    &__preview {
      background-color: ${theme.colors.background['99']};
      width: 830px;
      border-radius: 12px;
      margin-bottom: 24px;
      display: flex;
      padding: 24px;
      box-sizing: border-box;
      justify-content: space-between;

      @media only screen and (max-width: 1380px) {
        width: 600px;
      }

      &__title {
        font-family: 'Lato', sans-serif;
        font-size: 21px;
        font-weight: 800;
        line-height: 140%;
        letter-spacing: 0.84px;
        color: ${theme.colors.text['20']};
        padding-bottom: 17px;
      }

      &__description {
        font-size: 18px;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: 0.36px;
        padding-bottom: 12px;
      }

      &__apply {
        display: flex;
        gap: 20px;

        &-text {
          font-size: 18px;
          font-weight: 400;
          line-height: 150%;
          letter-spacing: 0.36px;
        }
      }
    }

    &__blur {
      position: absolute;
      bottom: 87px;
      right: 0;
      width: 80px;
      height: 200px;
      background: ${theme.colors.gradient['5']};
      border-radius: 50%;
      filter: blur(90px);
    }
  }
`;
