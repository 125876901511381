import { v4 as uuidv4 } from 'uuid';
import i18next from './translations/i18next';
import { convertSecondsToMonths } from '../containers/CaseStudy/components/AboutClient/helpers';

/**
 * @returns {object} serializable data
 * @param data {AxiosError}
 */
export const keysToCase = (data) => {
  if (Array.isArray(data)) {
    return data.map((i) => keysToCase(i));
  }

  if (data === Object(data) && !(data instanceof Date)) {
    const n = {};

    Object.entries(data).forEach(([key, value]) => {
      n[key] = keysToCase(value);
    });

    return n;
  }

  return data;
};

/**
 * @returns {data} data with converted fields to CamelCase
 * @param data {AxiosError}
 */
export const serializeAxiosError = (data) => keysToCase(data);

export const scrollListenerCreator = (elementRef, setActive, stepsNumber) => () => {
  if (!elementRef.current) return;
  const element = elementRef.current;

  const screenCenter = document.documentElement.scrollTop + window.innerHeight / 2;

  if (screenCenter < element.offsetTop) {
    setActive(-1);
    return;
  }

  if (screenCenter > element.offsetTop + element.scrollHeight) {
    setActive(stepsNumber - 1);
    return;
  }

  const stepHeight = element.scrollHeight / stepsNumber;
  const activeStep = Math.floor((screenCenter - element.offsetTop) / stepHeight);
  setActive(activeStep);
};

export const changeLanguage = (lang) => {
  i18next.changeLanguage(lang);
};

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

export const formattedVacancyForm = async (values, vacancyId) => {
  const file = await toBase64(values.file);
  return (
    file && {
      name: values.firstName.concat(' ', values.lastName),
      email: values.email,
      message: values.message || null,
      file,
      vacancy_id: vacancyId || null,
      linkedin_link: values.linkedin_link || null,
    }
  );
};

export const addMonthsToNumber = (number) => {
  if (number === 1) {
    return i18next.t('caseStudies.month');
  }
  if (number >= 2 && number <= 4) {
    return i18next.t('caseStudies.months_i');
  }
  if (number >= 5 && number <= 20) {
    return i18next.t('caseStudies.months');
  }

  const lastDigit = number % 10;
  if (lastDigit === 1) {
    return i18next.t('caseStudies.month');
  }
  if (lastDigit >= 2 && lastDigit <= 4) {
    return i18next.t('caseStudies.months_i');
  }

  return i18next.t('caseStudies.months');
};

export const formattedCaseStudyInfo = (project) => [
  {
    id: uuidv4(),
    name: project.industry.name.value,
  },
  {
    id: uuidv4(),
    name: `${project.team_size} ${i18next.t('caseStudies.people')}`,
  },
  {
    id: uuidv4(),
    name: `${Math.floor(convertSecondsToMonths(project.duration_seconds))} ${addMonthsToNumber(
      Math.floor(convertSecondsToMonths(project.duration_seconds)),
    )}`,
  },
  ...project.technologies.map((technology) => ({
    id: technology.id,
    name: technology.technology.name,
  })),
];

const LOCAL_STORAGE_KEY = 'roxers';

export const isLocalStorageAvailable = () => {
  if (typeof window !== 'undefined') {
    if (window.localStorage) {
      try {
        const testKey = 'testKey';
        const testValue = 'testValue';
        window.localStorage.setItem(testKey, testValue);
        const result = window.localStorage.getItem(testKey);
        if (result === testValue) {
          window.localStorage.removeItem(testKey);
          return true;
        }
      } catch (e) {
        return false;
      }
    }
    return false;
  }
  return false;
};

export const getFromLocalStorage = (key) => {
  if (isLocalStorageAvailable()) {
    const localObject = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) || '{}');
    return key === LOCAL_STORAGE_KEY ? localObject : localObject[key];
  }

  return null;
};

export const setToLocalStorage = (key, item) => {
  if (isLocalStorageAvailable()) {
    const localObject = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) || '{}');
    localObject[key] = item;
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(localObject));
  }
};
