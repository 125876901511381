import React from 'react';
import PropTypes from 'prop-types';
import { TechnologyItemContainer } from './TechnologyItem.styled';

const TechnologyItem = ({ area, technologies }) => (
  <TechnologyItemContainer>
    <div className="area">{area}</div>
    <div className="content-block">
      {technologies.map((el, index) => (
        <div key={`${el.name}-${String(index)}`} className="content-block__item">
          <div className="content-block__item-header">
            <div className="content-block__item-header-name">{el.name}</div>
            <img className="content-block__item-header-logo" alt={el.name} src={el.logo} />
          </div>
          <div className="content-block__item-main">
            <div className="content-block__item-main-addition">{el.description}</div>
          </div>
        </div>
      ))}
    </div>
  </TechnologyItemContainer>
);
TechnologyItem.propTypes = {
  area: PropTypes.string.isRequired,
  technologies: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      logo: PropTypes.string.isRequired,
      addition: PropTypes.arrayOf(PropTypes.string.isRequired),
    }),
  ).isRequired,
};

export default TechnologyItem;
