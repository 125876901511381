import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CandidateJourneyContainer, ContentContainer } from './CandidateJourney.styled';
import BackgroundLinearText from '../../../../components/BackgroundLinearText';

import { candidateJourneyData, heightStrategy } from './constants';
import { scrollListenerCreator } from '../../../../global/helpers';

const CandidateJourney = () => {
  const { t } = useTranslation();

  const [activeValue, setActiveValue] = useState(-1);
  const valuesRef = useRef(null);

  const images = useMemo(
    () => candidateJourneyData.map((step) => ({ src: step.image, key: step.id })),
    [candidateJourneyData],
  );

  useEffect(() => {
    const scrollListener = scrollListenerCreator(valuesRef, setActiveValue, 5);
    window.addEventListener('scroll', scrollListener);
    scrollListener();

    return () => window.removeEventListener('scroll', scrollListener);
  }, []);

  return (
    <CandidateJourneyContainer>
      <div className="background-dark-title">
        <BackgroundLinearText type="dark">
          {t('career.candidate_journey.title')}
        </BackgroundLinearText>
      </div>
      <div className="content responsive-width">
        <h2 className="title title_light">{t('career.candidate_journey.title')}</h2>
      </div>
      <ContentContainer>
        <div className="images-container">
          {images.map((image, index) => (
            <img
              key={image.key}
              className={`images-container__single ${
                activeValue === index && 'images-container__single_active'
              }`}
              src={image.src}
              alt={image.title}
              style={{ top: heightStrategy[index] }}
            />
          ))}
        </div>
        <div className="journey-container" ref={valuesRef}>
          {candidateJourneyData.map((step, index) => (
            <div className="journey-container__item" key={step.id}>
              <div className="journey-container__item-icon">
                {' '}
                <div
                  className={`journey-container__icon ${
                    activeValue === index && 'journey-container__icon_active'
                  }`}
                >
                  <div
                    className={`journey-container__number ${
                      activeValue === index && 'journey-container__number_active'
                    }`}
                  >
                    {step.id}
                  </div>
                </div>
              </div>
              <div
                className={`journey-container__item-content ${
                  activeValue === index && 'journey-container__item-content_active'
                }`}
              >
                <div className="journey-container__title">{t(`${step.title}`)}</div>
                <div
                  className={`journey-container__description ${
                    activeValue === index && 'journey-container__description_active'
                  }`}
                >
                  {t(`${step.description}`)}
                </div>
              </div>
            </div>
          ))}
        </div>
      </ContentContainer>
    </CandidateJourneyContainer>
  );
};

export default CandidateJourney;
