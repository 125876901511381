import React from 'react';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ModalWindow from '../../../../../../components/ModalWindow';
import { JoinAcademyModalFormContainer } from './JoinAcademyModalForm.styled';
import Input from '../../../../../../components/Input';
import { formikInjectedPropsTypes } from '../../../../../../global/types';
import { joinAcademyFormValidationSchema } from '../../../../validationSchemas';
import { useJoinAcademyMutation } from '../../../../../../global/service/joinAcademyService';

const JoinAcademyModalForm = ({ onClose, handleSuccessForm }) => {
  const { t } = useTranslation();

  const [joinAcademy] = useJoinAcademyMutation();

  const handleSubmitForm = (values) => {
    const onSuccess = () => {
      onClose();
      handleSuccessForm();
    };
    joinAcademy({ data: values, onSuccess });
  };

  return (
    <ModalWindow onClose={onClose}>
      <Formik
        initialValues={{
          name: '',
          email: '',
        }}
        validationSchema={joinAcademyFormValidationSchema}
        onSubmit={handleSubmitForm}
      >
        {(props) => (
          <Form>
            <JoinAcademyModalFormContainer>
              <div className="modal__title">{t('frontendAcademyBlock.joinAcademyModal.title')}</div>
              <div className="modal__description">
                {t('frontendAcademyBlock.joinAcademyModal.description')}
              </div>

              <div className="fieldsBlock">
                <Input
                  name="name"
                  error={props.touched.name && props.errors.name}
                  label={t('frontendAcademyBlock.joinAcademyModal.name')}
                  placeholder={t('frontendAcademyBlock.joinAcademyModal.name')}
                  required
                />
                <Input
                  name="email"
                  error={props.touched.email && props.errors.email}
                  label={t('frontendAcademyBlock.joinAcademyModal.email')}
                  placeholder={t('frontendAcademyBlock.joinAcademyModal.email')}
                  required
                />
              </div>

              <div className="sendFromBtnContainer">
                <button type="submit" className="btn btn_primary_contained sendFormBtn">
                  {' '}
                  {t('frontendAcademyBlock.joinAcademyModal.send')}
                </button>
              </div>
            </JoinAcademyModalFormContainer>
          </Form>
        )}
      </Formik>
    </ModalWindow>
  );
};

JoinAcademyModalForm.propTypes = {
  ...formikInjectedPropsTypes,
  onClose: PropTypes.func.isRequired,
  handleSuccessForm: PropTypes.func.isRequired,
};

export default JoinAcademyModalForm;
