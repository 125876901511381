import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { BriefPageContainer } from './BriefPage.styled';
import BriefHeader from './components/Brief/components/BriefHeader';
import Layout from '../../components/Layout';
import BriefForm from './components/Brief/components/BriefForm';
import Summary from './components/Summary';
import { useGetBriefContentQuery, useSendBriefMutation } from '../../global/service/briefService';
import { INITIAL_VALUES, CURRENT_FORM, SUCCESS_FORM } from './constants';
import { mapValuesForBrief } from './helpers';
import { postBriefValidationSchema } from './validationSchemas';
import SuccessSubmit from './components/SuccessSubmit';
import useNotifications from '../../global/hooks/useNotifications';
import SubmitForm from './components/SubmitForm';
import { formikInjectedPropsTypes } from '../../global/types';
import ErrorState from '../../components/ErrorState';
import Loader from '../../components/Loader';
import { useIsFirstRender } from '../../global/hooks/useIsFirtsRender';
import i18next from '../../global/translations/i18next';

const BriefPage = () => {
  const { t } = useTranslation();
  const [sendBrief, state] = useSendBriefMutation();
  const [statusBrief, setStatusBrief] = useState(CURRENT_FORM);
  const [isSubmitFormShowed, setIsSubmitFormShowed] = useState(false);
  const { addNotification } = useNotifications();
  const { data, isError, refetch, isLoading } = useGetBriefContentQuery();

  const isFirstRender = useIsFirstRender();
  useEffect(() => {
    if (!isFirstRender) refetch();
  }, [i18next.language]);

  const handleSubmitForm = (values) => {
    const onSuccess = () => {
      setStatusBrief(SUCCESS_FORM);
      window.scrollTo(0, 0);
    };
    const onError = () => {
      setStatusBrief(CURRENT_FORM);
      addNotification({
        type: 'error',
        title: t('common.notifications.error.title'),
        subtitle: t('common.notifications.error.subtitle'),
      });
    };
    setIsSubmitFormShowed(false);

    sendBrief({ data: mapValuesForBrief(values), onSuccess, onError });
  };

  const handleBack = (props) => {
    setStatusBrief(CURRENT_FORM);
    props.resetForm({
      values: {
        ...INITIAL_VALUES,
        consultation: [],
        software_solutions: [],
        technologies: [],
        specialists: {},
      },
    });
  };

  return (
    <BriefPageContainer>
      <BriefHeader status={statusBrief} />
      {data && !isError && (
        <Formik
          onSubmit={handleSubmitForm}
          validationSchema={postBriefValidationSchema}
          initialValues={INITIAL_VALUES}
        >
          {(props) => (
            <Form>
              <Layout>
                {statusBrief === CURRENT_FORM && (
                  <>
                    <BriefForm />
                    <Summary setSubmitFormShowed={(e) => setIsSubmitFormShowed(e)} />
                  </>
                )}
                {statusBrief === SUCCESS_FORM && (
                  <SuccessSubmit onClick={() => handleBack(props)} />
                )}
                {isSubmitFormShowed && <SubmitForm onClose={() => setIsSubmitFormShowed(false)} />}
              </Layout>
            </Form>
          )}
        </Formik>
      )}
      {isError && <ErrorState type="dark" />}
      {(state.isLoading || isLoading) && <Loader />}
    </BriefPageContainer>
  );
};

BriefPage.propTypes = {
  ...formikInjectedPropsTypes,
};

export default BriefPage;
