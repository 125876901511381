import React from 'react';
import { useTranslation } from 'react-i18next';
import { PrivacyPolicyContainer } from './PrivacyPolicyPage.styled';
import BackgroundLinearText from '../../components/BackgroundLinearText';
import Layout from '../../components/Layout';
import useDesktopSize from '../../global/hooks/useDesktopSize';
import LegalTopics from '../../components/LegalTopis';
import { getPrivacyData } from './helpers';
import LegalInformation from '../../components/LegalInformation';

const PrivacyPolicyPage = () => {
  const { t } = useTranslation();
  const { isDesktop } = useDesktopSize();

  return (
    <PrivacyPolicyContainer>
      <div className="background-dark-title">
        <BackgroundLinearText type="dark">{t('privacyPage.backgroundTitle')}</BackgroundLinearText>
      </div>
      <div className="policy-header responsive-width">
        <div className="header-container__title">
          <h2 className="title title_light ">{t('privacyPage.title')}</h2>
        </div>
        <div className="subtitle subtitle_light">{t('privacyPage.description')}</div>
      </div>
      <Layout>
        {isDesktop && <LegalTopics topics={getPrivacyData()} />}
        <LegalInformation information={getPrivacyData()} />
      </Layout>
      <div className="blur" />
      <div className="blur blur_second" />
    </PrivacyPolicyContainer>
  );
};

export default PrivacyPolicyPage;
