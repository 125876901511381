import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import BackgroundLinearText from '../../../../components/BackgroundLinearText';
import { OtherCaseStudiesContainer } from './OtherCaseStudies.styled';
import { useGetCaseStudiesQuery } from '../../../../global/service/caseStudiesService';
import CaseStudiesCard from '../../../CaseStudiesPage/components/CaseStudiesCard';
import SwiperNavigator from '../../../../components/SwiperNavigator';
import Loader from '../../../../components/Loader';
import useDesktopSize from '../../../../global/hooks/useDesktopSize';
import { formattedCaseStudyInfo } from '../../../../global/helpers';
import i18next from '../../../../global/translations/i18next';

const OtherCaseStudies = () => {
  const { t } = useTranslation();
  const selectedProject = useParams();
  const [currentSlide, setCurrentSlide] = useState(0);
  const { isDesktop } = useDesktopSize();

  const { data, refetch } = useGetCaseStudiesQuery();

  useEffect(() => {
    refetch();
  }, [i18next.language]);

  const otherCards = useMemo(
    () => data && data.filter((card) => card.project_name !== selectedProject.caseName),
    [data, selectedProject, i18next.language],
  );

  return (
    <OtherCaseStudiesContainer>
      <div className="background-dark-title">
        <BackgroundLinearText type="dark">{t('otherCaseStudies.title')}</BackgroundLinearText>
      </div>
      <div className="content responsive-width">
        <h2 className="title title_light">{t('otherCaseStudies.title')}</h2>

        {otherCards ? (
          <Swiper
            onBeforeTransitionStart={(swiper) => {
              setCurrentSlide(swiper.activeIndex);
            }}
            autoHeight
            speed={800}
            spaceBetween={60}
            className="content__results"
            slidesPerView={1}
            breakpoints={{
              1200: {
                slidesPerView: 2,
              },
            }}
          >
            {otherCards.map((card) => (
              <SwiperSlide key={card.id}>
                <CaseStudiesCard
                  projectName={card.project_name.value}
                  cardTitle={card.name}
                  imgUrl={card.images[0].url}
                  technologies={formattedCaseStudyInfo(card)}
                  projectSlug={card.slug}
                />
              </SwiperSlide>
            ))}
            {isDesktop ? (
              <SwiperNavigator
                steps={Math.round(otherCards.length / 2)}
                currentStep={currentSlide}
              />
            ) : (
              <SwiperNavigator steps={otherCards.length} currentStep={currentSlide} />
            )}
          </Swiper>
        ) : (
          <Loader />
        )}
      </div>
    </OtherCaseStudiesContainer>
  );
};

export default OtherCaseStudies;
