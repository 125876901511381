import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { ErrorStateContainer } from './ErrorState.styled';
import { ReactComponent as Light } from '../../global/images/common/empty-light.svg';
import { ReactComponent as Dark } from '../../global/images/common/empty-dark.svg';

const ErrorState = ({ type, height }) => {
  const { t } = useTranslation();

  return (
    <ErrorStateContainer height={height}>
      {type === 'light' && <Light />}
      {type === 'dark' && <Dark />}
      <div className={`description ${type === 'light' ? 'description_light' : 'description_dark'}`}>
        {t('common.empty_state_error')}
      </div>
    </ErrorStateContainer>
  );
};

ErrorState.propTypes = {
  type: PropTypes.oneOf(['light', 'dark']).isRequired,
  height: PropTypes.string,
};

ErrorState.defaultProps = {
  height: '',
};

export default ErrorState;
