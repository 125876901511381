import React from 'react';
import { AboutPageContainer } from './AboutPage.styled';
import FrontendAcademy from './components/FrontendAcademy';
import GetInTouch from '../../components/GetInTouch';
import History from './components/History';
import OurTeam from './components/OurTeam';
import ProcessBlock from '../../components/ProcessBlock';
import About from './components/About';
import FactsAndNumbers from '../MainPage/components/FactsAndNumbers';
import OurValues from './components/OurValues';
import HelmetHead from '../../components/HelmetHead';

const AboutPage = () => (
  <>
    <HelmetHead page="aboutPage" />
    <AboutPageContainer>
      <About />
      <OurValues />
      <FrontendAcademy />
      <History />
      <ProcessBlock />
      <OurTeam />
      <FactsAndNumbers />
      <GetInTouch />
    </AboutPageContainer>
  </>
);

export default AboutPage;
