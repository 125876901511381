import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { BsArrowRight } from 'react-icons/bs';
import PropTypes from 'prop-types';
import { ThankYouPageContainer } from './ThankYouPage.styled';
import BackgroundLinearText from '../../../../components/BackgroundLinearText';

const ThankYouPage = ({ setIsThanksPageShowed }) => {
  const { t } = useTranslation();
  return (
    <ThankYouPageContainer>
      <div className="background-dark-title ">
        <BackgroundLinearText type="dark">
          {t('contactUsPage.thankYouPage.backgroundTitle')}
        </BackgroundLinearText>
      </div>
      <div className="content responsive-width">
        <h2 className="title content__title"> {t('contactUsPage.thankYouPage.title')}</h2>
        <div className="content__subtitle">
          {t('contactUsPage.thankYouPage.subtitle')}
          <Link to="/" className="content__subtitle_link">
            {t('contactUsPage.thankYouPage.companyProfile')}
          </Link>
        </div>
        <div className="content__blur" />
        <button
          type="button"
          className="btn btn-image btn_text_light"
          onClick={() => setIsThanksPageShowed(false)}
        >
          <div className="btn-image__outlined">
            <BsArrowRight />
          </div>
          {t('contactUsPage.thankYouPage.button')}
        </button>
      </div>
    </ThankYouPageContainer>
  );
};

ThankYouPage.propTypes = {
  setIsThanksPageShowed: PropTypes.func.isRequired,
};

export default ThankYouPage;
