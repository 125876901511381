import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsArrowRight } from 'react-icons/bs';
import { Link } from 'react-scroll';
import {
  HowCanWeHelpInnerContainer,
  HowCanWeHelpOuterContainer,
  MainContentContainer,
  NavigationMenuContainer,
} from './HowWeCanHelp.styled';
import BackgroundLinearText from '../../../../components/BackgroundLinearText';
import Slider from './Slider';
import { howCanWeHelpData } from './constants';

const HowCanWeHelp = () => {
  const { t } = useTranslation();

  const [selectedId, setSelectedId] = useState(0);

  return (
    <HowCanWeHelpOuterContainer>
      <div className="background-dark-title-outlined">
        <BackgroundLinearText type="dark">{t('how_can_we_help.title')}</BackgroundLinearText>
      </div>

      <HowCanWeHelpInnerContainer className="light-container-responsive transparent-outlined-block">
        <div className="content responsive-width">
          <div className="content__header-container">
            <div className="content__top-content">
              <div className="title title_light">{t('how_can_we_help.title')}</div>
            </div>
            <div className="subtitle subtitle_light">{t('how_can_we_help.subtitle')}</div>
            <Link to="cooperation" offset={-50} spy smooth>
              <button type="button" className="btn btn-image btn_text_light">
                <div className="btn-image__outlined">
                  <BsArrowRight />
                </div>
                {t('how_can_we_help.top_button')}
              </button>
            </Link>
          </div>
          <NavigationMenuContainer>
            {howCanWeHelpData.map((datum) => (
              <div
                key={datum.id}
                className="content__nav-menu"
                onClick={() => setSelectedId(datum.id)}
              >
                {t(`${datum.menu}`)}
              </div>
            ))}
          </NavigationMenuContainer>
        </div>
        <MainContentContainer>
          <div className="main-info">
            <div className="main-info__title">{t(`${howCanWeHelpData[selectedId].title}`)}</div>
            <div className="main-info__description">
              {t(`${howCanWeHelpData[selectedId].text}`)}
            </div>
            {/* <button type="button" className="btn btn-image btn_text_light main-info__btn-explore"> */}
            {/*  <div className="btn-image__outlined"> */}
            {/*    <BsArrowRight /> */}
            {/*  </div> */}
            {/*  {t('how_can_we_help.explore_button')} */}
            {/* </button> */}
          </div>
          <img className="img" src={howCanWeHelpData[selectedId].imgDesktop} alt="product" />
        </MainContentContainer>
        <Slider howCanWeHelpData={howCanWeHelpData} />
      </HowCanWeHelpInnerContainer>
    </HowCanWeHelpOuterContainer>
  );
};

export default HowCanWeHelp;
