import styled from 'styled-components';
import { mobileWidth } from '../../../../../../global/styles/Global.styled';

export const JoinAcademyModalFormContainer = styled('div')`
  max-width: 760px;

  .modal {
    &__title {
      @media only screen and (max-width: ${mobileWidth}) {
        padding-top: 100px;
      }
    }
  }

  .fieldsBlock {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 40px;

    @media only screen and (max-width: 1024px) {
      display: flex;
      flex-direction: column;
      grid-gap: 24px;
    }
  }

  .fillFromBtn {
    height: 52px;
  }

  .sendFromBtnContainer {
    margin-top: 40px;

    display: flex;
    justify-content: flex-end;

    .sendFormBtn {
      width: 148px;
    }
  }
`;
