import { apiService } from './api';
import { isMockTechnologiesServices } from './isMockEnabled';
import technologiesList from '../../__mocks__/technologies/getTechnologiesDataMock.json';

export const technologiesService = apiService.injectEndpoints({
  endpoints: (builder) => ({
    getTechnologies: builder.query({
      query: () => ({
        url: `technologies`,
        method: 'GET',
        isMockEnabled: isMockTechnologiesServices,
        mockData: technologiesList,
      }),
    }),
  }),
});

export const { useGetTechnologiesQuery } = technologiesService;
