export const addresses = [
  {
    title: 'contactUsPage.addresses.headOffice.title',
    address: ['contactUsPage.addresses.headOffice.street'],
    phone: 'contactUsPage.addresses.headOffice.phone',
  },
  {
    title: 'contactUsPage.addresses.salesOffice1.title',
    address: [
      'contactUsPage.addresses.salesOffice1.street',
      'contactUsPage.addresses.salesOffice2.street',
    ],
    phone: null,
  },
  {
    title: 'contactUsPage.addresses.corporateOffice.title',
    address: ['contactUsPage.addresses.corporateOffice.street'],
    phone: 'contactUsPage.addresses.corporateOffice.phone',
  },
];

export const contacts = [
  {
    title: 'contactUsPage.support',
    email: 'support@roxers.io',
  },
  {
    title: 'contactUsPage.sales',
    email: 'sales@roxers.io',
  },
  {
    title: 'contactUsPage.pr',
    email: 'pr@roxers.io',
  },
];
