import React from 'react';
import { useTranslation } from 'react-i18next';
import { HiOutlineMail } from 'react-icons/hi';
import { FaLinkedinIn } from 'react-icons/fa';
import { OurTeamContainer } from './OurTeam.styled';
import BackgroundLinearText from '../../../../components/BackgroundLinearText';
import Layout from '../../../../components/Layout';
import { teamData } from './constants';
import theme from '../../../../global/styles/theme';

const OurTeam = () => {
  const { t } = useTranslation();

  return (
    <OurTeamContainer>
      <div className="background-dark-title">
        <BackgroundLinearText type="dark">{t('ourTeamBlock.backgroundText')}</BackgroundLinearText>
      </div>
      <Layout>
        <div className="content">
          <div className="content__header-container">
            <h2 className="title title_light">{t('ourTeamBlock.title')}</h2>
            <div className="subtitle subtitle_light">{t('ourTeamBlock.subtitle')}</div>
          </div>
          <div className="content__team-container">
            {teamData.map((person) => (
              <div className="content__person" key={person.name}>
                <div>
                  <img src={person.image} alt={person.name} />
                </div>

                <div className="content__person-info">
                  <div className="content__person-info-header">
                    <div className="content__person-info-name">{person.name}</div>
                    <div className="content__person-info-position">{person.position}</div>
                  </div>
                  <div className="content__person-info-line" />
                  <div className="content__person-info-links">
                    <div className="content__person-info-email">
                      <div>
                        <HiOutlineMail
                          strokeWidth="2.5"
                          style={{ color: `${theme.colors.primary['40']}` }}
                        />
                      </div>
                      <div>
                        <a href={`mailto:${person.email}`}>{person.email}</a>
                      </div>
                    </div>
                    <div className="content__person-info-linkedin">
                      <div>
                        {' '}
                        <FaLinkedinIn style={{ color: `${theme.colors.primary['40']}` }} />
                      </div>
                      <div>
                        <a rel="noreferrer" target="_blank" href={person.linkedin}>
                          {person.linkedin}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Layout>
    </OurTeamContainer>
  );
};

export default OurTeam;
