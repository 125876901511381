import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Element } from 'react-scroll';
import { QuestionContainer } from './Question.styled';
import { useExpand } from '../../../../../../global/hooks/useExpand';

const QuestionTemplate = ({ children, question, isOptional, name }) => {
  const { t } = useTranslation();
  const [opened, switchOpened] = useExpand();

  return (
    <Element name={name}>
      <QuestionContainer>
        <div className="question__title">
          <div className="question__brief">
            {question}
            {isOptional ? (
              <span className="question__optional">{t('brief.optionQuestion')}</span>
            ) : (
              ''
            )}
          </div>
          <div className="question__button-expand" onClick={switchOpened}>
            {opened ? '-' : '+'}
          </div>
        </div>
        <div className={opened ? '' : 'question__hidden-content'}>{children}</div>
      </QuestionContainer>
    </Element>
  );
};

export default QuestionTemplate;

QuestionTemplate.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  question: PropTypes.string.isRequired,
  isOptional: PropTypes.bool.isRequired,
};
