import styled from 'styled-components';
import theme from '../../../../global/styles/theme';
import { mobileWidth } from '../../../../global/styles/Global.styled';

export const FrontendAcademyContainer = styled('div')`
  margin: 100px auto;
  position: relative;
  overflow: hidden;

  .background-dark-title {
    padding-left: 0;
  }
`;

export const FrontendAcademyContentContainer = styled('div')`
  padding: 100px 107px 20px;
  position: relative;
  margin: 0 auto;

  @media only screen and (max-width: ${mobileWidth}) {
    padding: 40px 16px;
  }

  .header-container {
    &__title-container {
      display: flex;
      justify-content: space-between;

      @media only screen and (max-width: ${mobileWidth}) {
        flex-direction: column;
      }

      .btn {
        padding: 24px 0 0;
      }
    }

    .title-block {
      max-width: 456px;
    }
  }

  .academy-grid {
    @media only screen and (min-width: 1440px) {
      display: grid;
      grid-template-columns: 507px 1fr;
    }

    &__description {
      @media only screen and (min-width: 1440px) {
        padding: 160px 0;
        max-width: 600px;
      }

      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    &__item {
      font-size: 19px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      letter-spacing: 0.38px;
      color: ${theme.colors.text['80']};
    }
  }
`;

export const FrontendAcademyImageContainer = styled('div')`
  @media only screen and (min-width: 1440px) {
    position: absolute;
    right: -40px;
    bottom: 0;
    margin: 0 -3px -5px 0;
  }

  img {
    border-radius: 12px !important;
  }

  .academy-image {
    max-width: 676px;
    width: 100%;
    height: auto;

    @media only screen and (max-width: 1439px) {
      display: none;
    }
  }

  .academy-image-mobile {
    margin: 50px 0 20px;
    width: 100%;
    height: auto;

    @media only screen and (min-width: 1440px) {
      display: none;
    }
  }
`;
