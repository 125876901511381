import React from 'react';
import { useTranslation } from 'react-i18next';
import { BsArrowRight } from 'react-icons/bs';
import { Link } from 'react-scroll';
import BackgroundLinearText from '../../../../components/BackgroundLinearText';
import {
  TechnologiesWeUseContainer,
  TechnologiesWeUseContentContainer,
} from './TechnologiesWeUse.styled';
import useDesktopSize from '../../../../global/hooks/useDesktopSize';
import { useGetTechnologiesQuery } from '../../../../global/service/technologiesService';
import TechnologiesList from './components/TechnologiesList';
import { formattedTechnologiesData } from './helpers';
import ErrorState from '../../../../components/ErrorState';

const TechnologiesWeUse = () => {
  const { t } = useTranslation();
  const { isDesktop } = useDesktopSize();

  const { data, isError } = useGetTechnologiesQuery();

  const linkButton = (
    <Link to="cooperation" offset={-50} spy smooth>
      <button type="button" className="btn btn-image btn_text_light">
        <div className="btn-image__outlined">
          <BsArrowRight />
        </div>
        {t('technologiesWeUseBlock.linkText')}
      </button>
    </Link>
  );

  return (
    <TechnologiesWeUseContainer>
      <div className="background-dark-title-outlined">
        <BackgroundLinearText type="dark">
          {t('technologiesWeUseBlock.backgroundText')}
        </BackgroundLinearText>
      </div>

      <TechnologiesWeUseContentContainer className="light-container-responsive transparent-outlined-block">
        <div className="header-container">
          <div className="header-container__title">
            <h2 className="title title_light">{t('technologiesWeUseBlock.title')}</h2>
            {isDesktop && linkButton}
          </div>
          <div className="subtitle subtitle_light">{t('technologiesWeUseBlock.subtitle')}</div>
        </div>

        {data && !isError && <TechnologiesList data={formattedTechnologiesData(data)} />}
        {isError && <ErrorState type="dark" />}
      </TechnologiesWeUseContentContainer>
    </TechnologiesWeUseContainer>
  );
};

export default TechnologiesWeUse;
