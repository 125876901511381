import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BsArrowRight } from 'react-icons/bs';
import { CardsContainer, CaseStudiesContainer } from './CaseStudiesMainPage.styled';
import BackgroundLinearText from '../../../../components/BackgroundLinearText';
import { useGetCaseStudiesQuery } from '../../../../global/service/caseStudiesService';
import Slider from './components/Slider';
import CaseStudiesCard from './components/CaseStudiesCard';
import { CARDS_NUM_MAIN_PAGE, caseStudiesView } from './components/constants';
import ErrorState from '../../../../components/ErrorState';
import { formattedCaseStudyInfo } from '../../../../global/helpers';
import { useIsFirstRender } from '../../../../global/hooks/useIsFirtsRender';
import i18next from '../../../../global/translations/i18next';

const CaseStudiesMainPage = ({ isSingleRow }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleButtonClick = () => navigate('/case-studies');

  const { data, isError, refetch } = useGetCaseStudiesQuery(isSingleRow);

  const isFirstRender = useIsFirstRender();
  useEffect(() => {
    if (!isFirstRender) refetch();
  }, [i18next.language]);

  useMemo(() => {
    if (!data) return [];

    return data;
  }, [data]);

  return (
    <CaseStudiesContainer>
      <div className="background-dark-title">
        <BackgroundLinearText type="dark">{t('caseStudies.title')}</BackgroundLinearText>
      </div>
      <div className="content responsive-width">
        <div className="content__header">
          <div className="content__header-top">
            <div className="title title_light">{t('caseStudies.title')}</div>
          </div>
          <div className="subtitle subtitle_light">{t('caseStudies.subtitle')}</div>
          <button
            type="button"
            className="btn btn-image btn_text_light"
            onClick={handleButtonClick}
          >
            <div className="btn-image__outlined">
              <BsArrowRight />
            </div>
            {t('caseStudies.button')}
          </button>
        </div>
      </div>
      {data && !isError && (
        <>
          <CardsContainer className={isSingleRow ? 'single-row' : ''}>
            {data.slice(0, CARDS_NUM_MAIN_PAGE).map((project, index) => (
              <CaseStudiesCard
                key={project.id}
                type={caseStudiesView[index]}
                cartTitle={project.project_name.value}
                imgUrl={project.images[0].url}
                technologies={formattedCaseStudyInfo(project)}
                projectSlug={project.slug}
              />
            ))}
          </CardsContainer>
          <Slider data={data} />
        </>
      )}
      {isError && <ErrorState type="dark" />}
    </CaseStudiesContainer>
  );
};

CaseStudiesMainPage.propTypes = {
  isSingleRow: PropTypes.bool,
};

CaseStudiesMainPage.defaultProps = {
  isSingleRow: false,
};

export default CaseStudiesMainPage;
