import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useGetOneJobPositionQuery } from '../../global/service/jobsService';
import Loader from '../../components/Loader';
import { JobPageContainer } from './JobPage.styled';
import BackgroundLinearText from '../../components/BackgroundLinearText';
import Offer from './components/Offer';

const JobPage = () => {
  const { t } = useTranslation();
  const { jobPosition } = useParams();
  const { data, isLoading } = useGetOneJobPositionQuery(jobPosition);

  return (
    <>
      {data && (
        <JobPageContainer>
          <div className="background-dark-title">
            <BackgroundLinearText type="dark">{t('offer.jobDescription')}</BackgroundLinearText>
          </div>
          <Offer />
        </JobPageContainer>
      )}
      {isLoading && <Loader />}
    </>
  );
};

export default JobPage;
