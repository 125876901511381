import apply from '../../../../global/images/career/candidateJourney/apply.png';
import conversation from '../../../../global/images/career/candidateJourney/conversation.png';
import task from '../../../../global/images/career/candidateJourney/task.png';
import interview from '../../../../global/images/career/candidateJourney/interview.png';
import offer from '../../../../global/images/career/candidateJourney/offer.png';

export const candidateJourneyData = [
  {
    id: 1,
    title: 'career.candidate_journey.steps_title.apply',
    image: apply,
    description: 'career.candidate_journey.application_steps.apply',
    key: 'apply',
  },
  {
    id: 2,
    title: 'career.candidate_journey.steps_title.conversation',
    image: conversation,
    description: 'career.candidate_journey.application_steps.conversation',
    key: 'conversation',
  },
  {
    id: 3,
    title: 'career.candidate_journey.steps_title.task',
    image: task,
    description: 'career.candidate_journey.application_steps.task',
    key: 'task',
  },
  {
    id: 4,
    title: 'career.candidate_journey.steps_title.interview',
    image: interview,
    description: 'career.candidate_journey.application_steps.interview',
    key: 'interview',
  },
  {
    id: 5,
    title: 'career.candidate_journey.steps_title.offer',
    image: offer,
    description: 'career.candidate_journey.application_steps.offer',
    key: 'offer',
  },
];

export const heightStrategy = {
  0: '0',
  1: '150px',
  2: '200px',
  3: '400px',
  4: '460px',
};
