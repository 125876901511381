import React from 'react';
import { useSwiper } from 'swiper/react';
import PropTypes from 'prop-types';

const SwiperNavigator = ({ steps, currentStep }) => {
  const swiper = useSwiper();

  return (
    <div className="sliders-container">
      {new Array(steps).fill('').map((_, index) => (
        <div
          key={`slider-button-${String(index)}`}
          className={`sliders-container__button ${
            index === currentStep && 'sliders-container__button_active'
          }`}
          onClick={() => swiper.slideTo(index)}
        />
      ))}
    </div>
  );
};

export default SwiperNavigator;

SwiperNavigator.propTypes = {
  steps: PropTypes.number.isRequired,
  currentStep: PropTypes.number.isRequired,
};
