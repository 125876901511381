import styled from 'styled-components';

export const CareerFormContainer = styled('div')`
  .career__form {
    &-fields {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      column-gap: 32px;
      row-gap: 40px;

      @media only screen and (max-width: 1240px) {
        display: flex;
        flex-direction: column;
        gap: 40px;
      }
    }

    &-message {
      padding: 40px 0 40px;
    }

    &-finish {
      display: flex;
      justify-content: space-between;

      @media only screen and (max-width: 904px) {
        flex-direction: column;
        gap: 40px;
      }
    }

    &-send {
      min-width: 253px;
      max-height: 48px;

      @media only screen and (max-width: 904px) {
        width: 100%;
      }
    }

    &-file {
      display: flex;
    }
  }
`;
