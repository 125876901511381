import React from 'react';
import PropTypes from 'prop-types';
import { BackgroundLinearTextContainer } from './BackgroundLinearText.styled';

const BackgroundLinearText = ({ children, type }) => (
  <BackgroundLinearTextContainer>
    <div className={`text ${type}`}>{children}</div>
  </BackgroundLinearTextContainer>
);

BackgroundLinearText.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(['dark', 'light']),
};

BackgroundLinearText.defaultProps = {
  type: 'dark',
};

export default BackgroundLinearText;
