import styled from 'styled-components';
import { mobileWidth } from '../../../../global/styles/Global.styled';

export const TechnologiesWeUseContainer = styled('div')`
  margin: 100px auto;
`;

export const TechnologiesWeUseContentContainer = styled('div')`
  padding: 108px;
  position: relative;
  margin: 0 auto;

  @media only screen and (max-width: ${mobileWidth}) {
    padding: 40px 16px;
  }

  .header-container {
    &__title {
      display: flex;
      justify-content: space-between;
    }
  }
`;
