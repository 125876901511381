import styled from 'styled-components';
import theme from '../../../../global/styles/theme';
import { mobileWidth } from '../../../../global/styles/Global.styled';

export const ContactFormContainer = styled.div`
  background-color: ${theme.colors.primary['100']};
  border-radius: 12px;
  width: 792px;
  margin-top: 74px;

  position: sticky;
  top: 100px;

  @media only screen and (max-width: ${mobileWidth}) {
    width: auto;
    margin-top: 64px;
  }

  .form-container {
    padding: 32px 40px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media only screen and (max-width: ${mobileWidth}) {
      padding: 32px 16px;
    }

    &__grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 32px;

      @media only screen and (max-width: ${mobileWidth}) {
        grid-template-columns: repeat(1, 1fr);
      }
    }

    &__btn-send {
      width: 253px;
      height: 48px;
      margin-top: 8px;
      margin-bottom: 16px;
    }

    label {
      color: ${theme.colors.text['20']};
    }
  }

  .textarea {
    resize: none;
    min-height: 127px;

    @media only screen and (max-width: ${mobileWidth}) {
      min-height: 100px;
    }
  }

  .input {
    color: ${theme.colors.background['30']};

    &-error-content {
      color: ${theme.colors.support.error['70']};
    }
  }

  .input:active,
  .input:focus {
    color: ${theme.colors.background['20']};
  }

  .input-label {
    color: ${theme.colors.background['20']};
    font-family: 'Lato', sans-serif;
    line-height: 23px;
    letter-spacing: 0.036px;
    padding-bottom: 8px;
    padding-left: 0;

    &-light {
      color: ${theme.colors.background['20']};
    }
  }

  .custom-select {
    &__control {
      width: 100%;
    }

    &__menu {
      background-color: ${theme.colors.primary['100']};
    }

    &__single-value {
      color: ${theme.colors.background['0']} !important;
    }

    &__option {
      color: black;

      &--is-focused:not(&--is-selected) {
        color: ${theme.colors.background['80']};
      }
    }
  }

  .filterOption {
    & > div {
      color: ${theme.colors.text['20']};
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.32px;
    }
  }
`;
