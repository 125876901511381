import { useDispatch, useSelector } from 'react-redux';
import { selectors } from '../../containers/InitComponent/reducer';
import * as actions from '../../containers/InitComponent/actions';

const useNotifications = () => {
  const dispatch = useDispatch();
  const notificationsList = useSelector(selectors.notifications);

  const addNotification = ({ type, title, subtitle, noClose }) =>
    dispatch(actions.addApplicationNotification({ type, title, subtitle, noClose }));
  const removeNotification = (id) => dispatch(actions.removeApplicationNotification(id));
  const clearNotifications = () => dispatch(actions.clearApplicationNotification());

  return { addNotification, removeNotification, clearNotifications, notificationsList };
};

export default useNotifications;
