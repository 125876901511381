import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { HistoryContainer, HistoryContentContainer } from './History.styled';
import BackgroundLinearText from '../../../../components/BackgroundLinearText';
import Slider from './components/Slider';

import { historyData } from './constants';

const History = () => {
  const { t } = useTranslation();

  const historyRef = useRef(null);
  const [selectedYear, setSelectedYear] = useState(0);

  const changeYearHandle = (yearNumber) => {
    if (yearNumber !== selectedYear) {
      historyRef.current.classList.remove('history__animated');
      setTimeout(() => {
        historyRef.current.classList.add('history__animated');
        setSelectedYear(yearNumber);
      }, 0);
    }
  };

  return (
    <HistoryContainer>
      <div className="background-dark-title">
        <BackgroundLinearText type="dark">{t('history.title')}</BackgroundLinearText>
      </div>
      <div className="content responsive-width">
        <div className="title title_light">{t('history.title')}</div>
        <div className="subtitle subtitle_light">{t('history.subtitle')}</div>
      </div>

      <HistoryContentContainer>
        <div
          ref={historyRef}
          className="history__top  history__animated light-container-responsive"
        >
          <div className="history__top-year">{historyData[selectedYear].year}</div>
          <div className="history__top-text">{t(`${historyData[selectedYear].text}`)}</div>
        </div>

        <div className="history__panel responsive-width">
          {historyData.map((year, index) => (
            <div className="history__panel-container" key={year.id}>
              <div
                className={
                  selectedYear === index
                    ? 'history__panel-year active__year'
                    : 'history__panel-year'
                }
                onClick={() => changeYearHandle(year.id)}
              >
                {year.year}
              </div>
              <div className="history__range">
                <div
                  className={
                    selectedYear === index
                      ? 'history__range-line active__line'
                      : 'history__range-line'
                  }
                />
              </div>
            </div>
          ))}
        </div>
      </HistoryContentContainer>
      <Slider historyData={historyData} />
    </HistoryContainer>
  );
};

export default History;
