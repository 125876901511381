import styled from 'styled-components';
import theme from '../../../../../../global/styles/theme';
import { mobileWidth } from '../../../../../../global/styles/Global.styled';

export const QuestionContainer = styled.div`
  .question {
    &__title {
      display: flex;
      justify-content: space-between;
      gap: 28px;
    }

    &__hidden-content {
      @media only screen and (max-width: ${mobileWidth}) {
        display: none;
      }
    }

    &__brief {
      font-family: Manrope, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 800;
      line-height: 150%;
      color: ${theme.colors.text['50']};
      margin-bottom: 24px;
    }

    &__optional {
      color: ${theme.colors.backgroundDark['60']};
      padding-left: 8px;
    }

    &__button-expand {
      display: none;
      cursor: pointer;

      @media only screen and (max-width: ${mobileWidth}) {
        display: block;
        color: ${theme.colors.primary['30']};
        font-size: 40px;
      }
    }
  }

  .checkbox-container {
    margin-top: 24.5px;
  }

  .checkbox-container {
    &__option {
      display: flex;
      margin-bottom: 21px;
      font-family: 'Lato', sans-serif;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.02em;
    }

    &__columns-width-one {
      grid-column: span 1;
    }

    &__columns-width-two {
      grid-column: span 2;
    }

    &__technology-title {
      text-transform: uppercase;
      font-weight: 700;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.02em;
      color: ${theme.colors.text['60']};
      padding-bottom: 20px;
    }

    &__option-name {
      width: 153px;
      padding-right: 30px;
    }
  }

  .two-columns-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;
