import React from 'react';
import { ReactComponent as Linkedin } from '../../global/images/socials/linkedin.svg';
import { ReactComponent as Behance } from '../../global/images/socials/behance.svg';
import { ReactComponent as Dou } from '../../global/images/socials/dou.svg';
import { ReactComponent as Clutch } from '../../global/images/socials/clutch.svg';
import { ReactComponent as Dribble } from '../../global/images/socials/dribbble.svg';
import { ReactComponent as Instagram } from '../../global/images/socials/instagram.svg';

export const routesMenu = [
  {
    path: '/about',
    text: 'footer.routes.about_us',
  },
  {
    path: '/services',
    text: 'footer.routes.services',
  },
  {
    path: '/case-studies',
    text: 'footer.routes.projects',
  },
  {
    path: '/brief',
    text: 'footer.routes.brief',
  },
  {
    path: '/career',
    text: 'footer.routes.career',
  },
];

export const routesSocials = [
  {
    path: process.env.REACT_APP_LINKEDIN,
    icon: <Linkedin />,
    alt: 'Linkedin logo',
  },

  {
    path: process.env.REACT_APP_INSTAGRAM,
    icon: <Instagram />,
    alt: 'Instagram logo',
  },
  {
    path: process.env.REACT_APP_BEHANCE,
    icon: <Behance />,
    alt: 'Behance logo',
  },
  {
    path: process.env.REACT_APP_DOU,
    icon: <Dou />,
    alt: 'DOU logo',
  },
  {
    path: process.env.REACT_APP_CLUTCH,
    icon: <Clutch />,
    alt: 'Clutch logo',
  },
  {
    path: process.env.REACT_APP_DRIBBBLE,
    icon: <Dribble />,
    alt: 'Dribbble logo',
  },
];
