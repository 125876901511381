import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import RangeSlider from '../../../../../../components/RangeSlider';
import QuestionTemplate from './QuestionTemplate';

const QuestionDuration = ({ question, isOptional, formikFieldName }) => {
  const { setFieldValue } = useFormikContext();

  return (
    <QuestionTemplate question={question} isOptional={isOptional} name={formikFieldName}>
      <div className="checkbox-container">
        <RangeSlider
          onChange={(duration) => {
            setFieldValue(formikFieldName, duration);
          }}
          fieldName="duration"
        />
      </div>
    </QuestionTemplate>
  );
};

export default QuestionDuration;

QuestionDuration.propTypes = {
  question: PropTypes.string.isRequired,
  isOptional: PropTypes.bool.isRequired,
  formikFieldName: PropTypes.string.isRequired,
};
