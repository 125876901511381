import styled from 'styled-components';
import theme from '../../../../global/styles/theme';
import { mobileWidth } from '../../../../global/styles/Global.styled';

export const ObjectivesAndChallengesContainer = styled('div')`
  position: relative;
  overflow: hidden;
  padding: 120px 0 125px;
`;

export const ContentContainer = styled('div')`
  margin: 0 auto;
  padding-top: 109px;

  @media only screen and (max-width: ${mobileWidth}) {
    padding-top: 38px;
  }

  .title {
    @media only screen and (min-width: ${mobileWidth}) {
      padding-left: 50px;
    }
  }

  .content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    z-index: 1;
    padding-top: 65px;

    @media only screen and (max-width: ${mobileWidth}) {
      display: flex;
    }

    &__left {
      border-radius: 8px 0 0 8px;
      padding: 54px;
      background-color: ${theme.colors.background['95']};
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 90%;
        height: fit-content;
        max-height: 720px;
        margin: 0 auto;
      }
    }

    &__right {
      border-radius: 0 8px 8px 0;
      padding: 103px 54px;
      background: ${theme.colors.gradient['4']};
      display: flex;
      flex-direction: column;
      gap: 48px;

      img {
        width: 90%;
        height: fit-content;
        max-height: 720px;
        margin: 0 auto;
      }

      @media only screen and (max-width: ${mobileWidth}) {
        border-radius: 8px;
        padding: 24px 16px;
      }
    }

    &__challenges,
    &__objectives {
      display: flex;
      flex-direction: column;
      gap: 12px;

      &-title {
        font-family: Manrope, sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 800;
        line-height: 132%;
        letter-spacing: 0.96px;
        color: ${theme.colors.primary['100']};
      }

      &-description {
        font-size: 19px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: 0.38px;
        color: ${theme.colors.text['95']};
      }
    }
  }
`;
