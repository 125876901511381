import styled from 'styled-components';
import theme from '../../../../global/styles/theme';

export const LanguagesContainer = styled.div`
  display: flex;
  color: ${theme.colors.backgroundDark['95']};

  span {
    cursor: pointer;
    font-family: Manrope, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    letter-spacing: 0.32px;

    &:first-child {
      padding-right: 3px;
    }

    &:nth-child(2) {
      padding-left: 3px;
    }
  }

  .active {
    color: ${theme.colors.primary['40']};
  }
`;
