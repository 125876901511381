import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { CgClose } from 'react-icons/cg';
import { AnimatePresence, motion } from 'framer-motion';
import * as Styled from './Notification.styled';
import successIcon from '../../../../global/images/common/success.svg';
import errorIcon from '../../../../global/images/common/error.svg';
import warningIcon from '../../../../global/images/common/warning.svg';

const Notification = ({ id, type, title, subtitle, onClose, noClose }) => {
  useEffect(() => {
    const timeout = setTimeout(() => {
      onClose(id);
    }, 7000);
    return () => clearTimeout(timeout);
  });

  const notificationIcon = {
    success: <img src={successIcon} alt="success" />,
    error: <img src={errorIcon} alt="error" />,
    warning: <img src={warningIcon} alt="warning" />,
  };

  return (
    <AnimatePresence>
      <motion.div
        key={id}
        layout
        initial={{ opacity: 0, y: 50, scale: 0.3 }}
        animate={{ opacity: 1, y: 0, scale: 1 }}
        exit={{ opacity: 0, y: 20, scale: 0.5 }}
      >
        <Styled.NotificationContainer className={type}>
          {notificationIcon[type]}
          <div className="content">
            <div className="content__title">{title}</div>
            {subtitle && <div className="content__subtitle">{subtitle}</div>}
          </div>
          {!noClose && <CgClose className="close" onClick={() => onClose(id)} />}
        </Styled.NotificationContainer>
      </motion.div>
    </AnimatePresence>
  );
};

Notification.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['error', 'success', 'warning']).isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  noClose: PropTypes.bool,
};

Notification.defaultProps = {
  subtitle: '',
  noClose: false,
};

export default Notification;
