import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ClickAwayListener } from '@mui/material';
import { CgClose } from 'react-icons/cg';
import { ModalWindowContainer, ModalWindowBackdrop } from './ModalWindow.styled';

const ModalWindow = ({ children, onClose, close, height, width, background }) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'visible';
    };
  }, []);

  return (
    <>
      <ModalWindowBackdrop />
      <ClickAwayListener onClickAway={onClose}>
        <ModalWindowContainer height={height} width={width} background={background}>
          <div className="modal-header">
            {close && (
              <div className="modal-header__icon-container" onClick={onClose}>
                <CgClose className="modal-header__close" />
              </div>
            )}
          </div>
          {children}
        </ModalWindowContainer>
      </ClickAwayListener>
    </>
  );
};

ModalWindow.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  close: PropTypes.bool,
  height: PropTypes.string,
  width: PropTypes.string,
  background: PropTypes.string,
};

ModalWindow.defaultProps = {
  close: true,
  height: '',
  width: '',
  background: '',
};

export default ModalWindow;
