import styled from 'styled-components';
import { mobileWidth } from '../../global/styles/Global.styled';

export const InputContainer = styled('div')`
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: ${mobileWidth}) {
    width: 99%;
    margin: 0 auto;
  }
`;
