import styled from 'styled-components';
import { mobileWidth } from '../../global/styles/Global.styled';

export const TextareaContainer = styled('div')`
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: ${mobileWidth}) {
    width: 99%;
  }

  .textarea {
    max-width: 100%;
    width: 100%;
    min-height: 127px;
    min-width: 99%;
    vertical-align: top;
    resize: vertical;

    @media only screen and (max-width: ${mobileWidth}) {
      width: 99%;
      margin: 0 auto;
    }
  }
`;
