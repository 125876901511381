import styled from 'styled-components';
import theme from '../../../../global/styles/theme';
import { mobileWidth } from '../../../../global/styles/Global.styled';

export const StartCooperationContainer = styled('div')`
  background-color: ${theme.colors.background['30']};
  width: 100%;
  padding: 60px 0;

  .container {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    align-items: center;

    grid-column: 1/19;

    @media only screen and (max-width: ${mobileWidth}) {
      grid-column: 1/5;
      flex-direction: column;
    }

    &__title {
      font-family: Manrope, sans-serif;
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      letter-spacing: 0.56px;
      color: ${theme.colors.text['95']};
      max-width: 715px;
    }

    .btn,
    a {
      max-height: 52px;
      width: 100%;

      @media only screen and (min-width: 905px) {
        max-width: 250px;
      }
    }
  }
`;
