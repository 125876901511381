import developmentIcon from '../../../global/images/jobOpeningIcons/development.svg';
import designIcon from '../../../global/images/jobOpeningIcons/design.svg';
import QAIcon from '../../../global/images/jobOpeningIcons/qa.svg';
import HRIcon from '../../../global/images/jobOpeningIcons/hr.svg';
import managementIcon from '../../../global/images/jobOpeningIcons/management.svg';
import SalesIcon from '../../../global/images/jobOpeningIcons/sales.svg';

export const departmentsIconsData = {
  Development: developmentIcon,
  Design: designIcon,
  Management: managementIcon,
  QA: QAIcon,
  Sales: SalesIcon,
  HR: HRIcon,
};
