import styled from 'styled-components';
import theme from '../../../../global/styles/theme';
import { mobileWidth } from '../../../../global/styles/Global.styled';

export const SuccessSubmitContainer = styled('div')`
  grid-column: 1/12;

  @media only screen and (max-width: ${mobileWidth}) {
    grid-column: 1/5;
  }

  .success {
    &__title {
      font-family: Manrope, sans-serif;
      font-size: 64px;
      font-style: normal;
      font-weight: 800;
      line-height: 132%; /* 84.48px */
      letter-spacing: 1.28px;

      color: ${theme.colors.text['95']};
      position: relative;
      z-index: 10;

      @media only screen and (max-width: ${mobileWidth}) {
        font-size: 44px;
      }
    }

    &__description {
      color: ${theme.colors.text['90']};
      padding: 16px 0 40px 0;
      font-size: 19px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      letter-spacing: 0.38px;

      &-company {
        text-decoration: underline;
        color: ${theme.colors.primary['30']};
        padding-left: 4px;
        cursor: pointer;
      }
    }

    &__submit-again {
      button {
        padding: 0 !important;
      }
    }
  }
`;
