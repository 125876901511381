import styled from 'styled-components';
import theme from '../../../../global/styles/theme';
import night from '../../../../global/images/planets/night.jpg';
import { mobileWidth } from '../../../../global/styles/Global.styled';

const mobileMenu = '750px';

export const MainContainer = styled.div`
  background: linear-gradient(180deg, rgba(0, 5, 14, 0) 60%, ${theme.colors.background['20']} 100%),
    url(${night});
  background-size: cover;
  width: 100%;
  height: 750px;
  overflow: hidden;
  position: relative;

  @media only screen and (max-width: ${mobileMenu}) {
    height: 850px;
  }

  .content {
    margin: 0 auto;

    @media only screen and (max-width: ${mobileMenu}) {
      height: 56px;
    }

    &__planets {
      width: 400px;
      position: absolute;
      float: right;
      right: 130px;
      top: 760px;

      @media only screen and (min-width: 1920px) {
        right: calc((100vw - 1596px) / 2 + 100px);
      }

      @media only screen and (min-width: 1440px) and (max-width: 1919px) {
        right: calc((100vw - 1332px) / 2 + 70px);
      }

      @media only screen and (min-width: 1240px) and (max-width: 1439px) {
        right: calc((100vw - 1132px) / 2 + 10px);
      }

      @media only screen and (min-width: 905px) and (max-width: 1239px) {
        right: calc((100vw - 908px) / 2 + 10px);
      }

      @media only screen and (max-width: ${mobileWidth}) {
        right: calc((100vw - 375px) / 2);
        width: 250px;
      }
    }

    &__first {
      display: flex;
      flex-direction: column;
      gap: 48px;
      position: relative;
      z-index: 2;

      @media only screen and (max-width: ${mobileMenu}) {
        gap: 40px;
      }

      &-text {
        max-width: 729px;
        color: ${theme.colors.text['95']};
        margin-top: 152px;

        font-family: Manrope, sans-serif;
        font-size: 52px;
        font-style: normal;
        font-weight: 800;
        line-height: 150%;
        letter-spacing: 1.04px;

        @media only screen and (max-width: 1240px) {
          font-size: 46px;
          line-height: 69px;
          margin-top: 64px;
        }

        @media only screen and (max-width: ${mobileMenu}) {
          width: 351px;
        }
        @media only screen and (max-width: 320px) {
          width: 351px;
          font-size: 42px;
        }
      }
      &-btn {
        max-width: 253px;
        width: 100%;
      }

      &-slogan {
        color: ${theme.colors.text['95']};
        font-size: 32px;
        line-height: 60px;
        letter-spacing: 0.02em;
        font-family: Manrope, sans-serif;
        font-weight: 800;
      }
    }
  }
`;
