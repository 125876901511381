import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaqBlockContainer, FaqBlockContentContainer } from './FAQBlock.styled';
import Accordion from '../../../../components/Accordion';
import useAccordion from '../../../../global/hooks/useAccordion';
import BackgroundLinearText from '../../../../components/BackgroundLinearText';
import { getQuestions } from './helpers';

const FaqBlock = () => {
  const { t } = useTranslation();
  const [accordionState, handleChangeAccordionState] = useAccordion(getQuestions());

  return (
    <FaqBlockContainer className="light-container-responsive">
      <BackgroundLinearText type="light">{t('faqBlock.title')}</BackgroundLinearText>
      <FaqBlockContentContainer>
        <div className="header-container">
          <h2 className="title title_dark">{t('faqBlock.title')}</h2>
          <div className="subtitle subtitle_dark">{t('faqBlock.subtitle')}</div>
        </div>
        {getQuestions().map((el) => (
          <Accordion
            key={el.id}
            title={el.title}
            content={el.content}
            id={el.id}
            handleChangeAccordion={handleChangeAccordionState}
            state={accordionState}
          />
        ))}
      </FaqBlockContentContainer>
    </FaqBlockContainer>
  );
};

export default FaqBlock;
