import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FooterContainer } from './Footer.styled';
import { routesMenu, routesSocials } from './routes';
import logo from '../../global/images/roxers-logo.svg';

const Footer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <FooterContainer>
      <div className="main-info-container content">
        <div className="main-info-container__contacts">
          <img alt="logo" src={logo} />
          <div>{t('footer.contacts.contacts')}</div>
          <div>
            <a
              href={`mailto:${t('footer.contacts.email')}`}
              className="main-info-container__contacts-address"
            >
              {' '}
              {t('footer.contacts.email')}
            </a>
            <span className="main-info-container__contacts-address">
              {t('footer.contacts.address')}
            </span>
            <span className="main-info-container__contacts-address">
              {t('footer.contacts.zip')}
            </span>
            <a href={`tel:${t('footer.contacts.phone')}`}>{t('footer.contacts.phone')}</a>
          </div>
        </div>
        <ul className="main-info-container__menu">
          {routesMenu.map((route) => (
            <li key={route.path}>
              <Link to={route.path} className="main-info-container__menu-links">
                {t(route.text)}
              </Link>
            </li>
          ))}
        </ul>
        <div className="main-info-container__socials">
          <div className="main-info-container__socials-follow">{t('footer.socials.follow_us')}</div>
          <div className="main-info-container__socials-logos">
            {routesSocials.map((route) => (
              <Link to={route.path} key={route.alt} target="_blank">
                {route.icon}
              </Link>
            ))}
          </div>
        </div>
      </div>
      <div className="policy-container content">
        <div>
          <span onClick={() => navigate('privacy-policy')}>
            {t('footer.policy.privacy_policy')}
          </span>
          <span onClick={() => navigate('terms-of-use')}>{t('footer.policy.terms_of_use')}</span>
        </div>
        <div>{t('footer.policy.copyrights')}</div>
      </div>
    </FooterContainer>
  );
};

export default Footer;
