import React from 'react';
import { Routes as Switch, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import NotificationsList from '../../components/NotificationsList';
import { AppContainer } from '../../global/styles/Global.styled';
import MainPage from '../MainPage';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import ServicesPage from '../ServicesPage';
import AboutPage from '../AboutPage';
import ContactUsPage from '../ContactUsPage';
import CaseStudiesPage from '../CaseStudiesPage';
import BriefPage from '../BriefPage';
import CaseStudy from '../CaseStudy';
import ErrorPage from '../ErrorPage';
import CareerPage from '../CareerPage';
import PrivacyPolicyPage from '../PrivacyPolicyPage';
import ScrollToTop from '../../components/ScrollToTop';
import JobsPage from '../JobsPage';
import JobPage from '../JobPage';
import TermsOfUsePage from '../TermsOfUsePage';

const InitComponent = () => (
  <AppContainer>
    <Header />
    <HelmetProvider>
      <Switch>
        <Route path="/" element={<MainPage />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/contact-us" element={<ContactUsPage />} />
        <Route path="/case-studies" element={<CaseStudiesPage />} />
        <Route path="/case-studies/:caseName" element={<CaseStudy />} />
        <Route path="/brief" element={<BriefPage />} />
        <Route path="/career" element={<CareerPage />} />
        <Route path="/career/jobs" element={<JobsPage />} />
        <Route path="/career/jobs/:jobPosition" element={<JobPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="/terms-of-use" element={<TermsOfUsePage />} />
        <Route path="*" element={<ErrorPage />} />
      </Switch>
    </HelmetProvider>
    <NotificationsList />
    <Footer />
    <ScrollToTop />
  </AppContainer>
);

export default InitComponent;
