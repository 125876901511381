import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { axiosBaseQuery } from './axiosBaseQuery';
import { API_BASE_URL } from './constants';

export const apiService = createApi({
  reducerPath: 'apiService',
  baseQuery: axiosBaseQuery({
    baseUrl: API_BASE_URL,
  }),
  endpoints: () => ({}),
  refetchOnReconnect: true,
  refetchOnFocus: true,
  overrideExisting: false,
});
