import { useState } from 'react';

export const useExpand = () => {
  const [opened, setOpened] = useState(false);

  return [
    opened,
    () => {
      setOpened((prev) => !prev);
    },
  ];
};
