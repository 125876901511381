import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ContactsContainer, ContactUsContainer, ContentContainer } from './ContactUsPage.styled';
import BackgroundLinearText from '../../components/BackgroundLinearText';
import addressIcon from '../../global/images/icons/address.svg';
import phoneIcon from '../../global/images/icons/phone.svg';
import emailIcon from '../../global/images/icons/email.svg';
import { addresses, contacts } from './constants';
import ContactForm from './components/ContactForm';
import { useSendFeedbackMutation } from '../../global/service/feedBacksService';
import ThankYouPage from './components/ThankYouPage/ThankYouPage';
import useNotifications from '../../global/hooks/useNotifications';
import { formattedContactData } from '../MainPage/helpers';
import Loader from '../../components/Loader';

const ContactUsPage = () => {
  const { t } = useTranslation();
  const [sendFeedback, state] = useSendFeedbackMutation();
  const [isThanksPageShowed, setIsThanksPageShowed] = useState(false);
  const { addNotification } = useNotifications();

  const handleSubmitForm = async (values) => {
    const onSuccess = () => {
      window.scrollTo(0, 0);
      setIsThanksPageShowed(true);
    };
    const onError = () => {
      addNotification({
        type: 'error',
        title: t('common.notifications.error.title'),
        subtitle: t('common.notifications.error.subtitle'),
      });
    };
    sendFeedback({ data: await formattedContactData(values), onSuccess, onError });
  };

  if (isThanksPageShowed) {
    return <ThankYouPage setIsThanksPageShowed={setIsThanksPageShowed} />;
  }

  return (
    <>
      <ContactUsContainer>
        <div className="background-dark-title">
          <BackgroundLinearText type="dark">{t('contactUsPage.title')}</BackgroundLinearText>
        </div>
        <div className="content responsive-width">
          <h2 className="title content__title"> {t('contactUsPage.title')}</h2>
          <div className="content__subtitle">{t('contactUsPage.subtitle')}</div>
          <div className="content__blur" />
        </div>
        <ContentContainer className="responsive-width">
          <ContactsContainer>
            <div className="contact">
              {addresses.map((address) => (
                <div key={address.address}>
                  <div className="contact__title">{t(`${address.title}`)}</div>
                  {address.address.map((el) => (
                    <div className="contact__address-container">
                      <img src={addressIcon} alt="address icon" className="contact__icon" />
                      <div className="contact__text">{t(`${el}`)}</div>
                    </div>
                  ))}
                  {address.phone && (
                    <div className="contact__address-container">
                      <img src={phoneIcon} alt="phone icon" />
                      <a href={`tel:${address.phone}`} className="contact__text">
                        {t(`${address.phone}`)}
                      </a>
                    </div>
                  )}
                </div>
              ))}
              {contacts.map((contact) => (
                <div key={contact.email}>
                  <div className="contact__title">{t(`${contact.title}`)}</div>
                  <div className="contact__address-container">
                    <img src={emailIcon} alt="address icon" />
                    <a className="contact__text" href={`mailto:${contact.email}`}>
                      {contact.email}
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </ContactsContainer>
          <ContactForm handleSubmitForm={handleSubmitForm} />
        </ContentContainer>
      </ContactUsContainer>
      {state.isLoading && <Loader />}
    </>
  );
};

export default ContactUsPage;
