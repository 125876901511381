import { toBase64 } from '../../global/helpers';

export const formattedContactData = async (values) => {
  const file = values.file && (await toBase64(values.file));

  delete values.agreement;

  return {
    ...values,
    file: file || null,
    industry: values.industry || null,
    link: values.link || null,
    message: values.message || null,
    development_stage: null,
    consulting_specialists: null,
    software_solutions: null,
    technologies: null,
    specialists: null,
    duration_days: null,
  };
};
