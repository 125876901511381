import React, { useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CaseStudiesContainer } from './CaseStudies.styled';
import BackgroundLinearText from '../../../../components/BackgroundLinearText';
import CaseStudiesCard from '../CaseStudiesCard';
import { useGetCaseStudiesQuery } from '../../../../global/service/caseStudiesService';
import Loader from '../../../../components/Loader';
import ErrorState from '../../../../components/ErrorState';
import { formattedCaseStudyInfo } from '../../../../global/helpers';
import i18next from '../../../../global/translations/i18next';
import { useIsFirstRender } from '../../../../global/hooks/useIsFirtsRender';

const CaseStudies = () => {
  const { t } = useTranslation();
  const { isLoading, data, isError, refetch } = useGetCaseStudiesQuery();

  const isFirstRender = useIsFirstRender();
  useEffect(() => {
    if (!isFirstRender) refetch();
  }, [i18next.language]);

  const { firstColumn, secondColumn } = useMemo(() => {
    if (!data)
      return {
        firstColumn: [],
        secondColumn: [],
      };

    return {
      firstColumn: data.slice(0, Math.floor(data.length / 2)),
      secondColumn: data.slice(Math.floor(data.length / 2)),
    };
  }, [data]);

  return (
    <CaseStudiesContainer>
      <div className="background-dark-title">
        <BackgroundLinearText type="dark">{t('caseStudies.title')}</BackgroundLinearText>
      </div>
      <div className="header__container responsive-width">
        <div className="header__container-title"> {t('caseStudiesPage.mainTitle')}</div>
        <div className="header__container-subtitle">{t('caseStudiesPage.mainSubtitle')}</div>
      </div>

      {isLoading && <Loader />}
      {isError && <ErrorState type="dark" />}
      {data && !isError && (
        <div className="cards">
          <div className="cards__container">
            <div>
              <div className="cards__dummy" />
              {firstColumn.map((project) => (
                <CaseStudiesCard
                  key={project.id}
                  projectName={project.project_name.value}
                  cardTitle={project.name}
                  imgUrl={project.images[0].url}
                  technologies={formattedCaseStudyInfo(project)}
                  projectSlug={project.slug}
                />
              ))}
            </div>
            <div>
              {secondColumn.map((project) => (
                <CaseStudiesCard
                  key={project.id}
                  projectName={project.project_name.value}
                  cardTitle={project.name}
                  imgUrl={project.images[0].url}
                  technologies={formattedCaseStudyInfo(project)}
                  projectSlug={project.slug}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </CaseStudiesContainer>
  );
};

export default CaseStudies;
