import React from 'react';
import PropTypes from 'prop-types';
import CaseStudiesContent from '../CaseStudiesContent';
import { CaseStudiesCardContainer } from './CaseStudiesCard.styled';

const CaseStudiesCard = ({ type, cartTitle, technologies, imgUrl, projectSlug }) => (
  <CaseStudiesCardContainer className={`case-study-view--${type}`}>
    <div className="case-image-container">
      <img className="case-image-container__img" src={imgUrl} alt={cartTitle} />
    </div>
    <div>
      <CaseStudiesContent
        cartTitle={cartTitle}
        technologiesArr={technologies}
        projectSlug={projectSlug}
      />
    </div>
  </CaseStudiesCardContainer>
);

export default CaseStudiesCard;

CaseStudiesCard.propTypes = {
  type: PropTypes.string.isRequired,
  cartTitle: PropTypes.string.isRequired,
  technologies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  imgUrl: PropTypes.string.isRequired,
  projectSlug: PropTypes.string.isRequired,
};
