import styled from 'styled-components';
import theme from '../../../../global/styles/theme';
import { mobileWidth } from '../../../../global/styles/Global.styled';

export const ApplyForJobContainer = styled.div`
  background-color: ${theme.colors.background['20']};
  padding-top: 60px;
  margin: 0 auto;
  padding-bottom: 110px;

  .content {
    position: relative;
    padding-top: 74px;
    padding-left: 108px;

    @media only screen and (min-width: 1980px) {
      margin: 0 auto;
    }

    @media only screen and (max-width: ${mobileWidth}) {
      padding-left: 16px;
      padding-right: 16px;
      margin: 0 auto;
    }

    @media only screen and (max-width: 400px) {
      margin: 0;
    }

    &__title {
      color: ${theme.colors.text['90']};
      font-family: Manrope, sans-serif;
      font-weight: 800;
      font-size: 64px;
      line-height: 132%;
      letter-spacing: 0.02em;
    }

    &__subtitle {
      font-family: Manrope, sans-serif;
      font-size: 34px;
      font-style: normal;
      font-weight: 800;
      line-height: 140%;
      letter-spacing: 0.68px;

      color: ${theme.colors.backgroundDark['90']};
      padding-bottom: 12px;
      padding-top: 74px;

      @media only screen and (max-width: ${mobileWidth}) {
        padding-bottom: 12px;
      }
    }

    &__description {
      font-size: 19px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      letter-spacing: 0.38px;
      color: ${theme.colors.text['90']};
      width: 801px;

      @media only screen and (max-width: ${mobileWidth}) {
        max-width: 343px;
        padding-right: 16px;
      }
    }

    &__blur {
      position: absolute;
      top: -70px;
      right: 0;
      width: 300px;
      height: 300px;
      background: ${theme.colors.gradient['5']};
      border-radius: 50%;
      filter: blur(90px);

      @media only screen and (max-width: ${mobileWidth}) {
        width: 200px;
        height: 200px;
      }
    }

    &__blur-right {
      position: absolute;
      right: -231px;
      top: 382px;
      width: 140px;
      height: 300px;
      background: ${theme.colors.gradient['5']};
      border-radius: 26%;
      filter: blur(90px);
    }
  }
`;

export const CareerFormContainer = styled.div`
  width: 792px;

  @media only screen and (max-width: ${mobileWidth}) {
    width: 100%;
    padding-right: 16px;
  }

  @media only screen and (min-width: 1980px) {
    width: 50%;
    margin: 0 auto;
  }
`;
