import styled from 'styled-components';
import {
  LinearGradientBlockTemplate,
  TestimonialsMainContainerTemplate,
} from '../../../MainPage/components/Testimonials/Testimonials.styled';
import theme from '../../../../global/styles/theme';

export const ClientTestimonialsMainContainer = styled(TestimonialsMainContainerTemplate).attrs({
  maxWidth: '905px',
  contentPaddings: '60px 85px 79px 107px',
})`
  background-color: ${theme.colors.background['10']};
`;

export const ClientLinearGradientBlock = styled(LinearGradientBlockTemplate).attrs({
  maxWidth: '905px',
  gradientTextPaddings: '157px 45px 0 73px',
})``;
