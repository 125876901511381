import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useFormikContext, isEmptyArray, isObject } from 'formik';
import { scroller } from 'react-scroll';
import { SummaryContainer } from './Summary.styled';
import SummaryItem from './components/SummaryItem';
import { INITIAL_VALUES } from '../../constants';
import { useGetBriefContentQuery } from '../../../../global/service/briefService';

const Summary = ({ setSubmitFormShowed }) => {
  const { t } = useTranslation();
  const formik = useFormikContext();

  const briefContent = useGetBriefContentQuery();

  const emptyBriefForm =
    !formik.values.industry &&
    !formik.values.development_stage &&
    !formik.values.consultation.length &&
    !formik.values.software_solutions.length &&
    !formik.values.technologies.length &&
    !Object.values(formik.values.specialists).filter((value) => value).length &&
    !formik.values.duration;

  const summaryItemData = {
    industry: briefContent.data?.industries,
    development_stage: briefContent.data?.developmentProcess,
    consultation: briefContent?.data?.specialist,
    software_solutions: briefContent?.data?.softwareSolutions,
    technologies: briefContent.data?.techStack,
    specialists: briefContent?.data?.specialist,
  };

  const resetForm = () => {
    scroller.scrollTo('scroll', {
      spy: true,
      duration: 500,
      smooth: true,
      containerId: 'industry',
      offset: -150,
    });

    formik.resetForm({
      values: {
        ...INITIAL_VALUES,
        consultation: [],
        software_solutions: [],
        technologies: [],
        specialists: {},
      },
    });
  };

  const content = (
    <div className="content">
      <div className="content__items">
        {Object.entries(formik.values)
          .slice(4)
          .map(([key, value]) => (
            <SummaryItem
              isShowed={
                !!(
                  (value && !isEmptyArray(value) && !isObject(value)) ||
                  Object.values(value || {}).filter((item) => item).length
                )
              }
              key={key}
              title={t(`brief.summary.${key}Title`)}
              valueKey={key}
              summaryItemData={summaryItemData}
            />
          ))}
      </div>
      <button
        type="button"
        className="btn btn_primary_contained content__items__request-btn"
        onClick={() => setSubmitFormShowed(true)}
      >
        {t('summaryBriefBlock.requestForProposal')}
      </button>
    </div>
  );

  return (
    <SummaryContainer>
      <div className="header">
        <div className="header__title">{t('summaryBriefBlock.title')}</div>
        <div className="header__clear" onClick={resetForm}>
          {t('summaryBriefBlock.clearForm')}
        </div>
      </div>
      {emptyBriefForm && (
        <div className="content-empty">
          {' '}
          <div className="content-empty__info"> {t('summaryBriefBlock.emptySummary')}</div>
        </div>
      )}
      {!emptyBriefForm && content}
    </SummaryContainer>
  );
};

Summary.propTypes = {
  setSubmitFormShowed: PropTypes.func.isRequired,
};

export default Summary;
