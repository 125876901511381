import styled from 'styled-components';
import theme from '../../../../global/styles/theme';
import { mobileWidth } from '../../../../global/styles/Global.styled';

export const ContactUsContainer = styled('div')`
  .content {
    grid-column: 1/19;
    margin: 124px 0 115px;

    @media only screen and (max-width: ${mobileWidth}) {
      grid-column: 1/5;
      margin: 40px 0 100px;
    }

    &__header {
      margin-bottom: 16px;
    }
  }

  .contact-you {
    line-height: 150%;
    letter-spacing: 0.02em;
    color: ${theme.colors.text['90']};

    @media only screen and (max-width: ${mobileWidth}) {
      margin-bottom: 40px;
    }

    span {
      cursor: pointer;
      text-decoration: underline;
      margin-left: 4px;
    }
  }
`;
