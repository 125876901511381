import styled from 'styled-components';
import theme from '../../../../global/styles/theme';
import { mobileWidth } from '../../../../global/styles/Global.styled';

export const AboutClientContainer = styled('div')`
  grid-column: 1/19;
  padding-top: 90px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 100px;

  @media only screen and (max-width: ${mobileWidth}) {
    grid-column: 1/5;
    padding-bottom: 0;
  }

  @media only screen and (min-width: 1920px) {
    gap: 250px;
  }

  @media only screen and (max-width: 1439px) {
    flex-direction: column;
    gap: 0;
  }

  .content {
    &__title {
      font-family: Manrope, sans-serif;
      font-size: 46px;
      font-style: normal;
      font-weight: 800;
      line-height: 150%;
      letter-spacing: 0.92px;

      color: ${theme.colors.text['95']};
      margin-bottom: 13px;
    }

    &__summary {
      font-size: 19px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      letter-spacing: 0.38px;
      color: ${theme.colors.text['90']};
      width: 446px;

      @media only screen and (max-width: 1439px) {
        padding-bottom: 25px;
        width: 343px;
      }
    }
  }

  .client {
    @media only screen and (min-width: 1439px) {
      padding-top: 83px;
    }

    &__title {
      font-size: 21px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      letter-spacing: 0.42px;
      color: ${theme.colors.text['95']};
      width: 125px;
      display: inline-block;
      margin-bottom: 17px;
    }

    &__name {
      font-size: 19px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      letter-spacing: 0.38px;
      padding-left: 12px;
      color: ${theme.colors.text['95']};
    }
  }
`;
