import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { BsArrowRight } from 'react-icons/bs';
import { OpenJobsContainer, VacanciesContainer } from './OpenJobs.styled';
import BackgroundLinearText from '../../../../components/BackgroundLinearText';
import JobsMobile from '../JobsMobile';

const OpenJobs = ({ data }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [activeVacancy, setActiveVacancy] = useState(0);

  return (
    <OpenJobsContainer>
      <div className="background-dark-title">
        <BackgroundLinearText type="dark">{t('jobs_page.title')}</BackgroundLinearText>
      </div>
      <div className="content responsive-width">
        <h2 className="title content__title"> {t('jobs_page.title')}</h2>
      </div>
      <VacanciesContainer>
        <div className="vacancies__list">
          {data.map((department, index) => (
            <div
              key={department.name}
              className={
                index === activeVacancy ? 'vacancies__list_item active' : 'vacancies__list_item'
              }
              onClick={() => {
                if (department.vacancies.length > 0) {
                  setActiveVacancy(index);
                }
              }}
            >
              <div className="vacancies__list_item-name">
                {department.vacancies.length > 0 ? (
                  <>
                    {department.name}({department.vacancies.length})
                  </>
                ) : (
                  <div className="vacancies__list-notActive">{department.name}</div>
                )}
              </div>
              {index === activeVacancy ? (
                <button className="vacancies__list_btn" type="button">
                  <BsArrowRight />
                </button>
              ) : (
                ''
              )}
            </div>
          ))}
        </div>
        <div>
          <div className="vacancies__container">
            {data[activeVacancy].vacancies.map((vacancy) => (
              <div className="vacancies__preview" key={vacancy.slug}>
                <div>
                  <div className="vacancies__preview__title">{vacancy.name.value}</div>
                  <div className="vacancies__preview__description">{vacancy.work_type.value}</div>
                  <div className="vacancies__preview__description">{data[activeVacancy].name}</div>
                </div>
                <div className="vacancies__preview__apply">
                  <div className="vacancies__preview__apply-text">{t('jobs_page.apply')}</div>
                  <button
                    className="vacancies__list_btn"
                    type="button"
                    onClick={() => navigate(`/career/jobs/${vacancy.slug}`)}
                  >
                    <BsArrowRight />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="vacancies__blur" />
      </VacanciesContainer>
      <JobsMobile data={data} />
    </OpenJobsContainer>
  );
};

export default OpenJobs;

OpenJobs.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string.isRequired,
      vacancies: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          slug: PropTypes.string,
          name: PropTypes.shape({
            value: PropTypes.string.isRequired,
          }),
          work_type: PropTypes.shape({
            value: PropTypes.string,
          }).isRequired,
        }),
      ).isRequired,
    }),
  ).isRequired,
};
