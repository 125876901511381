import styled from 'styled-components';
import theme from '../../../../global/styles/theme';
import { mobileWidth } from '../../../../global/styles/Global.styled';

export const SummaryContainer = styled('div')`
  grid-column: 13/19;
  position: sticky;
  top: 80px;
  bottom: 15px;
  align-self: start;
  height: fit-content;

  @media only screen and (max-width: ${mobileWidth}) {
    position: static;
    grid-column: 1/5;
    margin-top: 30px;
  }

  .header {
    background: ${theme.colors.gradient['4']};
    padding: 48px 32px 25px 39px;
    display: flex;
    justify-content: space-between;
    border-radius: 12px 12px 0;

    &__title,
    &__clear {
      font-family: Manrope, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 800;
      line-height: 150%;
      letter-spacing: 0.32px;
    }

    &__title {
      font-family: Manrope, sans-serif;
      font-weight: 600;
      color: ${theme.colors.primary['100']};
    }

    &__clear {
      font-family: Manrope, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 800;
      line-height: 150%;
      letter-spacing: 0.32px;
      text-decoration: underline;
      color: ${theme.colors.text['90']};
      cursor: pointer;
    }
  }

  .content-empty {
    border-radius: 0 0 12px 12px;
    padding: 45px 16px;
    background-color: ${theme.colors.background['95']};
    display: flex;
    justify-content: center;
    align-items: center;

    &__info {
      font-family: Manrope, sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 150%;
      text-align: center;
      letter-spacing: 0.02em;
      color: ${theme.colors.text['25']};
      max-width: 253px;
    }
  }

  .content {
    border-radius: 0 0 12px 12px;
    padding: 20px 16px;
    background-color: ${theme.colors.background['95']};

    &__items {
      display: flex;
      flex-direction: column;
      gap: 12px;
      padding-bottom: 16px;

      &__request-btn {
        width: 100%;
      }
    }
  }
`;
