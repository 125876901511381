import styled from 'styled-components';
import theme from '../../../../global/styles/theme';

export const JoinTeamContainer = styled('div')`
  .join-container {
    position: relative;
    z-index: 20;
    grid-column: 1/19;
    margin: 81px 0 77px 0;

    @media only screen and (max-width: 904px) {
      grid-column: 1/5;
      margin: 40px 0 100px;
    }

    .title {
    }

    &__header-container {
      margin-bottom: 66px;
      display: flex;
      justify-content: space-between;

      @media only screen and (max-width: 904px) {
        flex-direction: column;
        margin-bottom: 10px;
      }

      .btn-mobile {
        margin: 10px 0 0 -15px;
      }
    }

    &__content {
      display: grid;
      grid-template-columns: 0.8fr 1fr;
      gap: 84px;

      @media only screen and (max-width: 1240px) {
        display: flex;
        flex-direction: column;
        gap: 40px;
      }
    }

    &__description {
      display: flex;
      flex-direction: column;
      gap: 35px;

      @media only screen and (max-width: 1240px) {
        gap: 24px;
      }

      &-item {
        font-size: 19px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        letter-spacing: 0.38px;
        color: ${theme.colors.text['95']};
        max-width: 466px;

        @media only screen and (max-width: 1240px) {
          max-width: 100%;
        }
      }
    }

    &__resume {
      font-size: 19px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
      letter-spacing: 0.38px;
      color: ${theme.colors.text['90']};

      span {
        color: ${theme.colors.support.error['70']};
      }
    }
  }
`;
