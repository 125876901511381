import React from 'react';
import { ServicesPageContainer } from './ServicesPage.styled';
import TechnologiesWeUse from './components/TechnologiesWeUse';
import GetInTouch from '../../components/GetInTouch';
import HowCanWeHelp from './components/HowCanWeHelp';
import ProcessBlock from '../../components/ProcessBlock';
import Services from './components/Services';
import CaseStudiesMainPage from '../MainPage/components/CaseStudiesMainPage';
import HelmetHead from '../../components/HelmetHead';

const ServicesPage = () => (
  <>
    <HelmetHead page="servicesPage" />
    <ServicesPageContainer>
      <Services />
      <HowCanWeHelp />
      <ProcessBlock />
      <TechnologiesWeUse />
      <CaseStudiesMainPage isSingleRow />
      <GetInTouch />
    </ServicesPageContainer>
  </>
);

export default ServicesPage;
