import React from 'react';
import i18next from '../../global/translations/i18next';
import { ReactComponent as CheckMark } from '../../global/images/common/checkmark.svg';

export const getValuesForSummaryItem = (data, values, valueKey) => {
  if (valueKey === 'duration') {
    return (
      <div className="content-item__simple">
        {' '}
        {`${values}  ${i18next.t('brief.rangeSliderLabels.months')}`}
      </div>
    );
  }

  if (valueKey === 'development_stage' || valueKey === 'industry') {
    const idData = data?.find((s) => s.id === Number(values));
    return <div className="content-item__simple">{idData?.name.value}</div>;
  }

  if (valueKey === 'specialists') {
    const specialists = Object.entries(values).map(([specialistId, count]) => {
      const specialistData = data?.find((s) => s.id === Number(specialistId));

      return {
        name: specialistData?.name,
        count,
      };
    });

    return (
      <div className="content-item__object-count">
        {specialists.map(
          (el) =>
            el.count > 0 && (
              <div key={el.name}>
                {el.name}
                <span className="count">({el.count})</span>
              </div>
            ),
        )}
      </div>
    );
  }

  if (valueKey === 'consultation' || valueKey === 'software_solutions') {
    const vData = values.map((id) => {
      const iData = data?.find((e) => e.id === Number(id));
      return valueKey === 'software_solutions' ? iData?.name.value : iData?.name;
    });

    return (
      <div className="content-item__object-items">
        {vData.map((el) => (
          <div className="content-item__object-item" key={el}>
            <CheckMark />
            {el}
          </div>
        ))}
      </div>
    );
  }

  if (valueKey === 'technologies') {
    const tData = values
      .map((id) => {
        const iData = data?.find((e) => e.technologies?.find((i) => i.id === Number(id)));
        return {
          name: iData?.name,
          technologies: [iData?.technologies?.find((i) => i.id === Number(id))],
        };
      })
      .reduce((accum, current) => {
        const aData = Object.values(accum);
        return {
          ...accum,
          [current.name]: {
            ...current,
            technologies: [
              ...(aData.length > 0 && accum[current.name]?.name === current.name
                ? accum[current.name].technologies
                : []),
              ...current.technologies,
            ],
          },
        };
      }, {});

    return (
      <div className="content-item__object-items-advanced">
        {Object.values(tData).map((el) => (
          <div className="content-item__object-item-advanced" key={el.name}>
            <div className="content-item__name">{el.name}</div>

            <div className="content-item__technologies">
              {el.technologies.map((tech) => (
                <div className="content-item__object-item" key={tech.id}>
                  <CheckMark />
                  {tech.name}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  }

  return null;
};

export const mapValuesForBrief = (values) => ({
  name: values.name,
  email: values.email,
  message: values.message || null,
  industry: values.industry || null,
  development_stage: values.development_stage || null,
  consulting_specialists: values.consultation.length
    ? values.consultation.map((s) => Number(s))
    : null,
  software_solutions: values.software_solutions.length
    ? values.software_solutions.map((st) => Number(st))
    : null,
  technologies: values.technologies.length ? values.technologies.map((t) => Number(t)) : null,
  specialists: Object.entries(values.specialists).length ? values.specialists : null,
  duration_days: values.duration ? Number(values.duration) : null,
  file: null,
  link: null,
});
