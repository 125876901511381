import { apiService } from './api';
import { isMockFactsAndNumbersServices } from './isMockEnabled';
import factsAndNumbersData from '../../__mocks__/factsAndNumbers/getFactsAndNumbersData.json';

export const factsAndNumberService = apiService.injectEndpoints({
  endpoints: (builder) => ({
    getFactsAndNumbers: builder.query({
      query: () => ({
        url: `get___`,
        method: 'GET',
        isMockEnabled: isMockFactsAndNumbersServices,
        mockData: factsAndNumbersData,
      }),
    }),
  }),
});

export const { useGetFactsAndNumbersQuery } = factsAndNumberService;
