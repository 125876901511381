import React from 'react';
import * as Styled from './NotificationsList.styled';
import Notification from './components/Notification';
import useNotifications from '../../global/hooks/useNotifications';

const NotificationsList = () => {
  const { notificationsList, removeNotification } = useNotifications();

  return (
    <Styled.NotificationsListContainer>
      {notificationsList.map((notification) => (
        <Notification key={notification.id} {...notification} onClose={removeNotification} />
      ))}
    </Styled.NotificationsListContainer>
  );
};

export default NotificationsList;
