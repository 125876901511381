import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-scroll';
import { ServicesContainer } from './Services.styled';

const Services = () => {
  const { t } = useTranslation();
  return (
    <ServicesContainer>
      <div className="services-container responsive-width">
        <h1 className="services-container__title"> {t('services.title')}</h1>
        <div className="services-container__content"> {t('services.content')}</div>
        <Link to="cooperation" offset={-50} spy smooth>
          <button className="services-container__btn btn btn_primary_contained" type="button">
            {t('services.button')}
          </button>
        </Link>
      </div>
      <div className="video__container">
        <div className="video__overlay" />
        <video autoPlay loop muted playsInline>
          <source
            src="https://roxers-public.s3.eu-central-1.amazonaws.com/web_media/services..mp4"
            type="video/mp4"
          />
        </video>
      </div>
    </ServicesContainer>
  );
};

export default Services;
