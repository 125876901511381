import styled from 'styled-components';
import { mobileWidth } from '../../../../global/styles/Global.styled';

export const ProcessListContainer = styled('div')`
  margin-top: 30px;
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 1240px) {
    flex-direction: column-reverse;
    align-items: center;
    width: 100%;

    .swiper-slide {
      div {
        max-width: 100%;
      }
    }
  }

  .processSwiper {
    max-width: 600px;
    max-height: 600px;
    width: 100%;
    height: 100%;
    overflow-y: hidden;

    @media only screen and (max-width: ${mobileWidth}) {
      max-width: 340px;
    }

    div {
      overflow-x: initial !important;
    }
  }
`;
