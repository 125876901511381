import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useGetOneCaseStudyQuery } from '../../../../global/service/caseStudiesService';
import { AboutClientContainer } from './AboutClient.styled';
import { convertSecondsToMonths } from './helpers';
import Layout from '../../../../components/Layout';
import { addMonthsToNumber } from '../../../../global/helpers';

const AboutClient = () => {
  const { t } = useTranslation();
  const { caseName } = useParams();
  const { data } = useGetOneCaseStudyQuery(caseName);

  return (
    <Layout>
      <AboutClientContainer>
        <div className="content">
          <div className="content__title"> {t('about_client.title')}</div>
          <div className="content__summary">{data.client_description.value}</div>
        </div>
        <div className="client ">
          <div>
            <span className="client__title">{t('about_client.client_name')}:</span>
            <span className="client__name">{data.name}</span>
          </div>
          <div>
            <span className="client__title">{t('about_client.client_country')}:</span>
            <span className="client__name">{data.country.name.value}</span>
          </div>
        </div>
        <div className="client">
          <div>
            <span className="client__title">{t('about_client.client_industry')}:</span>
            <span className="client__name">{data.industry.name.value}</span>
          </div>
          <div>
            <span className="client__title">{t('about_client.duration')}:</span>
            <span className="client__name">
              {Math.floor(convertSecondsToMonths(data.duration_seconds))}{' '}
              {addMonthsToNumber(Math.floor(convertSecondsToMonths(data.duration_seconds)))}
            </span>
          </div>
        </div>
      </AboutClientContainer>
    </Layout>
  );
};

export default AboutClient;
