import styled from 'styled-components';
import theme from '../../global/styles/theme';

export const AccordionContainer = styled('div')`
  width: 100%;

  .accordion-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(142.11deg, #f8f8f8 0.57%, #fbfbfb 78.12%);
    box-shadow: 4px 4px 27px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    padding: 21px 16px;
    cursor: pointer;

    &__title {
      color: ${theme.colors.text['20']};

      font-size: 17px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
      letter-spacing: 0.51px;
    }

    &__icon {
      transition: 100ms linear;
      width: 34px;
      height: 34px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &:hover {
        background: rgba(13, 158, 156, 0.03);
        border-radius: 17px;

        svg {
          path {
            fill: ${theme.colors.primary['30']};
          }
        }
      }

      &_collapsed {
        transform: rotate(180deg);
      }
    }

    &__collapsed {
      margin-bottom: 0;
    }
  }

  .accordion-body-container {
    margin: 12px 16px;

    &__body {
      display: flex;
      align-items: center;
      font-style: normal;
      line-height: 150%;
      overflow: hidden;
      transition: max-height 1s cubic-bezier(0, 1, 0, 1);
      max-height: 0;
      margin: 16px 0;
      font-size: 17px;
      font-weight: 400;
      letter-spacing: 0.51px;

      &_collapsed {
        display: flex;
        justify-content: center;
        overflow: hidden;
        width: 100%;
        max-width: 90vw;
        max-height: 1000px;
        transition: max-height 1s ease-in-out;
      }
    }
  }
`;
