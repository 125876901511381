import styled from 'styled-components';
import { mobileWidth } from '../../../../../../global/styles/Global.styled';
import theme from '../../../../../../global/styles/theme';

export const SliderContainer = styled.div`
  @media only screen and (min-width: ${mobileWidth}) {
    display: none;
  }
  height: 450px;

  .swiper-slide,
  .swiper-slide-active {
    display: flex;
    justify-content: center;
    height: 350px;
  }

  .sliders-container {
    display: flex;
    justify-content: center;
    gap: 12px;

    &__button {
      width: 38px;
      height: 6px;
      background-color: ${theme.colors.stroke['50']};
      cursor: pointer;
      transition: 0.5s;
      border-radius: 1px;

      &_active {
        width: 73px;
        background-color: ${theme.colors.primary['40']};
      }

      &:hover {
        background-color: ${theme.colors.primary['40']};
      }
    }
  }

  .slide {
    display: flex;
    flex-direction: column;

    &__content {
      margin-top: 40px;

      &-image {
        width: 343px;
        height: 229px;
        border-radius: 20px;
      }

      &-text {
        font-family: 'Lato', sans-serif;
        font-size: 21px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.42px;
        color: white;
        width: 343px;
        text-align: center;
        margin-top: 24px;
      }
    }
  }
`;
