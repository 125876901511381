import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { BsArrowRight } from 'react-icons/bs';
import BackgroundLinearText from '../../../../components/BackgroundLinearText';
import { JoinTeamContainer } from './JoinTeam.styled';
import Layout from '../../../../components/Layout';
import useDesktopSize from '../../../../global/hooks/useDesktopSize';
import GratitudeModal from '../../../../components/GratitudeModal';
import useNotifications from '../../../../global/hooks/useNotifications';
import { formikInjectedPropsTypes } from '../../../../global/types';
import CareerForm from '../../../../components/CareerForm';
import { formattedVacancyForm } from '../../../../global/helpers';
import { useSendVacancyFeedbackMutation } from '../../../../global/service/vacancyService';
import Loader from '../../../../components/Loader';

const JoinTeam = () => {
  const { t } = useTranslation();
  const [sendVacancyFeedback, state] = useSendVacancyFeedbackMutation();
  const isDesktop = useDesktopSize();
  const navigate = useNavigate();
  const { addNotification } = useNotifications();
  const [isSuccessJoinOpen, setSuccessJoinOpen] = useState(false);

  const handleSubmitForm = async (values, formikHelpers) => {
    const onError = () =>
      addNotification({
        type: 'error',
        title: t('common.notifications.error.title'),
        subtitle: t('common.notifications.error.subtitle'),
      });
    const onSuccess = () => {
      setSuccessJoinOpen(true);
      formikHelpers.resetForm();
    };
    sendVacancyFeedback({ data: await formattedVacancyForm(values), onSuccess, onError });
  };

  const handleButtonClick = () => navigate('jobs');

  const linkButton = (
    <button
      onClick={handleButtonClick}
      type="button"
      className={`btn btn-image btn_text_light ${!isDesktop && 'btn-mobile'}`}
    >
      <div className="btn-image__outlined">
        <BsArrowRight />
      </div>
      {t('common.open_position')}
    </button>
  );

  const descriptionContent = [
    t('career.join.description.excited'),
    t('career.join.description.welcome'),
  ];

  return (
    <>
      <JoinTeamContainer>
        <div className="background-dark-title">
          <BackgroundLinearText type="dark">{t('career.join.title')}</BackgroundLinearText>
        </div>
        <Layout>
          <div className="join-container">
            <div className="join-container__header-container">
              <h2 className="title title_light">{t('career.join.title')}</h2>
              {linkButton}
            </div>
            <div className="join-container__content">
              <div className="join-container__description">
                {descriptionContent.map((desc) => (
                  <div key={desc} className="join-container__description-item">
                    {desc}
                  </div>
                ))}
              </div>
              <CareerForm handleSubmitForm={handleSubmitForm} />
            </div>
          </div>
        </Layout>
      </JoinTeamContainer>
      {isSuccessJoinOpen && (
        <GratitudeModal
          onClose={() => setSuccessJoinOpen(false)}
          title={t('frontendAcademyBlock.successJoinModal.title')}
          description={t('frontendAcademyBlock.successJoinModal.description')}
        />
      )}
      {state.isLoading && <Loader />}
    </>
  );
};

JoinTeam.propTypes = {
  ...formikInjectedPropsTypes,
};

export default JoinTeam;
