import styled from 'styled-components';
import theme from '../../../../global/styles/theme';

const tabletWidth = '1239px';
const mobileWidth = '905px';

export const TestimonialsMainContainerTemplate = styled.div`
  background-color: ${theme.colors.background['10']};
  width: 100%;
  margin-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;

  .background-title {
    padding-top: 19px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .card-container {
    border-radius: 8px;
    display: grid;
    grid-template-columns: 813fr 519fr;

    @media only screen and (max-width: ${mobileWidth}) {
      width: 100%;
    }

    @media only screen and (max-width: ${(props) => props.maxWidth}) {
      grid-template-columns: 1fr;
    }

    &__content {
      height: 100%;
      background-color: ${theme.colors.background['95']};
      border-radius: 8px 0 0 8px;
      padding: ${(props) => props.contentPaddings};
      overflow: hidden;
      position: relative;

      @media only screen and (max-width: ${(props) => props.maxWidth}) {
        border-radius: 8px;
      }

      @media only screen and (max-width: ${(props) => props.maxWidth}) {
        padding: 40px 16px 42px;
      }

      &-block {
        position: relative;
        height: 100%;
      }
    }

    &__title {
      line-height: 132%;
      color: ${theme.colors.text['20']};
      font-family: Manrope, sans-serif;
      font-size: 50px;
      font-style: normal;
      font-weight: 800;
      letter-spacing: 1px;
    }
  }

  .sliders-container {
    display: flex;
    gap: 24px;

    &__button {
      width: 24px;
      height: 5px;
      background-color: ${theme.colors.stroke['90']};
      cursor: pointer;
      transition: 0.5s;

      &_active {
        width: 48px;
        background-color: ${theme.colors.primary['30']};
      }

      &:hover {
        background-color: ${theme.colors.primary['30']};
      }
    }
  }

  .swiper-container {
    min-height: 568px;
    padding-bottom: 30px;
  }
`;

export const TestimonialsMainContainer = styled(TestimonialsMainContainerTemplate).attrs({
  maxWidth: tabletWidth,
  contentPaddings: '100px 85px 79px 107px',
})``;

export const LinearGradientBlockTemplate = styled.div`
  background: ${theme.colors.gradient['4']};
  height: 100%;
  border-radius: 0 8px 8px 0;
  padding: ${(props) => props.gradientTextPaddings};

  @media only screen and (max-width: ${(props) => props.maxWidth}) {
    display: none;
  }

  .text {
    font-weight: 700;
    font-size: 28px;
    line-height: 39.2px;
    letter-spacing: 0.02em;
    color: ${theme.colors.text['95']};
    margin-bottom: 56px;
  }

  .btn {
    padding: 0;
  }
`;

export const LinearGradientBlock = styled(LinearGradientBlockTemplate).attrs({
  maxWidth: tabletWidth,
  gradientTextPaddings: '269px 45px 107px 73px',
})``;

export const TestimonialsMainContent = styled.div`
  color: ${theme.colors.text['30']};

  .client-info-container {
    height: 100px;
    width: 100%;
    margin-top: 60px;
    display: flex;
    align-items: start;
    justify-content: space-between;

    &__content {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 32px;

      @media only screen and (max-width: ${mobileWidth}) {
        gap: 24px;
      }

      &-photo {
        max-width: 100px;
        max-height: 100px;
        border-radius: 50px;
        object-fit: contain;

        @media only screen and (max-width: ${mobileWidth}) {
          width: 64px;
          height: 64px;
        }
      }

      &-name {
        font-weight: 700;
        font-size: 21px;
        color: ${theme.colors.primary['30']};
        margin-bottom: 6px;
        font-style: normal;
        line-height: 140%;
        letter-spacing: 0.42px;

        @media only screen and (max-width: ${mobileWidth}) {
          font-weight: 600;
          font-size: 19px;
        }
      }

      &-position {
        margin-bottom: 11px;
        font-size: 19px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        letter-spacing: 0.38px;

        @media only screen and (max-width: ${mobileWidth}) {
          font-size: 17px;
        }
      }

      &-country {
        display: flex;
        gap: 12px;
        align-items: center;

        &-flag {
          width: 24px;
          height: 14px;
        }
      }
    }

    &__logo {
      max-width: 100px;
      height: 22.22px;
    }
  }

  .client-testimonial {
    margin-top: 33px;
    font-weight: 400;
    font-size: 17px;
    font-style: normal;
    line-height: 150%;
    letter-spacing: 0.51px;

    @media only screen and (max-width: ${mobileWidth}) {
      font-size: 14px;
    }
  }
`;
