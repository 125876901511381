import styled from 'styled-components';
import theme from '../../../../global/styles/theme';

export const JobsMobileContainer = styled.div`
  margin-top: 66px;
  padding-left: 16px;

  @media only screen and (min-width: 1145px) {
    display: none;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 400px) {
    display: block;
  }

  .blur {
    position: absolute;
    top: 440px;
    left: 20%;
    width: 200px;
    height: 50px;
    background: ${theme.colors.gradient['5']};
    border-radius: 21%;
    filter: blur(50px);
  }

  .mobile {
    &__select {
      &__control {
        width: 343px;
        height: 62px;
        border-radius: 8px;

        &--is-focused {
          border-color: ${theme.colors.primary['40']};
          box-shadow: none;
        }
      }

      &__menu {
        width: 343px;
      }

      &__single-value,
      &__input {
        color: ${theme.colors.backgroundDark['20']};
      }

      &__indicator-separator {
        display: none;
      }

      &__indicator {
        color: ${theme.colors.text['20']};
      }

      &__option {
        padding: 12px 14px;
        line-height: 23px;
        letter-spacing: 0.02em;
        cursor: pointer;
        color: ${theme.colors.backgroundDark['20']};

        &--is-focused {
          background-color: rgba(13, 158, 156, 0.1);
        }

        &--is-selected {
          background-color: transparent;
        }
      }
    }

    &__open-jobs {
      color: ${theme.colors.primary['100']};
      margin-top: 26px;
      margin-bottom: 64px;
      font-family: 'Lato', sans-serif;
      font-size: 18px;
      font-weight: 500;
      line-height: 150%;
      letter-spacing: 0.36px;

      &_vacancies {
        font-size: 24px;
        font-weight: 800;
        line-height: 132%;
        letter-spacing: 0.96px;
        color: #16b9b7;
      }
    }
  }
`;

export const MobileVacanciesContainer = styled.div`
  .vacancy {
    &__container {
      &_outer {
        width: 343px;
        background-color: ${theme.colors.background['99']};
        border-radius: 12px;
        margin-bottom: 24px;
      }

      &_inner {
        padding: 24px 8px 24px 24px;
        border-radius: 8px;
      }
    }

    &__title {
      font-family: 'Lato', sans-serif;
      font-size: 21px;
      font-weight: 800;
      line-height: 140%;
      letter-spacing: 0.84px;
      color: ${theme.colors.text['20']};
      padding-bottom: 12px;
    }

    &__type {
      font-size: 16px;
      font-weight: 400;
      line-height: 150%;
      letter-spacing: 0.32px;
      color: ${theme.colors.stroke['50']};
      padding-bottom: 12px;
    }

    &__department {
      font-size: 18px;
      font-weight: 400;
      line-height: 150%;
      letter-spacing: 0.36px;
    }

    &__icon {
      display: flex;
      gap: 12px;
      padding-bottom: 12px;
    }

    &__apply {
      display: flex;
      gap: 20px;
      align-items: center;

      &-text {
        font-size: 18px;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: 0.36px;
        text-align: center;
      }
    }

    &__btn {
      border: none;
      width: 34px;
      height: 34px;
      border-radius: 50%;
      color: ${theme.colors.primary['100']};
      background-color: ${theme.colors.primary['30']};
      font-size: 19px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background-color: ${theme.colors.primary['20']};
      }
    }
  }
`;
