import { combineReducers } from 'redux';
import { initComponentReducer } from './containers/InitComponent';
import { caseStudiesService } from './global/service/caseStudiesService';
import { companiesService } from './global/service/companiesService';
import { testimonialsService } from './global/service/testimonialsService';
import { factsAndNumberService } from './global/service/factsAndNumbersService';
import { feedbacksService } from './global/service/feedBacksService';
import { joinAcademyService } from './global/service/joinAcademyService';
import { briefService } from './global/service/briefService';
import { jobsService } from './global/service/jobsService';
import { industryService } from './global/service/industryService';

export default combineReducers({
  initComponentReducer,
  [companiesService.reducerPath]: companiesService.reducer,
  [testimonialsService.reducerPath]: testimonialsService.reducer,
  [factsAndNumberService.reducerPath]: factsAndNumberService.reducer,
  [feedbacksService.reducerPath]: feedbacksService.reducer,
  [caseStudiesService.reducerPath]: caseStudiesService.reducer,
  [joinAcademyService.reducerPath]: joinAcademyService.reducer,
  [briefService.reducerPath]: briefService.reducer,
  [jobsService.reducerPath]: jobsService.reducer,
  [industryService.reducerPath]: industryService.reducer,
});
