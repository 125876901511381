import styled from 'styled-components';
import theme from '../../../../../../global/styles/theme';
import { mobileWidth } from '../../../../../../global/styles/Global.styled';

export const FormContainer = styled.div`
  padding: 48px;
  background-color: ${theme.colors.background['95']};
  grid-column: 1/12;
  border-radius: 12px;

  @media only screen and (max-width: ${mobileWidth}) {
    margin: 0;
    padding: 48px 16px 52px 16px;
    grid-column: 1/5;
  }

  .form__container {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
`;
