import styled from 'styled-components';
import theme from '../../../../global/styles/theme';
import { mobileWidth } from '../../../../global/styles/Global.styled';

export const CardContainer = styled.div`
  width: 580px;
  min-height: 435px;
  height: 100%;
  border-radius: 12px;
  background: ${theme.colors.gradient['1']};
  transition: background 0.5s ease;

  @media only screen and (max-width: ${mobileWidth}) {
    width: 343px;
    height: 100%;
  }

  .content {
    padding: 56px 47px 47px 47px;
    position: relative;
    overflow: hidden;

    @media only screen and (max-width: ${mobileWidth}) {
      padding: 226px 20px 47px 20px;
    }

    &__company {
      font-size: 21px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      letter-spacing: 0.42px;
      color: ${theme.colors.primary['100']};
      margin-bottom: 12px;
      width: 278px;
    }

    &__project {
      font-family: Manrope, sans-serif;
      font-size: 30px;
      font-style: normal;
      font-weight: 800;
      line-height: 140%;
      letter-spacing: 0.68px;
      color: ${theme.colors.primary['100']};
      width: 278px;
      margin-bottom: 58px;

      @media only screen and (max-width: ${mobileWidth}) {
        font-size: 28px;
        font-weight: 700;
        line-height: 140%;
        width: 295px;
      }
    }

    &__img {
      width: 280px;
      height: 232px;
      position: absolute;
      top: 0;
      right: -25px;
      object-fit: contain;

      @media only screen and (max-width: ${mobileWidth}) {
        width: 100%;
        margin: auto;
        left: 0;
        right: 0;
        text-align: center;
      }
    }

    &__technologies {
      &-container {
        display: flex;
        gap: 14px;
        flex-wrap: wrap;
        width: 387px;
        row-gap: 16px;

        @media only screen and (max-width: 1440px) {
          width: 100%;
        }

        &-button {
          font-size: 17px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
          letter-spacing: 0.51px;
          border: 1px solid ${theme.colors.backgroundDark['95']};
          height: 36px;
          padding: 6px 12px;
          width: auto;
          box-sizing: border-box;
          color: ${theme.colors.backgroundDark['95']};

          @media only screen and (max-width: ${mobileWidth}) {
            height: 33px;
            font-size: 14px;
            line-height: 21px;
          }
        }
      }
    }
  }

  &:hover {
    background: ${theme.colors.gradient['5']};
    border-radius: 12px;
  }
`;
