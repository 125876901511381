import styled from 'styled-components';
import { mobileWidth } from '../../global/styles/Global.styled';

export const LayoutContainer = styled('div')`
  width: 100%;
  margin: 0 auto;

  .main-content {
    width: 100%;
    display: grid;
    margin: auto;
    @media only screen and (min-width: 1920px) {
      max-width: 1488px;
      grid-gap: 16px;
      grid-template-columns: repeat(18, 1fr);
    }

    @media only screen and (min-width: 1440px) and (max-width: 1919px) {
      max-width: 1224px;
      grid-gap: 16px;
      grid-template-columns: repeat(18, 1fr);
    }

    @media only screen and (min-width: 1240px) and (max-width: 1439px) {
      max-width: 1024px;
      grid-gap: 16px;
      grid-template-columns: repeat(18, 1fr);
    }

    @media only screen and (min-width: 905px) and (max-width: 1239px) {
      max-width: 797px;
      grid-gap: 16px;
      grid-template-columns: repeat(18, 1fr);
    }

    @media only screen and (max-width: ${mobileWidth}) {
      max-width: 343px;
      grid-gap: 16px;
      grid-template-columns: repeat(4, 1fr);
    }

    @media only screen and (max-width: 375px) {
      max-width: fit-content;
      margin: 0 16px;
    }
  }
`;
