import styled from 'styled-components';
import theme from '../../global/styles/theme';

const mobileMenu = '904px';

export const FooterContainer = styled.footer`
  width: 100%;
  background-color: ${theme.colors.background['10']};

  .content {
    color: ${theme.colors.stroke['90']};
    margin: 0 auto;

    a {
      color: ${theme.colors.stroke['90']};
    }

    @media only screen and (min-width: 1920px) {
      max-width: 1320px;
    }
    @media only screen and (min-width: 1440px) and (max-width: 1919px) {
      max-width: 1230px;
    }
    @media only screen and (min-width: 1240px) and (max-width: 1439px) {
      max-width: 1024px;
    }
    @media only screen and (min-width: 905px) and (max-width: 1239px) {
      max-width: 800px;
    }
    @media only screen and (max-width: ${mobileMenu}) {
      max-width: min(343px, 90%);
    }
  }

  .main-info-container {
    display: flex;
    justify-content: space-between;
    align-items: start;
    padding-bottom: 54px;
    padding-top: 64px;
    font-weight: 600;
    font-size: 18px;
    font-style: normal;
    line-height: 150%;
    letter-spacing: 0.38px;

    @media only screen and (max-width: 1240px) {
      display: grid;
      grid-template-columns: 2fr 1fr;
      gap: 48px;
    }
    @media only screen and (max-width: ${mobileMenu}) {
      grid-template-columns: 1fr;
    }

    &__contacts {
      & > img {
        margin-bottom: 24px;
      }

      & > div:first-of-type {
        font-weight: 600;
        line-height: 26px;
        margin-bottom: 8px;
      }
      & > div:nth-of-type(2) {
        font-weight: 400;
        line-height: 32px;
      }

      &-address {
        display: block;
      }
    }

    &__menu {
      & > li:not(:last-child) {
        margin-bottom: 16px;
      }

      @media only screen and (min-width: 1240px) {
        margin-right: 87px;
      }

      &-links {
        color: ${theme.colors.stroke['90']};
        text-transform: capitalize;
        font-size: 19px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        letter-spacing: 0.38px;
      }
    }

    &__socials {
      text-transform: capitalize;

      &-follow {
        margin-bottom: 34px;
        font-size: 19px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        letter-spacing: 0.38px;
      }

      &-logos {
        display: grid;
        grid-template-columns: repeat(3, 87px);
        row-gap: 34px;
        column-gap: 48.35px;
        width: fit-content;

        @media only screen and (max-width: ${mobileMenu}) {
          display: grid;
          grid-template-columns: 1fr 1fr;
        }

        svg {
          filter: grayscale(100%);

          &:hover {
            filter: none;
            transition: 0.1s;
          }
        }
      }
    }
  }

  .policy-container {
    text-transform: capitalize;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 57px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.01em;

    @media only screen and (max-width: ${mobileMenu}) {
      height: 98px;
      display: grid;
      grid-template-columns: 1fr;
      padding-bottom: 20px;
      padding-top: 20px;
      row-gap: 24px;
    }

    & > div:first-of-type {
      display: flex;
      gap: 12px;

      & > span:not(:last-child):after {
        content: '|';
        margin-left: 12px;
      }
    }

    span {
      cursor: pointer;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      letter-spacing: 0.45px;
    }
  }
`;
