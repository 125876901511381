import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { LanguagesContainer } from './SwitchLanguage.styled';
import { changeLanguage, getFromLocalStorage, setToLocalStorage } from '../../../../global/helpers';
import i18next from '../../../../global/translations/i18next';

const SwitchLanguage = () => {
  const lang = getFromLocalStorage('lang') || i18next.language;
  const [active, setActive] = useState(lang);
  const { t } = useTranslation();

  useEffect(() => {
    setToLocalStorage('lang', lang);
    changeLanguage(lang);
  }, [lang]);

  const handleChangeLanguage = (ln) => {
    setToLocalStorage('lang', ln);
    changeLanguage(ln);
    setActive(ln);
  };

  return (
    <LanguagesContainer>
      <span
        onClick={() => handleChangeLanguage('uk')}
        className={` ${active === 'uk' && 'active'}`}
      >
        {t('header.button.ua')}
      </span>
      /
      <span
        onClick={() => handleChangeLanguage('en')}
        className={` ${active === 'en' && 'active'}`}
      >
        {t('header.button.en')}
      </span>
    </LanguagesContainer>
  );
};

export default SwitchLanguage;
