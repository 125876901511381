import React from 'react';
import HelmetHead from '../../components/HelmetHead';
import { CareerPageContainer } from './CareerPage.styled';
import PreviewBlock from './components/PreviewBlock';
import RoxersBenefits from './components/RoxersBenefits';
import Academy from './components/Academy';
import CandidateJourney from './components/CandidateJourney';
import JoinTeam from './components/JoinTeam';

const CareerPage = () => (
  <>
    <HelmetHead page="careerPage" />
    <CareerPageContainer>
      <PreviewBlock />
      <RoxersBenefits />
      <CandidateJourney />
      <Academy />
      <JoinTeam />
    </CareerPageContainer>
  </>
);

export default CareerPage;
