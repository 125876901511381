import React from 'react';
import { useTranslation } from 'react-i18next';
import { BsArrowRight } from 'react-icons/bs';
import { ThankYouPageContainer } from '../../../ContactUsPage/components/ThankYouPage/ThankYouPage.styled';
import BackgroundLinearText from '../../../../components/BackgroundLinearText';

const ThankYouForApplying = () => {
  const { t } = useTranslation();
  return (
    <ThankYouPageContainer>
      <div className="background-dark-title ">
        <BackgroundLinearText type="dark">{t('jobs_page.thank_you.title')}</BackgroundLinearText>
      </div>
      <div className="content responsive-width">
        <h2 className="title content__title"> {t('jobs_page.thank_you.subtitle')}</h2>
        <div className="content__subtitle">{t('jobs_page.thank_you.description')}</div>
        <div className="content__blur" />
        <button type="button" className="btn btn-image btn_text_light">
          <div className="btn-image__outlined">
            <BsArrowRight />
          </div>
          {t('jobs_page.thank_you.button')}
        </button>
      </div>
    </ThankYouPageContainer>
  );
};

export default ThankYouForApplying;
