import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FrontendAcademyContainer,
  FrontendAcademyContentContainer,
  FrontendAcademyImageContainer,
} from './FrontendAcademy.styled';
import BackgroundLinearText from '../../../../components/BackgroundLinearText';
import useDesktopSize from '../../../../global/hooks/useDesktopSize';
import JoinAcademyModalForm from './components/JoinAcademyModalForm';
import GratitudeModal from '../../../../components/GratitudeModal';
import AcademyImage from '../../../../global/images/frontendAcademy/academyAbout.jpg';

const FrontendAcademy = () => {
  const { t } = useTranslation();
  const { isDesktop } = useDesktopSize();

  const [isJoinAcademyModalOpen, setJoinAcademyModalOpen] = useState(false);
  const [isSuccessJoinOpen, setSuccessJoinOpen] = useState(false);

  const academyDescription = [
    t('frontendAcademyBlock.description.empower'),
    t('frontendAcademyBlock.description.system'),
    t('frontendAcademyBlock.description.better'),
    t('frontendAcademyBlock.description.promises'),
    t('frontendAcademyBlock.description.join'),
  ];

  // const linkButton = (
  //   <button
  //     onClick={() => setJoinAcademyModalOpen(true)}
  //     type="button"
  //     className="btn btn-image btn_text_light"
  //   >
  //     <div className="btn-image__outlined">
  //       <BsArrowRight />
  //     </div>
  //     {t('frontendAcademyBlock.joinTheAcademy')}
  //   </button>
  // );

  const academyImage = (className) => (
    <FrontendAcademyImageContainer>
      <img src={AcademyImage} alt="frontend academy" className={className} />
    </FrontendAcademyImageContainer>
  );

  return (
    <>
      <FrontendAcademyContainer>
        <div className="background-dark-title-outlined">
          <BackgroundLinearText type="dark">
            {t('frontendAcademyBlock.backgroundTitle')}
          </BackgroundLinearText>
        </div>
        <FrontendAcademyContentContainer className="light-container-responsive transparent-outlined-block">
          <div className="header-container">
            <div className="header-container__title-container">
              <h2 className="title title_light">{t('frontendAcademyBlock.title')}</h2>
              {/* {linkButton} */}
            </div>
          </div>
          {academyImage('academy-image-mobile')}
          <div className="academy-grid">
            <div className="academy-grid__description">
              {academyDescription.map((e) => (
                <div key={e} className="academy-grid__item">
                  {e}
                </div>
              ))}
            </div>
            {isDesktop && academyImage('academy-image')}
          </div>
        </FrontendAcademyContentContainer>
      </FrontendAcademyContainer>
      {isJoinAcademyModalOpen && (
        <JoinAcademyModalForm
          onClose={() => setJoinAcademyModalOpen(false)}
          handleSuccessForm={() => setSuccessJoinOpen(true)}
        />
      )}
      {isSuccessJoinOpen && (
        <GratitudeModal
          onClose={() => setSuccessJoinOpen(false)}
          title={t('frontendAcademyBlock.successJoinModal.title')}
          description={t('frontendAcademyBlock.successJoinModal.description')}
        />
      )}
    </>
  );
};

export default FrontendAcademy;
