import styled from 'styled-components';

export const GratitudeContainer = styled('div')`
  max-width: 493px;

  @media only screen and (max-width: 1904px) {
    max-width: max-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .modal__description {
    margin: 20px 0 0;
  }
`;
