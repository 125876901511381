import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useParams } from 'react-router-dom';
import { ResultsContainer } from './Results.styled';
import BackgroundLinearText from '../../../../components/BackgroundLinearText';
import Layout from '../../../../components/Layout';
import SwiperNavigator from '../../../../components/SwiperNavigator';
import { useGetOneCaseStudyQuery } from '../../../../global/service/caseStudiesService';
import { getImagesByType } from '../../helpers';

const Results = () => {
  const { caseName } = useParams();
  const { data } = useGetOneCaseStudyQuery(caseName);
  const { t } = useTranslation();
  const [currentSlide, setCurrentSlide] = useState(0);

  const resultsImages = getImagesByType(data, 'results');

  return (
    <ResultsContainer>
      <div className="background-dark-title">
        <BackgroundLinearText type="dark">{t('resultsBlock.title')}</BackgroundLinearText>
      </div>
      <Layout>
        <div className="content">
          <div className="content__header-container">
            <h2 className="title title_light">{t('resultsBlock.title')}</h2>
            <div className=" subtitle subtitle_light">{data.results_description.value}</div>
          </div>
          {resultsImages.length > 0 && (
            <Swiper
              onBeforeTransitionStart={(swiper) => {
                setCurrentSlide(swiper.activeIndex);
              }}
              autoHeight
              speed={800}
              className="content__results"
            >
              {resultsImages.map((el) => (
                <SwiperSlide key={el.id}>
                  <img className="content__result-item" src={el.url} alt="test" />
                </SwiperSlide>
              ))}
              <SwiperNavigator steps={resultsImages.length} currentStep={currentSlide} />
            </Swiper>
          )}
        </div>
      </Layout>
    </ResultsContainer>
  );
};

export default Results;
