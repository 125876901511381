import React from 'react';
import { useTranslation } from 'react-i18next';
import { briefFormQuestionsData } from '../../constants';
import { FormContainer } from './BriefForm.styled';
import QuestionSelect from '../Questions/QuestionSelect';
import QuestionCheckBox from '../Questions/QuestionCheckBox';
import QuestionCheckBoxAdvanced from '../Questions/QuestionCheckBoxAdvanced';
import QuestionCounter from '../Questions/QuestionCounter';
import QuestionDuration from '../Questions/QuestionDuration';
import { useGetBriefContentQuery } from '../../../../../../global/service/briefService';

const BriefForm = () => {
  const { t } = useTranslation();
  const briefContent = useGetBriefContentQuery();

  return (
    <FormContainer>
      <div className="form__container">
        <QuestionSelect
          question={t(`brief.questions.industry`)}
          options={briefContent.data?.industries || []}
          isOptional={briefFormQuestionsData.businessIndustry.isOptional}
          formikFieldName={briefFormQuestionsData.businessIndustry.alias}
        />
        <QuestionSelect
          question={t('brief.questions.development_stage')}
          options={briefContent.data?.developmentProcess || []}
          isOptional={briefFormQuestionsData.stage.isOptional}
          formikFieldName={briefFormQuestionsData.stage.alias}
        />
        <QuestionCheckBox
          question={t('brief.questions.consultation')}
          options={briefContent.data?.specialist || []}
          isOptional={briefFormQuestionsData.consultation.isOptional}
          formikFieldName={briefFormQuestionsData.consultation.alias}
        />
        <QuestionCheckBox
          question={t('brief.questions.software_solutions')}
          options={briefContent.data?.softwareSolutions || []}
          isOptional={briefFormQuestionsData.softwareSolution.isOptional}
          formikFieldName={briefFormQuestionsData.softwareSolution.alias}
        />
        <QuestionCheckBoxAdvanced
          question={t('brief.questions.technologies')}
          options={briefContent.data?.techStack || []}
          isOptional={briefFormQuestionsData.techStack.isOptional}
          formikFieldName={briefFormQuestionsData.techStack.alias}
        />
        <QuestionCounter
          question={t('brief.questions.specialists')}
          options={briefContent.data?.specialist || []}
          isOptional={briefFormQuestionsData.specialists.isOptional}
          formikFieldName={briefFormQuestionsData.specialists.alias}
        />
        <QuestionDuration
          question={t('brief.questions.duration')}
          isOptional={briefFormQuestionsData.duration.isOptional}
          formikFieldName={briefFormQuestionsData.duration.alias}
        />
      </div>
    </FormContainer>
  );
};

export default BriefForm;
