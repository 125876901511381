import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useGetOneCaseStudyQuery } from '../../../../global/service/caseStudiesService';
import BackgroundLinearText from '../../../../components/BackgroundLinearText';
import { TechnologiesAndTeamContainer } from './TechnologiesAndTeam.styled';
import { getFormattedData } from './helpers';
import Layout from '../../../../components/Layout';
import useDesktopSize from '../../../../global/hooks/useDesktopSize';

const TechnologiesAndTeam = () => {
  const { t } = useTranslation();
  const { isDesktop } = useDesktopSize();
  const { caseName } = useParams();
  const { data } = useGetOneCaseStudyQuery(caseName);

  const technologiesAndTeamItems = (items, index, key) => (
    <div
      key={key && key}
      className={`content__block-items ${index === 1 && 'content__block-items-team'}`}
    >
      {items.map((item) => (
        <div className={`content__item ${index === 1 && 'content__item-team'} `} key={item.id}>
          <div className="content__item-logo">
            <img src={item.icon} alt={item.name} />
          </div>
          <div>{item.name}</div>
        </div>
      ))}
    </div>
  );

  return (
    <TechnologiesAndTeamContainer>
      <div className="background-dark-title">
        <BackgroundLinearText type="dark">
          {t('technologiesAndTeam.backgroundText')}
        </BackgroundLinearText>
      </div>
      <Layout>
        <div className="content">
          {data &&
            Object.values(getFormattedData(data)).map((el, index) => (
              <div className="content__block" key={el.title}>
                <div className="content__block-title">{t(el.title)}</div>
                <div className="content__block-description">{el.description}</div>
                {!isDesktop && technologiesAndTeamItems(el.items, index)}
              </div>
            ))}
          {isDesktop &&
            data &&
            Object.values(getFormattedData(data)).map((el, index) =>
              technologiesAndTeamItems(el.items, index, el.title),
            )}
        </div>
      </Layout>
    </TechnologiesAndTeamContainer>
  );
};

export default TechnologiesAndTeam;
