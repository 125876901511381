import { apiService } from './api';
import { isMockCaseStudiesService } from './isMockEnabled';
import caseStudiesData from '../../__mocks__/caseStudies/getCaseStudiesData.mock.json';
import caseStudyData from '../../__mocks__/caseStudies/getCaseStudyDataMock.mock.json';
import { CACHE_TAGS } from './constants';
import { CARDS_NUM_SERVICES_PAGE } from '../../containers/MainPage/components/CaseStudiesMainPage/components/constants';

export const caseStudiesService = apiService.injectEndpoints({
  endpoints: (builder) => ({
    getCaseStudies: builder.query({
      query: () => ({
        url: `projects`,
        method: 'GET',
        isMockEnabled: isMockCaseStudiesService,
        mockData: caseStudiesData,
      }),
      transformResponse: (data, meta, isSingleRow) =>
        isSingleRow ? data.slice(0, CARDS_NUM_SERVICES_PAGE) : data,

      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: CACHE_TAGS.caseStudies, id })),
              { type: CACHE_TAGS.caseStudies, id: 'LIST' },
            ]
          : [{ type: CACHE_TAGS.caseStudies, id: 'LIST' }],
    }),
    getOneCaseStudy: builder.query({
      query: (projectSlug) => ({
        url: `projects/${projectSlug}`,
        method: 'GET',
        isMockEnabled: isMockCaseStudiesService,
        mockData: {
          status: 200,
          data: caseStudyData.find((el) => el.slug === projectSlug),
          providesTags: (result) =>
            result
              ? [
                  ...result.map(({ id }) => ({ type: CACHE_TAGS.caseStudyOne, id })),
                  { type: CACHE_TAGS.caseStudyOne, id: 'LIST' },
                ]
              : [{ type: CACHE_TAGS.caseStudyOne, id: 'LIST' }],
        },
      }),
    }),
  }),
});

export const { useGetCaseStudiesQuery, useGetOneCaseStudyQuery } = caseStudiesService;
