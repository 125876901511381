import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import TestimonialsTemplate from './TestimonialsTemplate';
import 'swiper/swiper.min.css';
import { useGetTestimonialsQuery } from '../../../../global/service/testimonialsService';
import SwiperNavigator from '../../../../components/SwiperNavigator';
import { useIsFirstRender } from '../../../../global/hooks/useIsFirtsRender';
import i18next from '../../../../global/translations/i18next';

const TestimonialsList = () => {
  const [currentStep, setCurrentStep] = useState(0);

  const { data, refetch } = useGetTestimonialsQuery();

  const isFirstRender = useIsFirstRender();
  useEffect(() => {
    if (!isFirstRender) refetch();
  }, [i18next.language]);

  return (
    <Swiper
      modules={[Autoplay]}
      onBeforeTransitionStart={(swiper) => {
        setCurrentStep(swiper.activeIndex);
      }}
      autoplay
      speed={1000}
    >
      {data.map((customer) => (
        <SwiperSlide key={customer.id} className="swiper-container">
          <TestimonialsTemplate
            photo={customer.customer.photo}
            name={customer.customer.name}
            position={customer.customer.position}
            country={customer.customer.country.name.value}
            flag={customer.customer.country.code}
            logo={customer.customer.company.logo}
            testimonial={customer.text.value}
          />
        </SwiperSlide>
      ))}
      <SwiperNavigator steps={data.length} currentStep={currentStep} />
    </Swiper>
  );
};

export default TestimonialsList;
