import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import { ContactFormContainer } from './ContactForm.styled';
import { dataFormValidationSchema } from '../../../../global/validationShemas';
import Input from '../../../../components/Input';
import Select from '../../../../components/Select';
import Textarea from '../../../../components/Textarea';
import Checkbox from '../../../../components/CheckBox';
import { formikInjectedPropsTypes } from '../../../../global/types';
import { useGetIndustriesQuery } from '../../../../global/service/industryService';

const ContactForm = ({ handleSubmitForm }) => {
  const { t } = useTranslation();
  const { data } = useGetIndustriesQuery();

  const subjectOptions =
    data?.map((el) => ({
      value: el.name.value,
      label: el.name.value,
      id: el.id,
    })) || null;

  return (
    <ContactFormContainer>
      <Formik
        initialValues={{
          name: '',
          email: '',
          industry: 0,
          message: '',
          agreement: false,
        }}
        enableReinitialize
        validationSchema={dataFormValidationSchema}
        onSubmit={handleSubmitForm}
      >
        {(props) => (
          <Form className="form-container">
            <div className="form-container__grid">
              <Input
                name="name"
                error={props.touched.name && props.errors.name}
                label={t(`contactUsPage.contactForm.name`)}
                placeholder={t(`contactUsPage.contactForm.name`)}
                required
              />
              <Input
                name="email"
                error={props.touched.email && props.errors.email}
                label={t(`contactUsPage.contactForm.email`)}
                placeholder={t(`contactUsPage.contactForm.email`)}
                required
              />
            </div>
            {subjectOptions && (
              <Select
                name="industry"
                selectOptions={subjectOptions}
                label={t(`contactUsPage.contactForm.interest`)}
                placeholder={t(`contactUsPage.contactForm.interest`)}
                theme="light"
              />
            )}

            <div className="form-container__row">
              <Textarea
                name="message"
                label={t(`contactUsPage.contactForm.note`)}
                placeholder={t(`contactUsPage.contactForm.note`)}
              />
            </div>
            <button className="btn btn_primary_contained form-container__btn-send" type="submit">
              {t('mainDataForm.buttonText')}
            </button>
            <div className="form-container__checkbox">
              <Checkbox
                checkBoxName="agreement"
                title={t('mainDataForm.agreement')}
                error={props.touched.agreement && props.errors.agreement}
              />
            </div>
          </Form>
        )}
      </Formik>
    </ContactFormContainer>
  );
};

ContactForm.propTypes = {
  ...formikInjectedPropsTypes,
  handleSubmitForm: PropTypes.func.isRequired,
};

export default ContactForm;
