import styled from 'styled-components';
import theme from '../../global/styles/theme';
import { mobileWidth } from '../../global/styles/Global.styled';

export const DataFormContainer = styled('div')`
  margin: 49px 0 40px;
  border-bottom: 0.5px dashed ${theme.colors.stroke['70']};

  @media only screen and (max-width: ${mobileWidth}) {
    margin: 40px 0 0;
    border: none;
  }

  .form-container {
    padding-bottom: 40px;
    @media only screen and (max-width: ${mobileWidth}) {
      flex-direction: column;
    }

    &__grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 40px;

      @media only screen and (max-width: ${mobileWidth}) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &:first-child {
          margin: 0;
        }
      }
    }

    &__row {
      margin: 40px 0;
    }

    &__btn-send {
      margin: 40px 0;
      min-width: 253px;

      @media only screen and (max-width: ${mobileWidth}) {
        width: 100%;
      }
    }
  }
`;
