import styled from 'styled-components';
import theme from '../../global/styles/theme';
import { mobileWidth } from '../../global/styles/Global.styled';

export const BackgroundLinearTextContainer = styled('div')`
  position: absolute;
  z-index: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .text {
    font-style: normal;
    line-height: 250px;

    white-space: nowrap;
    overflow: hidden;

    font-family: Manrope, sans-serif;
    font-size: 264px;
    font-weight: 500;
    letter-spacing: 5.28px;

    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .dark {
    background: ${theme.colors.gradient['2']};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    overflow: hidden;
  }

  .light {
    background: ${theme.colors.gradient['3']};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media only screen and (min-width: 1920px) {
      max-width: 1700px;
    }

    @media only screen and (min-width: 1440px) and (max-width: 1919px) {
      max-width: 1332px;
    }

    @media only screen and (min-width: 1240px) and (max-width: 1439px) {
      max-width: 1132px;
    }

    @media only screen and (min-width: 905px) and (max-width: 1239px) {
      max-width: 908px;
    }

    @media only screen and (max-width: ${mobileWidth}) {
      max-width: 375px;
    }
  }
`;
